import { Alert, Button, Divider, List, ListItem,Input,CircularProgress} from '@mui/material'
import React, { useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle' 
import Checkbox from '@mui/material/Checkbox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useTranslation } from 'react-i18next'
import avalancheIcon from 'assets/logos/avalanche_logo.svg'
import eth from 'assets/logos/eth.svg'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
//import { depositEther } from 'views/cryptoloterita/POSBridge/depositEther'
import CloseIcon from '@mui/icons-material/Close'
import LoaderCircle from 'components/LoaderCircle';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { switchEthereumChain } from 'services/metamask'
import { isEnglish } from 'views/cryptoloterita/determinateLanguage'
import { DrawerMobileContext } from 'hooks/DrawerMobileContext'
import PropTypes from 'prop-types'
import { sign } from 'services/Utils/signature'
import { saveUser } from 'services/User/saveUser'

import ButtonStyled from 'components/ButtonStyled'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #E5E5E5',
    borderRadius:'8px',
    boxShadow: 24,
    p: 4,
};


const LogIn = ({data}) => {
    const { t } = useTranslation("translate");
    const [open, setOpen] = React.useState(false);
    const [form,setForm] = useState('0.00');
    const [error,setError] = useState('');
    const [posTx,setPosTX] = useState('');
    ///const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false); setForm(''); setError(''); setPosTX('')}
    const [Load,setLoad] = useState(false);
    const [wethTime,setWethTime] = useState(false);
    const [wethSystem, setWethSystem] = useState(false);
    //const {dataContext} = React.useContext(Context)
    const [notifications,setNotifications] = useState('');
    const [viewEmailInput,setViewEmailInput] = useState(true);
    const [emailAcceptButtonValid,setEmailAcceptButtonValid] = useState(false);
    const {setOpenWallet} = React.useContext(DrawerMobileContext);
    const [initRegistrationEmail,setInitRegistrationEmail] = useState(false);
    const [msgError,setMsgError] = useState('');

    const handleNotifications = (e) =>{
        e.preventDefault(); 
        setEmailAcceptButtonValid(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(e.target.value))
        setNotifications(e.target.value);
    }



    const handleReceiveNotifications = async(e)=>{
        e.preventDefault();
        setInitRegistrationEmail(true);
        setMsgError('')
        let  { signature, message } = await sign("Approve my intention to receive notifications at",data.userAccount,data.provider).catch(err=>{
            setInitRegistrationEmail(false);
            console.log(err)
            return 0;
        })
        await saveUser({
            address : data.userAccount,
            email : notifications,
            signature : signature,
            message : message
        })
        setViewEmailInput(false)
        localStorage.setItem('viewEmailInput',JSON.stringify(viewEmailInput))
        setOpenWallet(false)
        setInitRegistrationEmail(false);
        setMsgError('')
    }

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        for (const cookie of cookies) {
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }

    const disconnect = ()=>{
        if(data.provider) {
            localStorage.removeItem('wallet')
            localStorage.removeItem('isMetamask')
            localStorage.removeItem('walletConnect')
            localStorage.removeItem('network')
            data.provider.request({
                method: "eth_requestAccounts",
                params: [
                    {
                        eth_accounts: {}
                    }
                ]
            });
            //data.provider.close()
            deleteAllCookies()
            window.location.reload()
          
        }
    }



    const handleConvert = (e) => {
        e.preventDefault();
        setError('')
        setPosTX('')
        if(form != '') {
            setLoad(true)
            let amount = parseFloat(form.replace(/,/g,''))
            if(amount > 0) {
             /*    depositEther(amount,data.provider,data.userAccount, (receipt) => {
                    setPosTX(JSON.stringify(receipt.hash))
                    setForm('0.00')
                    setLoad(false)
                }, (error) => {
                    console.log(error)
                    if(error.message) {
                        setError(error.message)
                    }
                    setLoad(false)
                }) */
            } else {
                setError('Error amount must be greater than 0')
                setLoad(false)
            }
        } else {
            setError('Enter a valid amount')
            setLoad(false)
        }
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleChange = (e) =>{
        e.preventDefault();
        var value = e.target.value;
        setForm(value);
    }
    //const [wbalance,setWbalance] = useState('')
    /* const isRegisterRef = React.useRef(
        data.userAccount && localStorage.getItem('viewEmailInput') == 'true'
    ) */
    React.useEffect(() => {
    }, [])
    return (
        <React.Fragment>

            <Box
                sx={{
                    width: '100%',
                    height: 'calc(100vh - 160px)',
                    boxSizing: 'border-box',
                    overflowY: 'auto',
                    "@media (max-width: 768px)": {
                        height: 'calc(100vh - 20px)'
                    }
                }}
            >
                <nav aria-label="main mailbox folders">
                <List
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                    }}
                >
                    <ListItem>
                        <Typography  component="div" fontSize={"15px"} >
                            {t('topbar.wallet.title')}
                        </Typography>
                    </ListItem>
                    <Divider />
                    <ListItem >
                        <Button
                            variant="none"
                            size="large"
                            startIcon={<AccountCircleIcon/>}
                        >
                            {(data.userAccount).substring(0, 4).concat('...').concat((data.userAccount).substring(38, 42))}
                        </Button>
                        <Checkbox icon={<CheckCircleIcon color='success' />} checkedIcon={<CheckCircleIcon color='success' />} />
                        <Box >
                                {t('topbar.wallet.modal_connected')}
                            { data && data.chainID && data.chainID == process.env.REACT_APP_ETHEREUM_NETWORK &&
                                "Ethereum"
                            }
                            { data && data.chainID && data.chainID == process.env.REACT_APP_POLYGON_NETWORK &&
                                "Avalanche"
                            }
                        { data && data.chainID && data.chainID != process.env.REACT_APP_POLYGON_NETWORK && data.chainID != process.env.REACT_APP_ETHEREUM_NETWORK &&
                                "Unknown network"
                            }
                        </Box>
                    </ListItem>
                    <ListItem button sx={data.chainID != process.env.REACT_APP_ETHEREUM_NETWORK && {display:'none'} }>
                        {data.formatBalance &&
                            <Button
                                variant="none"
                                size="large"
                                startIcon={<img src={eth} style={{marginLeft:'-6px',height:'35px', width:'35px'}} />}
                            >
                                {data.formatBalance.substring(0,6)} ETH
                            </Button>
                        }
                        { data && data.chainID && data.chainID == process.env.REACT_APP_ETHEREUM_NETWORK &&
                            <Button
                            disableElevation
                            variant="outlined"
                            size="small"
                                onClick={() => handleOpen()}
                            >
                            {t('topbar.fund_weth')} 
                            </Button>
                        }
                        { data && data.chainID && data.chainID == process.env.REACT_APP_POLYGON_NETWORK &&
                            <Button
                                sx={{
                                    border: '1px solid #393939',
                                    color:'#000',
                                    width:'50%',
                                    ':hover':{
                                        backgroundColor:'#8C8C8C',
                                        border:'1px solid #393939',
                                        color: '#fff'
                                    }
                                }}
                                variant="outlined"
                                size="small"
                                endIcon={<CompareArrowsIcon/>}
                                onClick={()=>switchEthereumChain(process.env.REACT_APP_ETHEREUM_NETWORK)}
                            >
                                {t('topbar.wallet.modal_switch_ethereum')}
                            </Button>
                        }
                    </ListItem>


                    <ListItem button>
                        {data && data.chainID && data.chainID == process.env.REACT_APP_ETHEREUM_NETWORK &&
                        <Button
                            variant="outlined"
                            size="large"
                            onClick={()=>switchEthereumChain(process.env.REACT_APP_POLYGON_NETWORK)}
                            startIcon={<img src={avalancheIcon} style={{height:'25px', width:'25px'}} />}
                            sx={{
                                border: '1px solid #925EE8',
                                color:'#925EE8',
                                width:'100%',
                                ':hover':{
                                    backgroundColor:'#E3D4FA',
                                    border:'1px solid #925EE8',
                                }
                            }}
                        >
                            {t('topbar.wallet.modal_switch_polygon')} ( {data.formatMaticBalance.substring(0,6)} MATIC)
                        </Button>}
                        {data && data.chainID && data.chainID == process.env.REACT_APP_POLYGON_NETWORK &&
                        <Button
                            variant="none"
                            size="large"
                            startIcon={<img src={avalancheIcon} style={{height:'25px', width:'25px'}} />}
                        >
                            {data.formatMaticBalance.substring(0,6)} AVAX
                        </Button>}
                    </ListItem>
                    <ListItem button>
                        {data && data.chainID && data.chainID == process.env.REACT_APP_POLYGON_NETWORK &&
                        <Button
                            variant="none"
                            size="large"
                            startIcon={<img src={avalancheIcon} style={{height:'25px', width:'25px'}} />}
                        >
                            {(data.formatWMaticBalance+'').toString().substring(0,6)} WAVAX
                        </Button>}
                    </ListItem>

                    <ListItem>
                        { data && (data.viewEmailInput && viewEmailInput) &&
                            <Box sx={{paddingTop:'1rem',width:'100%',display:'flex'}}>
                                <Box sx={{display:'flex',width:'100%',justifyContent:'center'}}>
                                    <form style={{width:'100%'}} onSubmit={(e)=>handleReceiveNotifications(e)}>
                                        <Box sx={{width:'100%'}}>
                                            <label>{t("topbar.wallet.modal_receive_notifications_label")}</label>
                                        </Box>
                                        <Input
                                            value={notifications}
                                            onChange={(e)=>{handleNotifications(e)}}
                                            type='email' placeholder="E-mail"
                                            disableUnderline
                                            disabled = {initRegistrationEmail}
                                            sx={{
                                                borderRadius: '10px',
                                                boxSizing: 'border-box',
                                                border:'solid 1px rgba(224, 224, 224,.1213)',
                                                outlineStyle:'red',
                                                padding: '3px',
                                                fontSize:'14px',
                                                background:'rgba(224, 224, 224,.43)',
                                                '@media screen and (max-width: 920px)': {
                                                    width:'100%'
                                                },
                                                "&:hover":{
                                                    background:'rgb(224, 224, 224)'
                                                },
                                                "&:focus::-webkitInputPlaceholder":{
                                                    color:'red'
                                                }
                                            }}
                                        />
                                        
                                            {
                                                initRegistrationEmail ? 
                                                <Button
                                                    disabled = { !emailAcceptButtonValid || initRegistrationEmail}
                                                
                                                    sx={{
                                                        marginLeft:'5px',
                                                        borderRadius: '0px',
                                                        backgroundColor: '#E0E0E0',
                                                        color: '#fff',
                                                        '-webkit-transform': 'skew(-16deg)',
                                                        '-moz-transform': 'skew(-16deg)',
                                                        '-ms-transform': 'skew(-16deg)',
                                                        '-o-transform': 'skew(-16deg)',
                                                        transform: 'skew(-16deg)',
                                                        '@media screen and (max-width: 920px)': {
                                                            width:'50%',
                                                            marginTop:'5px',
                                                            marginLeft:'0px'
                                                        }
                                                    }}
                                                    variant="contained"
                                                    size="small"
                                                >
                                                
                                                    {
                                                        initRegistrationEmail && 
                                                        <CircularProgress size={24}
                                                            sx={{
                                                                color: '##1B2635',
                                                                marginLeft: '5px',
                                                            }}
                                                        />
                                                    }
                                                    
                                                </Button>
                                                :
                                                <ButtonStyled
                                                    onClick={(e) => handleReceiveNotifications(e)}
                                                    text={t("topbar.wallet.modal_receive_notifications_btn")} 
                                                    width='30%'
                                                    fontSize='14px'
                                                    isDisabled={ !emailAcceptButtonValid || initRegistrationEmail}
                                                />
                                            }
                                        
                                        
                                    </form>

                                    
                                </Box>
                                <center>{
                                        msgError != '' &&
                                        <small style={{color:'red'}}>{msgError}</small>
                                    }</center>
                            </Box>
                        }
                    </ListItem>

                    <ListItem>
                        <Box
                            sx={{width:'100%',height:'100%',display:'flex',marginTo:'5x',justifyContent:'center'}}
                        >
                            {isEnglish() ?
                                <Box sx={{height:'356px',overflow:'auto',width:'100%'}}>
                                    <iframe id='iframe-widget' src='https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=0.011&from=btc&horizontal=false&lang=en-US&link_id=5e644f65bb9b85&locales=false&logo=true&primaryColor=CD1731&to=avax&toTheMoon=false' style={{
                                        //"height: 356px; width: 100%; border: none"
                                        width: '100%',
                                        height : '356px',
                                        border : 'none'
                                    }}/>
                                </Box>
                                :
                                <Box sx={{height:'356px',overflow:'auto',width:'100%'}}>
                                    <iframe id='iframe-widget' src='https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=true&amount=0.011&from=btc&horizontal=false&lang=es-ES&link_id=5e644f65bb9b85&locales=false&logo=true&primaryColor=CD1731&to=avax&toTheMoon=false' style={{
                                    //"height: 356px; width: 100%; border: none"
                                    width: '100%',
                                    height : '356px',
                                    border : 'none',
                                    }} />
                                </Box>
                            }
                        </Box>
                    </ListItem>

                    <ListItem
                        sx={{
                            marginTop:'20px',
                        }}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'center',
                                margin:'0 auto',
                                "@media screen and (max-width: 920px)": {
                                    width:'80%'
                                },
                                "@media screen and (max-width: 600px)": {
                                    width:'90%'
                                },
                                "@media screen and (max-width: 400px)": {
                                    width:'100%'
                                }
                            }}
                        >
                            <ButtonStyled
                                onClick={disconnect}
                                text={t('topbar.wallet.modal_logout_btn')} 
                                width='100%'
                            />
                        </Box>
                    </ListItem>


                </List>

                </nav>
            </Box>

        
            
            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                 
                <Box sx={{ ...style, width: 700,
                '@media screen and (max-width: 750px)': {
                    width: '100%'
                }}}>
                    <Box sx={{
                        display: 'grid',
                        width:'100%',
                        gridTemplateColumns:'repeat(1,1fr)',
                        textAlign:'center'
                    }}>
                        <Box sx={{
                            display:'flex',
                            width:'100%',
                            justifyContent:'flex-end'
                        }}>
                        <Button  variant="contained" 
                            sx={{
                               
                                background:"gray",
                                borderRadius:"20px 20px 20px 20px",
                                fontSize:"15px",
                                "&:hover":{
                                    background:"black"
                                }
                            }}
                            size="small"
                            onClick={ () => handleClose()}
                        >
                            <CloseIcon/>
                        </Button>
                        </Box>
                        <Typography  variant="h5" color="black" component="h2">
                            {t('topbar.wallet.modal_title')} 
                        </Typography>
                        <Typography  fontSize={"15px"} color="black" component="h2">
                            {t('topbar.wallet.modal_you_have')}: {data.formatBalance && data.formatBalance.substring(0,6)} ETH
                        </Typography>
                        <Typography  fontSize={"15px"} color="black" component="h2" sx={{margin:'0 auto'}}>
                        { (error != '') &&
                            <Alert severity="error">{error}!</Alert>
                        }
                        { (posTx != '') &&
                            <Alert severity="success"> 
                                Transaction 
                                <a 
                                    style={{textDecoration:'none', color:'#375C39', margin:'auto 5px auto 5px'}} 
                                    href={`https://etherscan.io/address/${posTx}`} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                >
                                    {(posTx).substring(0,5)}...{(posTx).substring(37,42)}
                                </a>
                                your weth will be available in 10 minutes!
                            </Alert>
                        }
                        </Typography>
                        {
                            Load ?  <LoaderCircle text={t("message_loader.wallet.load")} />
                            :
                            <form 
                                onSubmit={(e)=>handleConvert(e)}
                                style={{marginTop:'10px'}}
                            >
                                <div>
                                    <input 
                                        required 
                                        defaultChecked={wethTime} 
                                        onChange={()=>setWethTime(!wethTime)} 
                                        type="checkbox" 
                                    />
                                    <span>
                                        {t("topbar.time_transaction")}
                                    </span>
                                </div>
                                <div>
                                    <input 
                                        required 
                                        defaultChecked={wethSystem} 
                                        onChange={()=>setWethSystem(!wethSystem)} 
                                        type="checkbox" 
                                    />
                                    <span>
                                        {t("topbar.confirm_network")}
                                    </span>
                                </div>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <input required style={{
                                        padding:'10px',
                                        border:'solid 2px #ed2891',
                                        borderRadius:'10px',
                                    }} type="number" value={form} onChange={(e)=>handleChange(e)} />
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>    
                                    <Button 
                                        disabled={!wethTime || !wethSystem}
                                        variant="contained" 
                                            sx={{
                                                background:'#000',
                                                color:'#fff',
                                                "&:hover":{
                                                    background:"#000"
                                                }
                                            }}
                                        onClick={(e)=>handleConvert(e)}
                                    >
                                        {t('topbar.wallet.modal_btn_convert')}
                                    </Button>
                                </Typography>
                            </form>
                        }
                        <Typography  component="h2" fontSize={"12px"} sx={{marginTop:'7px'}}>
                            cryptoart.com uses the Hop <a href="https://app.hop.exchange/send?token=ETH&sourceNetwork=ethereum&destNetwork=polygon" rel="noopener noreferrer" target="_blank">bridge</a> to fund WETH account. if you want you can do it directly on their 
                            <a href="https://app.hop.exchange/send?token=ETH&sourceNetwork=ethereum&destNetwork=polygon" rel="noopener noreferrer" target="_blank"> site </a> 
                            by converting Eth to Weth.
                        </Typography>
                    </Box>
                </Box>
            </Modal>

        </React.Fragment>
    );
}

LogIn.propTypes = {
    data: PropTypes.object,
};


  

export default LogIn;
