import React, { Suspense } from 'react';
import { Box, Container } from '@mui/material'
import ErrorBoundary from 'components/ErrorBoundary';
import {fetchFAQSData} from './getFAQSData'
import { isEnglish } from 'views/cryptoloterita/determinateLanguage';
import BlockContent from '@sanity/block-content-to-react'
//import { useTranslation } from 'react-i18next';
import LoaderCircle from 'components/LoaderCircle';
import ServerError from 'components/ServerError';
import PropTypes from 'prop-types'

const resourceFAQS = fetchFAQSData();

const BlockContentFAQS = ({data}) => {
    const faqs = data.faqs.read()[0];
    return (
        <React.Fragment>
            {
                faqs && faqs.english ||  faqs && faqs.spanish ?
                <Box sx={{width:'100%',display:'flex',justifyContent:'center',textAlign:'left'}}>
                    <BlockContent blocks={ isEnglish() ? faqs.english: faqs.spanish } projectId="klqezt1e"/>    
                </Box>
                :
                <ServerError />

            }
           
        </React.Fragment>
    )
}

const FAQS = () => {
    //const {t} = useTranslation("translate");
    return (
        <React.Fragment>
            <center>
                <Box 
                    component='h1'
                    sx={{
                        color:'#000', 
                        fontSize:'1.9vw',
                        textAlign:'center',
                        margintop:'-14px',
                        '@media screen and (max-width: 600px)': {
                            fontSize:'7vw',
                            margintop:'0px',
                        }
                    }}
                > 
                    FAQS
                </Box>
            </center>
            <Container maxWidth='md'>
                {
                    resourceFAQS && 
                    <ErrorBoundary fallback={<ServerError />}>
                        <Suspense fallback={<LoaderCircle text='loading' />}>
                        <BlockContentFAQS data={resourceFAQS} />
                        </Suspense>
                    </ErrorBoundary>
                }
               
            </Container>
        </React.Fragment>
       
    )
    
}


BlockContentFAQS.propTypes = {
    data: PropTypes.object,
}

export default FAQS;