import React ,{useContext} from 'react'
import { Box, CardMedia } from '@mui/material';
// import LotteryContainer from 'components/LotteryContainer';
import uploadPicNFT from 'assets/images/upload_pic.jpg';
import uploadPicCollection from 'assets/images/upload_pic_2.jpg';
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Context } from 'hooks/WalletContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateNFT = styled(Link)`
    text-decoration: none;
    color: #000;
    &:hover {
        color: '#000',
    };
`

const Create = () => {
    const {t} = useTranslation("translate")
    const {data} = useContext(Context);
    const history = useNavigate();

    if(data && data.userAccount && data.userAccount !== "undefined"){
        return (
            <React.Fragment>
                <Box
                    component='h1'
                    sx={{
                        textAlign: 'center',
                        fontSize: '2rem',
                        fontWeight: 600,
                    }}
                >
                   {t("create_page.title")}
                </Box>
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    alignContent='center'
                    sx={{
                        gap:'200px',
                        marginTop:'5rem',
                        '@media (max-width: 600px)': {
                            flexDirection: 'column',
                            gap: '10px',
                            marginTop: '0',
                        }
                    }}
                >
                    <CreateNFT to='/create/select-collection'>
                        <Box
                            display='flex'
                            justifyContent='center'
                            flexDirection='column'
                            alignItems='center'
                            sx={{
                                gap:'1rem',
                                border:'1px solid #e6e6e6',
                                boxShadow:'0px 0px 10px #e6e6e6',
                                borderRadius:'10px',
                                boxSizing:'border-box',
                                padding:'1rem',
                                cursor:'pointer',
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                                },
                            }}
                        >
                            <CardMedia
                                component="img"
                                src={uploadPicNFT}
                                sx={{
                                    borderRadius:'10px',
                                }}
                            />
                            <Box>
                                <Box
                                    component='h3'
                                    sx={{
                                        fontSize:'1.5rem',
                                        textAlign:'center',
                                    }}
                                >
                                   {t("create_page.mint_nft")}
                                </Box>
                            </Box>
                        </Box>
                    </CreateNFT>
                    <CreateNFT to='/create/create-collection'>
                        <Box
                            display='flex'
                            justifyContent='center'
                            flexDirection='column'
                            alignItems='center'
                            sx={{
                                gap:'1rem',
                                border:'1px solid #e6e6e6',
                                boxShadow:'0px 0px 10px #e6e6e6',
                                borderRadius:'10px',
                                boxSizing:'border-box',
                                padding:'1rem',
                                cursor:'pointer',
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-2px)',
                                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                                },
                            }}
                        >
                            <CardMedia
                                component="img"
                                src={uploadPicCollection}
                                sx={{
                                    borderRadius:'10px',
                                }}
                            />
                            <Box>
                                <Box
                                    component='h3'
                                    sx={{
                                        fontSize:'1.5rem',
                                        textAlign:'center',
                                    }}
                                >
                                    {t("create_page.create_collection")}
                                </Box>
                            </Box>
                        </Box>
                    </CreateNFT>
                </Box>
            </React.Fragment>
        )
    }else{
        history.push('/home');
        return null;
    }  
   
}

export default Create