import React, { useContext, useEffect, useState } from 'react';
import { Box, Modal, CardMedia, Checkbox } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ButtonStyled from './ButtonStyled';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import {RiQuestionnaireLine} from 'react-icons/ri';
import { useNavigate  } from 'react-router-dom';
import  { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import LogoProfileBlue from 'assets/logos/logo_profile_blue.svg'


const ModalWelcome = () => {
    const { t } = useTranslation("translate");
    const [welcome, setWelcome] = useState(false);
    const {setOpenWallet} = useContext(DrawerMobileContext);
    const [showWelcome, setShowWelcome] = useState(false);
    const history = useNavigate();
    useEffect(() => {
        const showModal = localStorage.getItem('showOff');
        if(showModal){
            setWelcome(false);
        }
        if(!showModal){
            setWelcome(true);
        }
    } , []);
    const handleOpenWallet = () => {
        setOpenWallet(true);
        setWelcome(false);
    }
    const handleOpenTutorial = () => {
        setWelcome(false);
        window.open('https://www.youtube.com/watch?v=LfgUjJZVbhI', '_blank')
    }
    const handleClickPush = () => {
        history.push('/FAQS');
        setWelcome(false);
        //window.location.href = '/FAQS';
    }
    const handleCloseModal = () => {
        if(showWelcome){
            localStorage.setItem('showOff', false)
        }
        setWelcome(false);
    }
    return (
        <Modal
            open={welcome}
            onClose={()=>handleCloseModal()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            sx={{
                border: 'none',
                overflow:'auto',
            }}
        >
            <Box 
                sx={{
                    width:660, 
                    padding:'2rem 2rem 0px', 
                    margin:'0 auto', overflow:'auto', 
                    borderRadius:'8px',
                    border: 'none',
                    outline: 'none',
                    ':focus': {
                        outline: 'none',
                    },
                    '@media (max-width:850px)':{
                        width: '100%',
                        margin: '0 auto',
                    }
                }}
                >
                <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' alignContent='center'>
                    <Box display='flex' justifyContent='center' flexDirection='column' sx={{backgroundColor:'#fff', padding:'0 2rem'}}>
                        <Box 
                            display="flex"
                            justifyContent="flex-end"
                            onClick={()=>handleCloseModal()}
                            sx={{cursor:'pointer'}}
                        >
                            <CloseIcon />
                        </Box>
                        <Box>
                            <CardMedia component='img' src={LogoProfileBlue} sx={{width:'20%', height:'20%', margin:'0 auto'}} alt='welcome' />
                        </Box>
                        <Box
                            component='h3'
                            sx={{
                                color:'rgba(0,0,0,.87)',
                                fontFamily:'Titillium Web, sans-serif',
                                fontWeight:500,
                                fontSize: '1.75rem',
                                textAlign:'center',
                                marginTop:'0px',
                                '@media (max-width:250px)':{
                                    fontSize: '17px',
                                }
                            }}
                        >
                            {t('modal_welcome.title')}
                        </Box>
                    </Box>
                    <Box sx={{backgroundColor:'#2d3748', color:'#fff', textAlign:'center', padding:'0 2rem', width:'100%'}}>
                        {/* <Box 
                            component='h5'
                            sx={{
                                fontFamily:'Titillium Web, sans-serif',
                                fontWeight:500,
                                fontSize: '1.25em',
                                marginBottom:'0px',
                            }}
                        >
                            {t('modal_welcome.subtitle_one')}
                        </Box> */}
                        <Box 
                            component='h5'
                            sx={{
                                fontFamily:'Titillium Web, sans-serif',
                                fontWeight:500,
                                fontSize: '1.25em',
                                '@media (max-width:250px)':{
                                    fontSize: '14px',
                                }
                            }}
                        >
                            {t('modal_welcome.subtitle_two')}
                        </Box>
                        <Box display='flex' justifyContent='center' 
                            sx={{
                                gap:'1rem',
                                marginBottom:'1rem',
                                "@media (max-width:420px)":{
                                    flexDirection:'column'
                                }
                            }}
                        >
                            <ButtonStyled
                                onClick={handleOpenWallet}
                                text={t('modal_welcome.connect_wallet_btn')}
                                width='90%'
                            />
                            <ButtonStyled
                                onClick={handleOpenTutorial}
                                text={t('modal_welcome.tutorial_btn')}
                                width='90%'
                            />
                        </Box>
                        <Box display='flex' justifyContent='space-around' 
                            sx={{
                                gap:'2rem',
                                marginBottom:'1rem',
                                fontSize:'12px',
                                "@media (max-width:250px)":{
                                    flexDirection:'column'
                                }
                            }}
                        >
                            <Box>
                                <span style={{marginRight:'5px'}}>
                                    {t('modal_welcome.faq')}
                                </span>
                                <a onClick={()=>setWelcome(false)} style={{color:'inherit'}} href='https://t.me/ChivasNFT' target='_blank' rel="noopener noreferrer">
                                    {t('modal_welcome.faq_link')}
                                </a>
                            </Box>
                            <Box onClick={handleClickPush} sx={{cursor:'pointer'}}>
                                <span>
                                    {t('modal_welcome.faq_text')}
                                </span>
                                <RiQuestionnaireLine />
                            </Box>
                        </Box>
                        <Box>
                            <span>{t('modal_welcome.dont_show_again')}</span>
                            <Checkbox 
                                onChange={()=>setShowWelcome(!showWelcome)} 
                                defaultChecked={showWelcome} 
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

// ModalWelcome.propTypes = {
//     isOpen: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired
// }

export default ModalWelcome