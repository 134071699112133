import React, { useContext, useEffect, useState} from 'react';
import { Box, Container, Divider} from '@mui/material';
//import ErrorBoundary from 'components/ErrorBoundary';
//import ShowError from 'components/ErrorMessage';
import LoaderCircle from 'components/LoaderCircle';
import { useLocation } from 'react-router-dom';
import { ActivitySectionProfile,Header,MyNFTs,ProfileData,Offers} from './components';
import styled from '@emotion/styled'
import { BsFillGrid1X2Fill } from 'react-icons/bs';
import { Context } from 'hooks/WalletContext'
import { getMaticInUSD } from 'services/getMaticInUSD'
import TocIcon from '@mui/icons-material/Toc';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
//import SideBar from 'components/SideBar';
import SortByDropdown from './components/SortByDropdown';
import { getUser } from 'services/User/getUser';
import { MdCollections } from 'react-icons/md'; 
import CollectionCard from 'components/CollectionCard';
import { useFetch } from 'hooks/useFetch';
import LoaderCollection from 'components/LoaderCollection';
import ErrorMessage from 'components/ErrorMessage';
import NFTsCard from 'components/NFTsCard';
import LoaderNFT from 'components/LoaderNFT';
import OfferHistory from 'components/OfferHistory';
import { sign } from 'services/Utils/signature';

const ActiveTag = styled(Box)`
    border-bottom: ${props => props.active ? '3px solid #000' : 'null'};
    font-weight: ${props => props.active ? 'bold' : 'null'};
    //background-color: ${props => props.active ? '#F344A1' : '#000'};
    ${props => props.styles}
`

const Profile = () => {
    const {t} = useTranslation("translate");
    const {data} = useContext(Context)
    const [user, setUser] = useState(null);
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const address = query.get("address");
   // let resource = fetchUserNFT(address)
    const [userNfts, setUserNfts] = useState(true);
    const [offers, setOffers] = useState(false);
    const [userActivity, setUserActivity] = useState(false);
    const [profile,setProfile] = useState(false);
    const [load,setLoad] = useState(false);
    const [msgProfile,setMsgProfile] = useState(false);
    const [myCollections,setMyCollections] = useState(false);
    const [myNFTs,setMyNFTs] = useState(false);
    const [showMsgRegister,setShowMsgRegister] = useState(false)
    const [userCollection, setUserCollection] = useState(false);
    const [showMyInfo,setShowMyInfo] = useState(false);
    const [firstRequestInfo,setFirstRequestInfo] = useState(false);
    const collectionUrl = `${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&key_name=OWNER&key_val=${address}&page=0&limit=10&order=created`
    const ownerCollectionUrl = `${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&key_name=OWNER&key_val=${address}&page=0&limit=10&order=created`
    const ownerNFTsUrl = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=10&page=0&order=created`
    const historyUrl = process.env.REACT_APP_URL_API+`/history?domain=${process.env.REACT_APP_DOMAIN}&wallet=${address}&blockchain=${process.env.REACT_APP_POLYGON_NETWORK_NAME}&page=0&limit=30&order=created`
    const {data: dataCollection, loading: loaderCollection, error: errorCollection} = useFetch(collectionUrl);
    const {data: collectionsOwner, loading: loaderCollectionOwner, error: errorCollectionOwner} = useFetch(ownerCollectionUrl);
    const {data: NFTsOwner, loading: loaderNFTsOwner, error: errorNFTsOwner} = useFetch(ownerNFTsUrl);
    const {data:dataHistory, error:errorHistory, loading:loadingHistory} = useFetch(historyUrl)
    const [addressTemp,setAddressTemp] = useState(address)

    useEffect(()=>{
        if(!address){
            window.location.href = '/'
        }
    },[])
  
    const [dataTemp,setDataTemp] = useState(
        {
            username:'',
            email:''
        }
    )
    const [formEditProfile,setFormEditProfile] = useState({
        avatar:'',
        fileAvatar: [],
        banner:'',
        fileBanner: [],
        username:'',
        email:'',
        validateAttr:{username:null,email:null,avatar:null,banner:null}
    });

    const [editProfile,setEditProfile] = useState(false);
    const [initEditProfile, setInitEditProfile] = useState(false);

    const handleActiveEditProfile = ()=>{
        if(!initEditProfile){
            //setFormEditProfile({...formEditProfile,avatar:'',fileAvatar:'',fileBanner:'',banner:'',username:'',email:''});
            setEditProfile(!editProfile);
            if(editProfile){
                setUserNfts(false)
                setUserActivity(false)
                setProfile(true)
                setOffers(false)
                setUserCollection(false)
                setMyCollections(false)
                setMyNFTs(false)
            }else{
                setUserNfts(false)
                setUserActivity(false)
                setProfile(true)
                setOffers(false)
                setUserCollection(false)
                setMyCollections(false)
                setMyNFTs(false)
            }
        }
    }
   
    const handleShowNFTtComponent = () => {
        if(!initEditProfile){
            setUserNfts(true)
            setUserActivity(false)
            setProfile(false)
            setOffers(false)
            setEditProfile(false)
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(false)
        }
    }

    const handleCollectionsComponent = () => {
        if(!initEditProfile){
            setUserNfts(false)
            setUserActivity(false)
            setProfile(false)
            setOffers(false)
            setEditProfile(false)
            setUserCollection(true)
            setMyCollections(false)
            setMyNFTs(false)
        }
    }

    const handleShowActivityComponent = ()=>{
        if(!initEditProfile){
            setUserNfts(false)
            setUserActivity(true)
            setProfile(false)
            setOffers(false)
            setEditProfile(false)
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(false)
        }
    }

    const handleShowProfileComponent = ()=>{
        if(!initEditProfile){
            setShowMsgRegister(localStorage.getItem('viewEmailInput') == "true" ? true:false)
            console.log("showMsgRegister::",showMsgRegister);
            setUserNfts(false)
            setUserActivity(false)
            setProfile(true)
            setOffers(false)
            setEditProfile(false)
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(false)
        }
    }
    const handleShowOffersComponent = ()=>{
        if(!initEditProfile){
            setUserNfts(false)
            setUserActivity(false)
            setProfile(false)
            setOffers(true)
            setEditProfile(false);
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(false)
        }
    }
    const handleShowMyCollections = ()=>{
        if(!initEditProfile){
            setUserNfts(false)
            setUserActivity(false)
            setProfile(false)
            setOffers(false)
            setEditProfile(false);
            setUserCollection(false)
            setMyCollections(true)
            setMyNFTs(false)
        }
    }
    const handleShowMyNFT = ()=>{
        if(!initEditProfile){
            setUserNfts(false)
            setUserActivity(false)
            setProfile(false)
            setOffers(false)
            setEditProfile(false);
            setUserCollection(false)
            setMyCollections(false)
            setMyNFTs(true)
        }
    }

    const getMyDataProfile = async ()=>{
        setMsgProfile('');
        setLoad(true);
        let userData = null;
        let {signature, message} = await sign("Show mi info",address,data.provider).catch((error =>{
            console.log("error::",error);
            setMsgProfile(error.message+'');
            setLoad(false)
            return {signature:null, message:error.message};
        }));

        if(signature){
            userData = await getUser(address,message,signature).finally(()=>{setLoad(false);setMsgProfile('')}).catch(err=>{console.log("error profile",err);setMsgProfile(err+'');setLoad(false)})
            if(userData){
                setUser(userData)
                console.log("profile init::",userData)
                console.log("profile init::",user)
                if(userData && userData.username){
                    setShowMyInfo(true)
                    console.log("username ::", userData.username)
                    setFormEditProfile({...formEditProfile,username:userData.username, email : userData.email})
                    setDataTemp({...dataTemp,username:userData.username,email:userData.email})
                    setLoad(false)
                }
            }else{
                setLoad(false)
            }
            
        }

       
    }

    const getDataProfile = async ()=>{
        setFormEditProfile({...formEditProfile,username:'',avatar:'',fileAvatar:[],email:'',banner:'',fileBanner:[]});
        setDataTemp({...dataTemp,username:'', email :''});
        setUser(null)
        setLoad(true)
        let isOwner = false
        let wallet = localStorage.getItem('current_wallet')
        console.log('Use wallet')
        if (wallet) {
            isOwner =  (address.toUpperCase() == wallet.toUpperCase()) ? true : false
        }
        let userData = null;
        if(isOwner && data && data.provider) {
            // let {signature , message} = await sign("Show mi info",address,data.provider);
            console.log("is owner: true");
            userData = await getUser(address).finally(()=>{setLoad(false);setMsgProfile('')}).catch(err=>{console.log("error profile",err);setMsgProfile(err+'');setLoad(false)})
        } else {
            userData = await getUser(address).finally(()=>{setLoad(false);setMsgProfile('')}).catch(err=>{console.log("error profile",err);setMsgProfile(err+'');setLoad(false)})
        }
       console.log('Use is owner', isOwner)
       if(userData) {
            setUser(userData)
            console.log("profile init::",userData)
            console.log("profile init::",user)
            if(userData && userData.username){
                console.log("username ::", userData.username)
                setFormEditProfile({...formEditProfile,username:userData.username, email : userData.email})
                setDataTemp({...dataTemp,username:userData.username, email : userData.email})
            }
        }
    }
    useEffect(()=>{
        try {
            if(address != null && address != 'undefined' && firstRequestInfo == false || address != addressTemp){
                setFirstRequestInfo(true)
                setAddressTemp(address)
                getDataProfile(); 
            }
            getMaticInUSD().then((usd) => {
                localStorage.setItem('USD',""+usd)
            })
        } catch (error) {
            console.log(error)
        }
    },[address,addressTemp])
    
    if(address === null){
        return <LoaderCircle text='loading profile' />
    }

    return (
        <Box component="header" sx={{position:'relative'}}>
            <Header 
                formEditProfile={formEditProfile} 
                user={user} 
                address={address} 
                data={data}
                handleActiveEditProfile={handleActiveEditProfile}
                editProfile={editProfile}
                initEditProfile={initEditProfile}
            />
            <Box
                component='section'
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    '@media screen and (max-width: 768px)': {
                        display:'grid',
                        width:'100%',
                        gridTemplateColumns:'1fr',
                    }
                }}
            >
                <Box 
                    component='nav' 
                    display='flex' 
                    justifyContent='center' 
                    alignContent='center'
                    sx={{
                        '@media screen and (max-width: 768px)': {
                            width:'100%',
                            overflowX:'scroll',
                            paddingLeft:'5px',
                            paddingRight:'5px',
                            justifyContent:'flex-start'
                        }
                    }}
                >
                    <ActiveTag active={userNfts}  component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowNFTtComponent}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><BsFillGrid1X2Fill size={20} /><span style={{fontSize:'20px', marginLeft:'5px'}}>NFTs</span></Box>
                    </ActiveTag>
                    <ActiveTag active={userCollection} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleCollectionsComponent}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><MdCollections size={25} /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.tab_collections")}</span></Box>
                    </ActiveTag>
                    {data && data.userAccount && data.userAccount != 'undefined' && (data.userAccount + '').toUpperCase() == (address+'').toUpperCase() &&
                    <ActiveTag active={myCollections} component='div' sx={{display:'none',margin:'auto 25px', cursor:'pointer'}} onClick={handleShowMyCollections}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><MdCollections size={25} /><span style={{fontSize:'20px', marginLeft:'5px'}}>Created Collections</span></Box>
                    </ActiveTag>}
                    {data && data.userAccount && data.userAccount != 'undefined' && (data.userAccount + '').toUpperCase() == (address+'').toUpperCase() &&
                    <ActiveTag active={myNFTs} component='div' sx={{display:'none',margin:'auto 25px', cursor:'pointer'}} onClick={handleShowMyNFT}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><BsFillGrid1X2Fill size={20} /><span style={{fontSize:'20px', marginLeft:'5px'}}>NFTs Created</span></Box>
                    </ActiveTag>}
                    <ActiveTag active={offers} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowOffersComponent}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><LocalOfferIcon /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.activity")}</span></Box>
                    </ActiveTag>
                    <ActiveTag active={userActivity} component='div' sx={{display:'none',margin:'auto 25px', cursor:'pointer'}} onClick={handleShowActivityComponent}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><TocIcon /> <span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.activity")}</span></Box>
                    </ActiveTag>
                    {data && data.userAccount && data.userAccount != 'undefined' &&  user && user.info && (data.userAccount + '').toUpperCase() == (address+'').toUpperCase() ?
                    <ActiveTag active={profile} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowProfileComponent}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><PersonIcon /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.profile")}</span></Box>
                    </ActiveTag>
                    :
                    data && data.userAccount && data.userAccount != 'undefined' && (data.userAccount + '').toUpperCase() == (address+'').toUpperCase() ? 
                    <ActiveTag active={profile} component='div' sx={{margin:'auto 25px', cursor:'pointer'}} onClick={handleShowProfileComponent}>
                        <Box sx={{display:'flex',justifyContent:'center',alignItems: 'center'}}><PersonIcon /><span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.profile")}</span></Box>
                    </ActiveTag>
                    :''}
                </Box>
                
            </Box>
            <Divider />
            <Box
                sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'flex-end',
                    alignItems:'center'
                }}
            >   <Box>
                    <SortByDropdown />
                </Box>
            </Box>
            
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    gap:'20px',
                    '@media screen and (max-width: 750px)': {
                        flexDirection: 'column',
                    }
                }}
            >
                {/* <SideBar /> */}
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    sx={{width:'100%'}}
                >
                    <Container maxWidth="90%" sx={{marginTop:'40px',marginBottom:'30px'}}>
                        {load ? <LoaderCircle text='loading profile' /> :
                        <>
                        {
                            user && 
                            <>
                                {
                                    userNfts && <MyNFTs user={user} address={address} data={data} />  
                                }
                                {loadingHistory && <div>loading...</div>}
                                {errorHistory && <ErrorMessage error={errorHistory.message} />}
                                {dataHistory && offers &&
                                    <>
                                    <Container maxWidth='lg'>
                                        <OfferHistory content={dataHistory} />
                                    </Container>
                                    <Offers 
                                        user={user}
                                        address={address} 
                                        data={data} 
                                    />
                                    </>
                                }
                                {
                                    userActivity && 
                                    <ActivitySectionProfile 
                                        user={user}
                                        address={address} 
                                    />
                                }
                                {
                                    myCollections && loaderCollectionOwner &&
                                    <LoaderCollection />
                                }
                                {
                                    myCollections && errorCollectionOwner &&
                                    <ErrorMessage error={errorCollectionOwner.message} />
                                }
                                {
                                    myCollections && collectionsOwner &&
                                    <CollectionCard content={dataCollection} />
                                }
                                {
                                    myNFTs && loaderNFTsOwner &&
                                    <LoaderNFT />
                                }
                                {
                                    myNFTs && errorNFTsOwner && 
                                    <ErrorMessage error={errorNFTsOwner.message} />
                                }
                                {
                                    myNFTs && NFTsOwner && NFTsOwner.length > 0 &&
                                    <NFTsCard content={NFTsOwner} />
                                }
                                {
                                    userCollection && loaderCollection &&
                                    <LoaderCollection />
                                }
                                {
                                    userCollection && errorCollection &&
                                    <ErrorMessage error={errorCollection.message} />
                                }
                                {
                                    userCollection && dataCollection &&
                                    <CollectionCard content={dataCollection} limit={dataCollection.length} />
                                }
                                {
                                    data && data.userAccount && data.userAccount != 'undefined' && user && profile &&
                                    <ProfileData 
                                        formEditProfile={formEditProfile} 
                                        setFormEditProfile={setFormEditProfile}
                                        initEditProfile={initEditProfile}
                                        isEditProfile={editProfile}
                                        isSetEditProfile={setEditProfile}
                                        setInitEditProfile={setInitEditProfile}
                                        user={user} 
                                        address={address} 
                                        data={data} 
                                        dataTemp={dataTemp}
                                        setDataTemp={setDataTemp}
                                        showMsgRegister={showMsgRegister}
                                        setShowMsgRegister={setShowMsgRegister} 
                                        getMyDataProfile={getMyDataProfile}  
                                        showMyInfo={showMyInfo}  
                                        getDataProfile={getDataProfile}     
                                    />
                                }
                            </>}
                        </>}
                        <center>
                            {
                                msgProfile != '' && msgProfile
                            }
                        </center>
                    </Container>
                </Box>
            </Box>
        </Box>
    );
}

export default Profile;
