import React from 'react'
import { Box, CardMedia } from '@mui/material';
import PropTypes from 'prop-types';

const Banner = ({content,isVideo, randomThumb,children}) => {
    let temp = 22;
    if(temp === -22){
        console.log('Necesitamos visualizar video',isVideo)
    }
  
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            sx={{
                gap:'1rem',
                //backgroundImage:`url(https://f8n-production.imgix.net/collections/reuben-cover.jpg?q=75&w=1600&auto=format%2Ccompress&fit=fill)`,
                backgroundImage:`url(${content})`,
                backgroundSize:'cover',
                backgroundPosition:'center',
                backgroundRepeat:'no-repeat',
                width:'100%',
                minHeight:'460px',
                boxSizing:'border-box',
                paddingLeft:'10rem',
                '@media screen and (max-width: 768px)':{
                    paddingLeft:'0px',
                    alignItems:'center',
                }
            }}
        >
            <Box
                sx={{
                    boxSizing:'border-box',
                    padding: '10px',
                    borderRadius:'8px',
                    marginTop:'2rem',
                    backdropFilter: 'blur(10px)',
                    backgroundColor:'rgba(255, 255, 255, 0.2)',
                    '@media screen and (max-width: 768px)':{
                        marginTop:'0px',
                    }
                }}
            >
                <CardMedia  
                    component={'img'}
                    alt='collection'
                    src={randomThumb}
                    sx={{
                        width:'160px',
                        height:'160px',
                        borderRadius:'8px',
                    }}
                />
            </Box>
            {children}
        </Box>
    )
}

Banner.propTypes = {
    content: PropTypes.string.isRequired,
    isVideo: PropTypes.bool.isRequired,
    randomThumb: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default Banner