import React from 'react';
import { Container, Grid, Skeleton,Box} from '@mui/material';

const NavInfoLoader = () => {

    return (
        <Container maxWidth="xl">
            <Grid container columns={{ xs: 12, sm: 12, md:12, lg:12 }}>
                {
                    [0,1,2,3,4,5].map((val)=>{
                        return(
                            <Grid key={val} item xs={6} sm={6} md={4} lg={2} 
                                sx={{
                                    height:195
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}
                                >
                                    <Skeleton width="98%" 
                                        sx={{
                                            height:305,
                                            borderRadius:'8px'
                                        }}
                                    /> 
                                </Box>
                            </Grid>
                        )
                    })
                }

                <Grid item xs={12} sm={12} md={6} lg={6} 
                    sx={{
                        height:615
                    }}
                >
                    <Grid container columns={{ xs: 12, sm: 12, md:12, lg:12 }} justifyContent="flex-start">
                        <Grid item xs={12} sm={12} md={8} lg={8} 
                            sx={{
                                height:615
                            }}
                        >
                            <Box
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'
                                }}
                            >
                                <Skeleton width="98%" 
                                    sx={{
                                        height:970,
                                        borderRadius:'8px'
                                    }}
                                /> 
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} 
                    sx={{
                        height:615
                    }}
                >
                    <Grid container columns={{ xs: 12, sm: 12, md:12, lg:12 }} justifyContent="center">
                        <Grid item xs={12} sm={12} md={9} lg={9} 
                            sx={{
                                height:615
                            }}
                        >
                            <Box
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'
                                }}
                            >
                                <Skeleton width="98%" 
                                    sx={{
                                        height:970,
                                        borderRadius:'8px'
                                    }}
                                /> 
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </Container>
    );

}

export default NavInfoLoader;