import React from 'react'
import { Avatar, Box, Button, Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material'
import { profiles } from './utils/profiles'
import PropTypes from 'prop-types'

const ProfileCard = ({showBtnAll, content, limit}) => {
    return <>
        <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
            <Grid 
                container 
                columns={{sm:12, md:12, lg:12, xl:12}}
                rowSpacing={4} 
                spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
            >
                {content.slice(0, limit).map((item, index)=>(
                <Grid 
                    key={index} 
                    item 
                    sm={12} 
                    md={6} 
                    lg={3} 
                    xl={3}
                >
                    <Card
                        sx={{
                            height:'60vh',
                            width:'100%',
                            borderRadius:'8px',
                            cursor:'pointer',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                            },
                            '@media screen and (max-width: 768px)': {
                                height:'75vh',
                            }
                        }}
                    >
                        <CardMedia
                            component="img"
                            src={item.banner_url}
                            alt="art"
                            autoPlay
                            loop
                            muted
                            sx={{
                                borderRadius: '8px 8px 0 0',
                                height:'200px',
                                width:'100%',
                                margin: '0 auto',
                            }}
                        />
                        <CardContent
                            sx={{
                                display:'flex',
                                flexDirection:'column',
                            }}
                        >
                            <Box>
                                <Avatar 
                                    alt="CryptoArt"
                                    src={item.profile_pic_url}
                                    sx={{
                                        marginTop:'-40px',
                                        border: '4px solid #fff',
                                        width: 65, 
                                        height: 65
                                    }}
                                />
                                <Typography 
                                    variant="overline" 
                                    display="block" 
                                    gutterBottom 
                                    sx={{
                                        fontSize:'25px',
                                        color:'#000',
                                        fontWeight: 600,
                                        letterSpacing:'-0.02em',
                                    }}
                                >
                                    {item.username}
                                </Typography>
                                <Typography 
                                    variant="overline" 
                                    display="block" 
                                    gutterBottom 
                                    sx={{
                                        fontSize:'24px',
                                        color:'#000',
                                        fontWeight: 600,
                                        letterSpacing:'-0.01em',
                                        background: 'linear-gradient(110.78deg, #76E650 -1.13%, #F9D649 15.22%, #F08E35 32.09%, #EC5157 48.96%, #FF18BD 67.94%, #1A4BFF 85.34%, #62D8F9 99.57%)',
                                        backgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        marginTop:'-30px',
                                    }}
                                >
                                    @{item.username}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    fontSize:'16px',
                                    lineHeight:'1.7',
                                    fontWeight: 600,
                                    fontFamily: '"Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                }}
                            >
                                {item.about}
                            </Box>
                        </CardContent>
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            sx={{
                                marginTop:'20px',
                                borderTop:'1px solid #e0e0e0',
                            }}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        color:'#000',
                                        fontSize:'24px',
                                        fontWeight: 600,
                                        letterSpacing:'-0.01em',
                                        fontFamily: '"Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

                                    }}
                                >
                                   {item.followers}
                                </Box>
                                <Box
                                    sx={{
                                        color:'#666',
                                        fontSize:'18px',
                                        fontWeight: 600,
                                    }}
                                >
                                    Followers
                                </Box>
                            </CardContent>
                            <CardContent>
                                <Button
                                    variant="outlined"
                                    type='button'
                                    sx={{
                                        borderRadius:'9999px',
                                        backgroundColor:'#fff',
                                        color:'#000',
                                        border:'1px solid #e3e3e3',
                                        fontSize:'18px',
                                        '&:hover':{
                                            backgroundColor:'#000',
                                            transition:'background-color 0.3s ease-in-out',
                                            color:'#fff',
                                            border:'1px solid #000',
                                        }
                                    }}
                                >
                                    Follow
                                </Button>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
                ))}
            </Grid>
        </Container>
        {showBtnAll &&
        (<Container 
            maxWidth='sm' 
            sx={{
                display: 'flex',
                marginTop:'2rem',
                justifyContent:'center',
            }}
        >
            <Button
                variant="outlined"
                type="button"
                sx={{
                    borderRadius:'9999px',
                    backgroundColor:'#fff',
                    color:'#000',
                    border:'1px solid #e3e3e3',
                    fontSize:'18px',
                    '&:hover':{
                        backgroundColor:'#000',
                        transition:'background-color 0.3s ease-in-out',
                        color:'#fff',
                        border:'1px solid #000',
                    }
                }}
            >
                View all Profiles
            </Button>
        </Container>)}
    </>
}

ProfileCard.defaultProps = {
    showBtnAll: false,
    content: profiles,
    limit: 8,
}

ProfileCard.propTypes = {
    showBtnAll: PropTypes.bool,
    content: PropTypes.array,
    limit: PropTypes.number,
}

export default ProfileCard