import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Modal,Box,Button,Alert} from '@mui/material';
import { cancelAllByIds } from 'views/cryptoloterita/NFT/components/ModalOffers/components/cancelAllRequests/cancelAllByIds';
import CloseIcon from '@mui/icons-material/Close'
import {transferCrypto} from 'views/cryptoloterita/Blockchain/transferCrypto';
import LoaderCircle from 'components/LoaderCircle';

const ModalCancelOffersMade  = ({openModalOffersMade,arrChecked,setArrChecked,handleCloseModalOffersMade,data,setMovements,movements,setArrOffers}) =>{
    const {t} = useTranslation("translate");

    const [msg,setMsg] = React.useState('');
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [finishSuccess,setFinishSuccess] = React.useState(false);
    const to = process.env.REACT_APP_WALLET;


    const handleCloseModal = () =>{
        if(!loading){
            setMsg('');
            setError(false);
            setLoading(false);
            setFinishSuccess(false);
            handleCloseModalOffersMade()
        }
    }

    const resetArrOffersMade = () => {
        
        let arrTemp = new Array();
        movements.map((val)=>{
              console.log("click cancel 1", val);
            arrChecked.map((arr)=>{
                if(arr.id == val.id && !arr.check){
                    arrTemp.push(val)
                }
           })
        })
        setMovements(arrTemp)
        setArrOffers(arrTemp)
        setArrChecked([])

        console.log("click cancel 1", arrTemp);
       // console.log("click cancel 1",movements.filter((val) => val.id !==  newArray.includes(val.id)));
       // console.log("click cancel 2",newArray.filter(item => item.checked === true));
    }

    const handleCancelOffersMade = () =>{
        
      
        if(data && data.userAccount){
          
            let requestIDS = []
            arrChecked.forEach((check) => {
                if(check.check) {
                    requestIDS.push(check.id)
                }
            })
            
            if(requestIDS.length > 0){
                setMsg('')
                setError(false)
                //transferCrypto(data.userAccount,to,(requestIDS.length*0.21),requestIDS,data.provider,(success) => {
                transferCrypto(data.userAccount,to,0.00001,requestIDS,data.provider,(success) => {
                    console.log('success cancel offers made::',success)
                    cancelAllByIds(requestIDS).then((success) => {
                        console.log(success)
                            setMsg(t('all_offers.cancel_success_text'))
                            setArrChecked([])
                            setError(false)
                            setLoading(false)
                            setFinishSuccess(true)
                            resetArrOffersMade()
                    },(error) => {
                        console.log(error)
                        setMsg(error.message)
                        setArrChecked([])
                        setError(true)
                        setLoading(false)
                    })
                   
                },(error) => {
                    console.log(error)
                    setMsg(error.message)
                    setMsg(error)
                    setError(true)
                    setLoading(false)
                });
            }

        }
    
    }


    return (
        <Modal
        open={openModalOffersMade}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
        }}
    >
        <Box sx={{
            width: 800,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: '2px solid #E5E5E5',
            borderRadius:'8px',
            boxShadow: 24,
            padding:'20px',
            p: 4,
            '@media screen and (max-width: 1100px)':{
                width:'100%',
            },
            '@media screen and (max-width: 700px)':{
                width:'100%',
                height:'100vh'
            },
            overflowY:'auto'
        }}
        >
            <Box 
                sx={{
                    width:'100%',
                    position:'relative',
                    marginBottom:'25px',
                    '@media screen and (max-width: 750px)': {
                        marginBottom:'5px'
                    },
                }}
            >
                <Box sx={{

                    '@media screen and (max-width: 360px)': {
                        fontSize: '12px',
                        marginBottom:'10px'
                    },
                    
                }} > 
                    <center>
                        <h2>{t("profile.modal_cancel.title_modal_text")}</h2>    
                    </center>
                </Box>

                <Box  sx={{
                    position:'absolute',
                    top:'0',
                    right:'-4px'
                }}>
                    <Button 
                        variant="contained" 
                        size="small"
                        onClick={()=>{handleCloseModal()}}
                        sx={{
                            width:'33px',
                            background:"gray",
                            borderRadius:"20px 20px 20px 20px",
                            float:'center',
                            "&:hover":{
                            background:"black"
                            }
                            // '@media screen and (max-width: 360px)': {
                            //     maxWidth:'33px',
                            //     minWidth:'33px',
                            // }
                        }}
                    >
                        <CloseIcon />
                    </Button>
                </Box>
            </Box>
            
            <Box>
                
               

               
                <Box sx={{width:'100%'}}>
                    <Box sx={{width:'100%',display:'flex',justifyContent:'center',marginBottom:'20px',color:'#ed2891'}}><b>{t("profile.modal_cancel.subtitle_modal_text")}</b></Box>
                </Box>
                <Box sx={{width:'100%'}}>
                    <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}><b>{t("profile.modal_cancel.text1_modal_text")}: {arrChecked.filter((val) => val.check).length }</b></Box>
                </Box>
                <Box sx={{width:'100%'}}>
                    <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}><b> Total : {arrChecked.filter((val) => val.check).length * 0.5} USD </b></Box> 
                </Box>

                <center>
                {
                msg !== '' &&
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',padding:'10px 0px'}}>
                    <Alert severity={error ? "error":"success"} >
                        {msg}
                    </Alert>
                </Box>
                }
                </center>

                <center>
                    {
                        loading &&  <LoaderCircle text={t("message_loader.transaction")}/>
                    }
                </center>


           
                {
                    !finishSuccess && 
                    <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <Button  variant="contained" 
                            disabled = {loading}
                            sx={{
                                marginTop:'15px',
                                marginBottom:'15px',
                                backgroundColor: '#ed2891',
                                '&:hover': {
                                    backgroundColor: '#F344A1',
                                }
                            }} 
                            onClick={()=>{setLoading(true);handleCancelOffersMade()}}
                        >
                            {t("profile.modal_cancel.accept_btn")}
                        </Button>
                    </Box>
                }
                

                <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <small>{t("profile.modal_cancel.text2_modal_text")}</small>
                </Box>
            </Box>
          
        </Box>
    </Modal> 
    )
};


ModalCancelOffersMade .propTypes = {
    openModalOffersMade: PropTypes.bool.isRequired,
    arrChecked: PropTypes.array,
    setArrChecked: PropTypes.func,
    handleCloseModalOffersMade: PropTypes.func,
    data: PropTypes.object,
    setMovements: PropTypes.func,
    movements: PropTypes.array,
    setArrOffers:PropTypes.func
};

export default ModalCancelOffersMade ;
