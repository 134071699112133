import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const OwnerPrice = () => {
    const { t } = useTranslation("translate");
    return (
        <Box 
            display='flex'
            flexDirection='row'
            sx={{
                marginTop:'2rem',
                color:'#666',
                fontWeight: 600,
                '@media screen and (max-width: 768px)': {
                    flexDirection:'column',
                }
            }}
        >
            <Box>
                {t('home.current_bid')}
                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom 
                    sx={{
                        fontSize:'36px',
                        color:'#000',
                        fontWeight: 600,
                        marginTop: '-20px',
                    }}
                >
                    1.50 AVAX
                </Typography>
                <Typography 
                    variant="overline" 
                    display="block" 
                    gutterBottom 
                    sx={{
                        fontSize:'16px',
                        color:'#666',
                        fontWeight: 600,
                        marginTop: '-30px',
                    }}
                >
                    $2,226.98
                </Typography>
            </Box>
            <Divider
                orientation="vertical"
                flexItem
                sx={{
                    margin: '0px 10px',
                    '@media screen and (max-width: 768px)': {
                        display: 'none',
                    }
                }}
            />
            <Box>
                {t('home.auction_ends')}
                <Box
                    display='flex'
                    sx={{
                        gap:'30px',
                    }}
                >
                    <Box>
                        <Typography 
                            variant="overline" 
                            display="block" 
                            gutterBottom 
                            sx={{
                                fontSize:'36px',
                                color:'#000',
                                fontWeight: 600,
                                marginTop: '-20px',
                            }}
                        >
                            5
                        </Typography>
                        <Typography 
                            variant="overline" 
                            display="block" 
                            gutterBottom 
                            sx={{
                                fontSize:'16px',
                                color:'#666',
                                fontWeight: 600,
                                marginTop: '-30px',
                            }}
                        >
                            hours
                        </Typography>
                    </Box>
                    <Box>
                        <Typography 
                            variant="overline" 
                            display="block" 
                            gutterBottom 
                            sx={{
                                fontSize:'36px',
                                color:'#000',
                                fontWeight: 600,
                                marginTop: '-20px',
                            }}
                        >
                            20
                        </Typography>
                        <Typography 
                            variant="overline" 
                            display="block" 
                            gutterBottom 
                            sx={{
                                fontSize:'16px',
                                color:'#666',
                                fontWeight: 600,
                                marginTop: '-30px',
                            }}
                        >
                            minutes
                        </Typography>
                    </Box>
                    <Box>
                        <Typography 
                            variant="overline" 
                            display="block" 
                            gutterBottom 
                            sx={{
                                fontSize:'36px',
                                color:'#000',
                                fontWeight: 600,
                                marginTop: '-20px',
                            }}
                        >
                            50
                        </Typography>
                        <Typography 
                            variant="overline" 
                            display="block" 
                            gutterBottom 
                            sx={{
                                fontSize:'16px',
                                color:'#666',
                                fontWeight: 600,
                                marginTop: '-30px',
                            }}
                        >
                            seconds
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default OwnerPrice