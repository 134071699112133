import React, { lazy, Suspense } from 'react'
import { Box, Container, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import PropTypes from 'prop-types'

const NFTsCard = lazy(() => import('components/NFTsCard/NFTsCard'));
const CollectionCard = lazy(() => import('components/CollectionCard'));



const NavLink = styled(Link)`
    text-decoration: none;
    color: #666;
    font-size: 18px;
`

const HomeSectionContent = ({title, searchBlockName, query, nft, collection,profile, showBtnAll, content, limit}) => {
    console.log(profile)
    console.log(limit)
    return (
        <>
        <Container 
            maxWidth='xl' 
            sx={{
                marginTop:'4rem',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display:'grid',
                    gridTemplateColumns: '50% 50%'
                }}
            >   
                <Box
                    sx={{
                        width: '100%',
                        display:'flex',
                        justifyContent:'flex-start',
                        fontSize:'24px', 
                        color:'#000'
                    }}
                >
                    <Box
                        style={{
                            marginLeft:'1rem',
                            letterSpacing:'-0.02em',
                            fontWeight: 600,
                            fontFamily: '"Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        }}
                    >
                        {title}
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        display:'flex',
                        justifyContent:'flex-end',
                        textAlign:'right'
                    }}
                >   
                   <Box
                        sx={{
                            marginRight:'1rem',
                            fontSize:'18px', 
                            color:'#666',
                            cursor:'pointer',
                            display:'flex',
                            alignItems:'center'
                        }}
                    >
                        <NavLink to={`/explore?${query}`}>{searchBlockName}</NavLink>
                    </Box>

                </Box>

            </Box>           
            <Divider sx={{marginTop:'0.8rem'}} />
            <Suspense fallback={<div>Loading...</div>}>
                { nft && <NFTsCard showBtnAll={showBtnAll} content={content} limit={limit} />}
                { collection && <CollectionCard showBtnAll={showBtnAll} content={content} limit={limit} />}
            </Suspense>
        </Container>
        </>
    )
}

HomeSectionContent.propTypes = {
    title: PropTypes.string,
    searchBlockName: PropTypes.string,
    query: PropTypes.string,
    nft: PropTypes.bool,
    collection: PropTypes.bool,
    profile: PropTypes.bool,
    showBtnAll: PropTypes.bool,
    content: PropTypes.array,
    limit: PropTypes.number,
}

export default HomeSectionContent