import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WalletSection from '../WalletSection';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import { FaUser } from 'react-icons/fa';
import { Context } from 'hooks/WalletContext';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

let drawerWidth = 25;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit; 
  /* color: #fff;
  font-weight: 500;
  font-size: 18px;
  border: none;
  &:hover {
    color: #FAB9DB;
  } */
`;

export default function PersistentDrawerRight({items, pending, notifications,dashboardAddress}) {
  const {data} = React.useContext(Context)
  const theme = useTheme();
  const {openWallet, setOpenWallet, openNav, setOpenNav} = React.useContext(DrawerMobileContext);
  const {push}=useNavigate()
  const handleDrawerOpen = () => {
    drawerWidth = window.screen.width
    setOpenNav(true);
  };

  const handleDrawerClose = () => {
    setOpenNav(false);
    drawerWidth = 25
  };

  const handleOpenWallet = () => {
    setOpenWallet(!openWallet);
  }

  const handleClickProfile = () => {
    push(`/profile?address=${data.userAccount}`)
    handleDrawerClose()
  }

  return (
    <Box 
      sx={{
        display: 'flex',
        '@media (min-width: 1050px)': {
          display: 'none',
        }
      }}
    >
      <CssBaseline />
      <IconButton
        //style={{color: '#fff'}}
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{
          display: openNav && 'none',
          color: '#fff',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100%',
            marginTop: theme.spacing(0),
          },
        }}
        variant="persistent"
        anchor="right"
        open={openNav}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {items.map((text, index) => ( text.visible &&
            <ListItem button key={index} onClick={handleDrawerClose}>
             <NavLink to={text.path} sx={{width:'100%'}}>
               <Box sx={{display:'grid',width:'100%',gridTemplateColumns:'minmax(30px,30px) 1fr'}}>
                 <Box sx={{display:'flex',alignItems:'center'}}>
                   <text.icon />
                 </Box>
                 <Box>
                   <ListItemText primary={text.name} />
                 </Box>
               </Box>
             </NavLink>
           </ListItem>
          ))}
          {(data && data.userAccount != undefined) && (
            <ListItem button onClick={handleClickProfile}>
              <NotificationsNoneIcon />
              <ListItemText primary={'Notifications '+ ( notifications > 0? notifications : '')} />
            </ListItem>)}
          {(data && data.userAccount != undefined) && (
            <ListItem button onClick={handleClickProfile}>
              <FaUser style={{marginRight:'2px'}} size={25} />
              <ListItemText primary='Profile' />
            </ListItem>)}

          {
            data && data.userAccount != undefined && dashboardAddress && dashboardAddress.length > 0 && dashboardAddress.includes(data.userAccount) && 
            <ListItem button onClick={handleDrawerClose} >
              <NavLink to='/create' sx={{width:'100%'}}>
                <Box sx={{display:'grid', width:'100%', gridTemplateColumns:'minmax(30px,30px) 1fr'}}>
                  <Box sx={{display:'flex', alignItems:'center'}}>
                    <NoteAddIcon />
                  </Box>
                  <Box>
                    <ListItemText primary='Create' />
                  </Box>
                </Box>
              </NavLink>
            </ListItem>
          }
          
          <Divider />
          { !pending &&
          <ListItem button onClick={handleOpenWallet}>
            <ListItemIcon>
              <AccountBalanceWalletIcon htmlColor='#1B2635' />
            </ListItemIcon>
            <ListItemText primary='My wallet' />
          </ListItem>
          }
          { pending &&
          <ListItem button onClick={handleOpenWallet}>
            <ListItemIcon>
              <CircularProgress                      
                size={'1.5rem'}
                sx={{
                  color: '#ED2891',
                }}    
              />
            </ListItemIcon>
            <ListItemText primary='Pending' />
          </ListItem>
          }
          <WalletSection openWallet={openWallet} setOpenWallet={setOpenWallet} />
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

/* NavItem.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired
  }))
} */
PersistentDrawerRight.propTypes = {
  items: PropTypes.array,
  pending : PropTypes.bool,
  notifications : PropTypes.number,
  dashboardAddress : PropTypes.array
}
