import { FormControl } from '@mui/material';
import { styled } from '@mui/system';
import React  from 'react'
import {useTranslation} from 'react-i18next';
import cardName from 'utils/cardName';
import Proptypes from 'prop-types';

const CustomSelect = styled('select')(() => ({
    border: '1px solid #ed2891',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '8px',
    width: '140px',
    '@media screen and (max-width: 500px)': {
        //width: '100%',
        width: '100%',
    }
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

  const CustomOption = styled('option')(() => ({
    //backgroundColor: '#FAB9DB',
    fontSize: '16px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

const DropDownCard = ({ handleFetchCard }) => {
    const {t} = useTranslation("translate");
    const refCard = React.useRef(null) 
    const handleChaneCard = () => {
        let value = ""+refCard.current.value
        if (value.toUpperCase().includes('YANGA')) {
            value = 'N/A'
        }
        console.log('Value artist :', value)
        handleFetchCard(value);
    }
    return (
        <FormControl
            variant="outlined" 
            sx={{ 
                minWidth: 150,
                //border:'1px solid#ed2891',
                borderRadius: '8px',
                outline: 'none',
                '&:hover': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                  },
                '@media screen and (max-width: 500px)': {
                    width: '100%',
                    marginBottom: '10px',
                }
            }}
        >
            <CustomSelect ref={refCard} onChange={handleChaneCard}>
                <CustomOption value=''>{t("search.filter_card")}</CustomOption>
                <CustomOption value=''>{t("search.all_cards")}</CustomOption>
                {cardName.map((item) => (
                    <CustomOption key={item.id} value={item.name}>{item.name}</CustomOption>
                ))}
            </CustomSelect>
        </FormControl>
    );
}

DropDownCard.propTypes = {
    setFilteredCards: Proptypes.func.isRequired,
    fetchFilter: Proptypes.func.isRequired,
    setFilterCard: Proptypes.func.isRequired,
    handleFetchCard: Proptypes.func.isRequired,

}

export default DropDownCard;