import React from 'react'
import { Box } from '@mui/material'
import NFTsCard from 'components/NFTsCard';
import PropTypes from 'prop-types'

const SectionNFTs = ({content}) => {
    return (
        <Box 
            sx={{
                marginBottom:'2rem'
            }}>
            {content && <NFTsCard limit={content && content.length} content={content && content} />}
        </Box>
    )
}

SectionNFTs.propTypes = {
    content: PropTypes.array,
}

export default SectionNFTs