import { useEffect, useState } from 'react'; 
import axios from 'axios';

export const useFetch = (url) => {
    console.log("debug url", url);
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(()=>{
        const fetchData = async ()=>{
            try{
                setLoading(true);
                setData(null);
                const response = await axios.get(url, {
                    headers: {
                        'X-Api-key': process.env.REACT_APP_X_API_KEY
                    }
                });
                const { data, status } = response;
                setStatus(status);
                console.log(data)
                setData(data);
                setLoading(false);
            }catch(error){
                setError(error);
                setLoading(false);
            }
        }
        fetchData();
    },[url])

    return {data, status, loading, error};
}

