import React, { Suspense } from 'react'
import { Box, CardMedia, Container } from '@mui/material'
import ErrorBoundary from 'components/ErrorBoundary';
import {fetchAboutData} from './getAboutData'
import { isEnglish } from 'views/cryptoloterita/determinateLanguage';
import BlockContent from '@sanity/block-content-to-react'
import { useTranslation } from 'react-i18next';
import LoaderCircle from 'components/LoaderCircle';
import ServerError from 'components/ServerError';
import PropTypes from 'prop-types'

const resourceAbout = fetchAboutData();





const BlockContentAbout = ({data}) => {
    const about = data.about.read()[0];


    return (
        <React.Fragment>

            {
                [about].length > 0 ?

                <>
                   {
                        isEnglish() ? 
                        <React.Fragment>
                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',textAlign:'left'}}>
                                <BlockContent blocks={ about.englishInstruction} projectId="klqezt1e"/>  
                            </Box>
                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',textAlign:'left'}}>
                                <BlockContent blocks={ about.englishQuestion_1_2 } projectId="klqezt1e"/>
                            </Box>

                            <Box 
                                sx={{
                                    width:'100%',
                                    display: about &&  about.englishImage1 &&   about.englishImage1.asset && about.englishImage1.asset.url ? 'flex':'none',
                                    justifyContent:'center',
                                    textAlign:'left'
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:'auto',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}
                                >
                                    <CardMedia 
                                        component='img'
                                        src={about.englishImage1.asset.url}
                                        sx={{
                                            width:'50%',
                                            "@media (max-width: 900px)": {
                                                width:'60%',
                                            },
                                            "@media (max-width: 580px)": {
                                                width:'65%',
                                            },
                                            "@media (max-width: 500px)": {
                                                width:'75%',
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> 
                           
                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',textAlign:'left'}}>
                                <BlockContent blocks={about.englishQuestion_3 } projectId="klqezt1e"/>    
                            </Box>

                            <Box 
                                sx={{
                                    width:'100%',
                                    display: about &&  about.englishImage2 &&   about.englishImage2.asset && about.englishImage2.asset.url ? 'flex':'none',
                                    justifyContent:'center',
                                    textAlign:'left'
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:'auto',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}
                                >
                                    <CardMedia 
                                        component='img'
                                        src={about.englishImage2.asset.url}
                                        sx={{
                                            width:'25%',
                                            "@media (max-width: 900px)": {
                                                width:'35%',
                                            },
                                            "@media (max-width: 580px)": {
                                                width:'45%',
                                            },
                                            "@media (max-width: 500px)": {
                                                width:'45%',
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> 
                            
                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',textAlign:'left'}}>
                                <BlockContent blocks={ about.englishQuestion_4 } projectId="klqezt1e"/>    
                            </Box>

                            
                            
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',textAlign:'left'}}>
                                <BlockContent blocks={ about.spanishInstruction} projectId="klqezt1e"/>  
                            </Box>

                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',textAlign:'left'}}>
                                <BlockContent blocks={ about.spanishQuestion_1_2 } projectId="klqezt1e"/>     
                            </Box>

                            <Box 
                                sx={{
                                    width:'100%',
                                    display: about &&  about.spanishImage1 &&   about.spanishImage1.asset && about.spanishImage1.asset.url ? 'flex':'none',
                                    justifyContent:'center',
                                    textAlign:'left'
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:'auto',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}
                                >
                                    <CardMedia 
                                        component='img'
                                        src={about.spanishImage1.asset.url}
                                        sx={{
                                            width:'50%',
                                            "@media (max-width: 900px)": {
                                                width:'60%',
                                            },
                                            "@media (max-width: 580px)": {
                                                width:'65%',
                                            },
                                            "@media (max-width: 500px)": {
                                                width:'75%',
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> 

                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start',textAlign:'left'}}>
                                <BlockContent blocks={ about.spanishQuestion_3 } projectId="klqezt1e"/>  
                            </Box>

                            <Box 
                                sx={{
                                    width:'100%',
                                    display: about &&  about.spanishImage2 &&   about.spanishImage2.asset && about.spanishImage2.asset.url ? 'flex':'none',
                                    justifyContent:'center',
                                    textAlign:'left'
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:'auto',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}
                                >
                                    <CardMedia 
                                        component='img'
                                        src={about.spanishImage2.asset.url}
                                        sx={{
                                            width:'25%',
                                            "@media (max-width: 900px)": {
                                                width:'35%',
                                            },
                                            "@media (max-width: 580px)": {
                                                width:'45%',
                                            },
                                            "@media (max-width: 500px)": {
                                                width:'45%',
                                            }
                                        }}
                                    />
                                </Box>
                            </Box> 

                            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-start'}}>
                                <BlockContent 
                                    
                                    blocks={about.spanishQuestion_4 }
                                    projectId="klqezt1e"/>     
                            </Box>

                        </React.Fragment>
                   }
                   
                </>
                :
                <ServerError />
               

            }
            

           
        </React.Fragment>
    )
}

const About = () => {
    const {t} = useTranslation("translate");
    return (
        <React.Fragment>
            <center>
                <Box 
                    component='h1'
                    sx={{
                        color:'#000', 
                        fontSize:'1.9vw',
                        textAlign:'center',
                        margintop:'-14px',
                        '@media screen and (max-width: 600px)': {
                            fontSize:'7vw',
                            margintop:'0px',
                        }
                    }}
                > 
                    {t("about.about")}
                </Box>
            </center>
            <Container maxWidth='md'>
                {
                    resourceAbout && 
                    <ErrorBoundary fallback={<ServerError />}>
                        <Suspense fallback={<LoaderCircle text='loading' />}>
                        <BlockContentAbout data={resourceAbout} />
                        </Suspense>
                    </ErrorBoundary>
                }
               
            </Container>
        </React.Fragment>
       
    )
}

BlockContentAbout.propTypes = {
    data: PropTypes.object,
}

export default About