import { Avatar, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import metamaskLogo from 'assets/logos/metamask.svg'
import wcLogo from 'assets/logos/walletconnect-circle.svg'
import { Context } from 'hooks/WalletContext';
//import { Link } from 'react-router-dom';
//import { useTranslation } from 'react-i18next';
//import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import LoaderCircle from 'components/LoaderCircle';
//import PropTypes from 'prop-types';

const LogOut = () => {
  //  const { t } = useTranslation("translate");
    const { t } = useTranslation("translate");
    const {data,setIsLoading} = useContext(Context)
    const {connection}= useContext(Context)
    const [metamask, setMetamask] = useState(null)
    useEffect(() => {
        if (typeof window.ethereum !== 'undefined') {
            setMetamask(true)
        }else{
            setMetamask(false)
        }
    },[])

    useEffect(() => {
        if(JSON.parse(window.localStorage.getItem('isMetamask')) == true){
            setIsLoading(true)
            let interval = setInterval(() => {
                console.log('State interval',window.ethereum?._state.initialized)
                if( window.ethereum._state?.initialized) {
                    try {
                        setTimeout(() => {
                            connection('metamask')
                            if(window.localStorage.getItem('current_wallet')) {
                                clearInterval(interval)
                                setIsLoading(false)
                            }
                        }, 1000);
                    } catch (error) {
                            console.log('error logout', error)
                    }
                } else {
                    window.location.reload()
                }
            },3000)
        }
    },[data])

    const handleConnectionMetamask = () => {
        setIsLoading(true)
        if(metamask){
            try {
                if(!window.ethereum?._state.initialized) {
                    setTimeout(() => {
                        alert('Metamask no esta listo para conectarse, presiona ok para actualizar la pagina');
                        window.location.reload()
                    })
                } else {
                    connection('metamask')
                }
            } catch (error) {
                let interval = setInterval(() => {
                    console.log('State interval',window.ethereum?._state.initialized)
                    if( window.ethereum?._state.initialized) {
                        try {
                            setTimeout(() => {
                                connection('metamask')
                                if(window.localStorage.getItem('current_wallet')) {
                                    clearInterval(interval)
                                    setIsLoading(false)
                                }
                            }, 1000);
                        } catch (error) {
                                console.log('error logout', error)
                        }
                    } else {
                        window.location.reload()
                    }
                },1000)
            }
        }else{
            alert('Please install Metamask')
        }
        /* setTimeout(() => {
            setIsLoading(false)
        } , 8000)
        clearTimeout() */
    }
    const handleConnectionWalletconnect = ()=>{
        connection('walletConnect')
    }

    /* if(data && data.userAccount === undefined){
        return <LoaderCircle text={t("message_loader.loading_account")} />
    } */

    return (
        <List>
            <ListItemButton
                onClick={handleConnectionMetamask}
            >
                <ListItemIcon>
                    <Avatar alt="metamask" src={metamaskLogo} />
                </ListItemIcon>
                <ListItemText primary={metamask ? 'Metamask' : 'Install Metamask'} />

            </ListItemButton>

            {!metamask ?
                <ListItemButton >
                    <ListItemText>
                        <center>
                            {t("topbar.install_metamask.how")} <a href='https://metamask.io/' target='_blank'  rel="noreferrer">{t("topbar.install_metamask.here")}</a>
                        </center>
                    </ListItemText>
                </ListItemButton>
                : null
            }


            <Divider />
            <ListItemButton
                onClick={handleConnectionWalletconnect}
            >
                <ListItemIcon>
                    <Avatar alt="walletconnect" src={wcLogo} />
                </ListItemIcon>
                <ListItemText primary='WalletConnect' />
            </ListItemButton>
            <Divider />
        </List>
    );
}


export default LogOut;
