import React,{Suspense} from 'react';
import { Box, Container } from '@mui/material'
import ErrorBoundary from 'components/ErrorBoundary';
import {getBenefitsData} from './getBenefitsData'
import { isEnglish } from 'views/cryptoloterita/determinateLanguage';
import BlockContent from '@sanity/block-content-to-react'
import LoaderCircle from 'components/LoaderCircle';
import ServerError from 'components/ServerError';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

const resourceBenefits = getBenefitsData();

const BlockContentBenefits = ({data}) => {
    const benefits = data.benefits.read()[0];
    return (
        <React.Fragment>
            {
                benefits && benefits.english ||  benefits && benefits.spanish ?
                <Box sx={{width:'100%',display:'flex',justifyContent:'center',textAlign:'left'}}>
                    <BlockContent blocks={ isEnglish() ? benefits.english: benefits.spanish } projectId="klqezt1e"/>    
                </Box>
                :
                <ServerError />

            }
           
        </React.Fragment>
    )
}

const Benefits = () =>{
    const {t} = useTranslation("translate");
    return (
        <React.Fragment>
            <center>
                <Box 
                    component='h1'
                    sx={{
                        color:'#000', 
                        fontSize:'1.9vw',
                        textAlign:'center',
                        margintop:'-14px',
                        '@media screen and (max-width: 600px)': {
                            fontSize:'7vw',
                            margintop:'0px',
                        }
                    }}
                > 
                    {t("topbar.benefits")}
                </Box>
            </center>
            <Container maxWidth='md'>
                {
                    resourceBenefits && 
                    <ErrorBoundary fallback={<ServerError />}>
                        <Suspense fallback={<LoaderCircle text='loading' />}>
                            <BlockContentBenefits data={resourceBenefits} />
                        </Suspense>
                    </ErrorBoundary>
                }
               
            </Container>
        </React.Fragment>
       
    )
 
}

BlockContentBenefits.propTypes = {
    data: PropTypes.object,
}

export default Benefits;