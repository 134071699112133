import React from 'react';
import LoteryMain from 'layouts/LoteryMain/LoteryMain';
import { BrowserRouter, Route , Routes as RoutesMain, Navigate } from "react-router-dom";
import Create from 'views/Create';
import Home from 'views/Home';
import About from 'views/About';
import FAQS from 'views/FAQS';
import Benefits from 'views/Benefits';
import ModalChangeNetwork from 'components/ModalChangeNetwork';
import ModalWelcome from 'components/ModalWelcome';

import Explore  from 'views/Explore';
import Collection  from 'views/Collection';
import Profile  from 'views/cryptoloterita/Profile';
import CreateCollection from 'views/CreateCollection';
import NFT  from 'views/cryptoloterita/NFT/NFT';
import MintNFT  from 'views/MintNFT';
import SelectCollection  from 'views/SelectCollection';
import AdminDashboard  from 'views/AdminDashboard';

// const Explore = lazy(() => import(/* webpack chunckName: gallery */'views/Explore'));
// const Collection = lazy(() => import(/* webpack chunckName: collection */'views/Collection'));
// const Profile = lazy(() => import(/* webpack chunckName: profile */'views/cryptoloterita/Profile'));
// const CreateCollection =  lazy(() => import(/* webpack chunckName: create-collection */'views/CreateCollection'));
// const NFT = lazy(() => import(/* webpack chunckName: nft */'views/cryptoloterita/NFT/NFT'));
// const MintNFT = lazy(() => import(/* webpack chunckName: mint-nft */'views/MintNFT'));
// const SelectCollection = lazy(() => import(/* webpack chunckName: select-collection */'views/SelectCollection'));
// const AdminDashboard = lazy(() => import(/* webpack chunckName: mint-nft */'views/AdminDashboard'));


const Routes = () => {
    return (
        <BrowserRouter>
                <ModalWelcome />
                <LoteryMain>
                    <ModalChangeNetwork/>
                    <RoutesMain>
                        <Route path='/' element={<Home />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/explore/*' element={<Explore/>} />
                        <Route path='/collection' element={<Collection/>} />
                        <Route path='/nft' element={<NFT/>} />
                        <Route path='/profile' element={<Profile/>} />
                        <Route path='/faqs' element={<FAQS/>} />
                        <Route path='/about' element={<About/>} />
                        <Route path='/benefits' element={<Benefits/>} />
                        <Route path='/admin' element={<AdminDashboard/>} />
                        <Route path='/create/*' element={<Create/>} >
                            <Route path='create-collection' element={<CreateCollection/>} />
                            <Route path='select-collection' element={<SelectCollection/>} />
                            <Route path='mint-nft' element={<MintNFT/>} />
                        </Route>
                        <Route path='*' element={<Navigate to="/" replace />} />
                        <Route path='*' element={<div>Not found 404</div>} />
                    </RoutesMain>
                </LoteryMain>
            </BrowserRouter>
  );
};

export default Routes;
