import sanityClient from 'sanityClient';

const fetchData = ()=>{
    return sanityClient.fetch(`*[_type == "about"] | order(_createdAt desc) {
        englishInstruction,
        englishQuestion_1_2,
        englishImage1{
            asset->{
                _id,
                url
            } ,
            alt
        },
        englishQuestion_3,
        englishImage2{
            asset->{
                _id,
                url
            } ,
            alt
        },
        englishQuestion_4,
        spanishInstruction,
        spanishQuestion_1_2,
        spanishImage1{
            asset->{
                _id,
                url
            } ,
            alt
        },
        spanishQuestion_3,
        spanishImage2{
            asset->{
                _id,
                url
            } ,
            alt
        },
        spanishQuestion_4

    }`).then((data) => data)
}

export const fetchAboutData =()=>{
    const aboutPromise = fetchData();
    return{
        about: WrapPromise(aboutPromise),
    }
}

const WrapPromise = promise =>{
    let status = 'pending';
    let result;
    let suspender = promise.then(
        res=>{
            status = 'success';
            result = res;
        },
        err=>{
            status = 'error';
            result = err;
        }
    )
    return {
        read(){
            if(status === 'pending'){
                throw suspender;
            }else if(status === 'success'){
                return result;
            }else if(status === 'error'){
                throw result;
            }
        }
    }
}