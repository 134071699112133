import React from 'react';
import { Container, Grid, Skeleton,Box} from '@mui/material';

const DetailsLoader = () => {

    return (
        <Container maxWidth="xl">
            <Grid container columns={{ xs: 12, sm: 12, md:12, lg:12 }}>
                {
                    [0,1].map((val)=>{
                        return(
                            <Grid key={val} item xs={12} sm={12} md={12} lg={12} 
                                sx={{
                                    height:150
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        height:'100%',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center'
                                    }}
                                >
                                    <Skeleton 
                                        sx={{
                                            width:"98%", 
                                            height:200,
                                            borderRadius:'8px'
                                        }}
                                    /> 
                                </Box>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Container>
    );

}

export default DetailsLoader;