import React, { useState } from 'react'
import { Box, Divider } from '@mui/material'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const ActiveTag = styled(Box)`
    border-bottom: ${props => props.active ? '3px solid #000' : 'null'};
    font-weight: ${props => props.active ? 'bold' : 'null'};
    cursor: pointer;
    //background-color: ${props => props.active ? '#F344A1' : '#000'};
    ${props => props.styles}
`

const initialState = [
    {
        name: 'NFTs',
        active: true,
        number: 1,
    },
    {
        name: 'Description',
        active: false,
        number: 2,
    },
    {
        name: 'Activity',
        active: false,
        number: 3,
    }
]

const options = [
    {
        name: 'Date Minted - Newest',
        value: 'newest'
    },
    {
        name: 'Date Minted - Oldest',
        value: 'oldest'
    },
    {
        name: 'Price - Highest',
        value: 'highest'
    },
    {
        name: 'Price - Lowest',
        value: 'lowest'
    }
]


const TabSelector = ({items, setUpdate, showSelector, options}) => {
    const [tabs, setTabs] = useState(items)
    const handleClickActiveTab = (index) => {
        const newTabs = [...tabs]
        newTabs.forEach((tab, i) => {
            if(i === index){
                setUpdate(tab.number)
                setTabs([...tabs.map((tab, i) => {
                    if(i === index){
                        return {...tab, active: true}
                    }
                    return {...tab, active: false}
                })])
            }
        })
    }
    return (
        <>
            <Box
                sx={{
                    width:'100%'
                }}
            >
                <Box display='flex' justifyContent='space-between' 
                    sx={{
                        width: '100%',

                        "@media (max-width: 768px)": {
                            display:'grid',
                            width:'100%',
                            gridTemplateColumns:'1fr',
                        }
                    }}
                >
                    
                    <Box display='flex' justifyContent='flex-start' 
                        sx={{
                            gap:'1rem',
                            marginLeft:'1.5rem',
                            "@media (max-width: 768px)": {
                                width:'100%',
                                justifyContent:'flex-start'
                            }
                        }}>
                        {tabs.map((item, index) => 
                        <ActiveTag key={index} onClick={()=>handleClickActiveTab(index)} active={item.active+''}>{item.name}</ActiveTag>)}
                    </Box>
                    
                    
                </Box>
                <Divider />
                {
                        showSelector && 
                        <Box
                            sx={{
                                width:'100%',
                                display:'flex',
                                justifyContent:'flex-end'
                            }}
                        >
                            <select>
                                {options.map((item, index) =>
                                    <option key={index} value={item.value}>{item.name}</option>)}
                            </select>
                        </Box>
                    }

            </Box>
        </>
    )
}

TabSelector.defaultProps = {
    items: initialState,
    showSelector: true,
    options: options,
    setUpdate: () => {}
}

TabSelector.propTypes = {
    items: PropTypes.array,
    setUpdate: PropTypes.func,
    options: PropTypes.array,
    showSelector: PropTypes.bool
}

export default TabSelector

