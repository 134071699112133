import React from 'react'
import { Box, Container } from '@mui/material'
import PropTypes from 'prop-types'

const Description = ({content}) => {
    return (
        <Box 
        display='flex' 
        justifyContent='center' 
        alignItems='center' 
        sx={{marginTop:'2rem',fontSize: '36px',
        letterSpacing: '-0.02em'}}
        >
            <Container maxWidth='lg'>
                {content}
            </Container>
        </Box>
    )
}

Description.propTypes = {
    content: PropTypes.string,
}

export default Description