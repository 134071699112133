import { FormControl } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { tokenIdsOfUser } from 'views/Shop/BuySection/ERC1155/idsOfUser';
import PropTypes from 'prop-types';
//import artist from './services/artist'
//import Proptypes from 'prop-types';

const CustomSelect = styled('select')(() => ({
    border: '1px solid #ed2891',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '8px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

  const CustomOption = styled('option')(() => ({
    //backgroundColor: '#FAB9DB',
    fontSize: '16px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

const DropDown = ({address, setCards, setLoading, handleRandom}) => {
    const { t } = useTranslation("translate");
    const allRef = React.useRef(null);
    const handleChange = () => {
        setLoading(true);
        setCards([]);
        const filters = allRef.current.value; 
        console.log('DropDown :: project')
        if(filters === 'custom'){
            axios.get(`${process.env.REACT_APP_URL_API}/project?address=${process.env.REACT_APP_COLLECTION_ADDRESS}`).then(res=>{
                console.log(res)
                tokenIdsOfUser(address,1000,(tokensId) => {
                    console.log( tokensId)
                    let nfts = []
                    for (let index = 0; index < tokensId.length; index++) {
                        //const element = array[index];
                        let token_id = parseInt(tokensId[index])
                        if(token_id > 0) {
                            let nft = res.data[0].nfts.find(x => x.token_id == token_id);
                            if(nft) {
                                nfts.push(nft)
                            }
                        }
                    }
                    console.log('my nfts ::',nfts)
                    setCards(nfts)
                    setLoading(false);
                })
            })
        }
        if(filters === 'all'){
            handleRandom()
        }
    }
    return (
        <FormControl
            variant="outlined" 
            sx={{ 
                minWidth: 150,
                //border:'1px solid#ed2891',
                borderRadius: '8px',
                outline: 'none',
                '&:hover': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                  },
                '@media screen and (max-width: 500px)': {
                    width: '100%',
                    marginBottom: '10px',
                }
            }}
        >
            <CustomSelect ref={allRef} onChange={handleChange}>
                <CustomOption value='all'>{t("search.all_cards")}</CustomOption>
                <CustomOption value='custom'>{t("search.my_cards")}</CustomOption>
            </CustomSelect>
        </FormControl>
    );
}

DropDown.propTypes = {
    setCards: PropTypes.func.isRequired,
    address: PropTypes.string,
    setLoading: PropTypes.func.isRequired,
    handleRandom: PropTypes.func.isRequired
}

export default DropDown;