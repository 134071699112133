import React from 'react'
import { Avatar, Box, Card, Grid, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom'
import  { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const OwnerInformation = ({content}) => {
    const { t } = useTranslation("translate");
    return (
        <React.Fragment>
            <Grid container columns={{xs:12,sm:12, md:12, lg:12, xl:12}} 
                sx={{
                    width: '100%'
                }}
                justifyContent='center'
            >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} 
                    sx={{
                        width: '100%',
                        borderRight: '1px solid #e0d0d0',
                        '@media screen and (max-width: 599px)':{
                            borderRight: 'none'
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box> {t('home.owner')}</Box>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Card
                            component={Link} 
                            to={`/profile?address=${content.owner}`}
                            sx={{
                                marginTop: '1rem',
                                display: 'flex',
                                gap:'5px',
                                boxSizing: 'border-box',
                                padding: '5px 15px',
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                width: 'auto'
                            }}
                        >
                            <Avatar alt="CryptoArt" src={content.user && content.user.profile_pic_url} />
                            <Typography 
                                variant="overline" 
                                display="block" 
                                gutterBottom 
                                sx={{
                                    fontSize:'16px',
                                    color:'#000',
                                    fontWeight: 600,
                                    cursor:'pointer',
                                    textOverflow: 'ellipsis'
                                
                                }}
                            >
                                {content.user && content.user.short_main_key}
                            </Typography>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} 
                    sx={{
                        width: '100%',
                        borderLeft: '1px solid #e0d0d0',
                        '@media screen and (max-width: 599px)':{
                            borderLeft: 'none'
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Box>{t('nft-screen.collection')}</Box>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Card
                            component={Link} 
                            to={`/profile?address=${content.owner}`}
                            sx={{
                                marginTop: '1rem',
                                display: 'flex',
                                gap:'5px',
                                boxSizing: 'border-box',
                                padding: '5px 15px',
                                textDecoration: 'none',
                                textOverflow: 'ellipsis',
                                width: 'auto'
                            }}
                        >
                            <Avatar variant="rounded">
                                <AccountCircleIcon />
                            </Avatar>
                            <Typography 
                                variant="overline" 
                                display="block" 
                                gutterBottom 
                                sx={{
                                    boxSizing: 'border-box',
                                    fontSize:'16px',
                                    color:'#000',
                                    fontWeight: 600,
                                    cursor:'pointer',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden'
                                }}
                            >
                                {content.project &&
                                    content.project.name
                                }
                            </Typography>
                        </Card>
                    </Box>
                </Grid>
            </Grid>


           
        </React.Fragment>
    )
}

OwnerInformation.propTypes = {
    content: PropTypes.object,
}

export default OwnerInformation