import React from 'react'
import { CircularProgress, Box, Modal } from '@mui/material';
import PropTypes from 'prop-types';

const LoaderCircle = ({text, textColor, spinnerColor}) => {
    return(
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{
                textAlign: 'center',
                marginTop: '2rem',
                color: textColor ? textColor : '#000',
            }}
        >
            <CircularProgress
                sx={{
                    color: spinnerColor ? spinnerColor : '#fff',
                }}
            />
            <h3>{text}</h3>
        </Box>
    )
}

const LoaderModal = ({isOpen, setIsClosed, text, textColor, spinnerColor}) => {
    return (
        <Modal 
            open={isOpen}
            onClose={()=> setIsClosed(false)}
            sx={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
            }}
        >
            <Box>
                <LoaderCircle 
                    text={text}
                    textColor={textColor}
                    spinnerColor={spinnerColor}
                />
            </Box>
        </Modal>
    )
}

LoaderCircle.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    spinnerColor: PropTypes.string,
}

LoaderModal.propTypes = {
    text: PropTypes.string,
    textColor: PropTypes.string,
    spinnerColor: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsClosed: PropTypes.func,
}

export default LoaderModal