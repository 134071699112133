export const profiles = [
  {
    "registered": true,
    "balances": [],
    "games": [],
    "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "domain": "artcrypted.com",
    "username": "0x016b76...9014",
    "email": "",
    "about": "",
    "twitter": "",
    "homepage": "",
    "telegram": "",
    "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "theme": 1,
    "expert": false,
    "first_seen": "2022-04-08 18:45:35.178152",
    "last_seen": "2022-04-08 18:45:35.178152",
    "role": 1,
    "followers": 315
  },
  {
    "registered": true,
    "balances": [],
    "games": [],
    "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "domain": "artcrypted.com",
    "username": "0x016b76...9014",
    "email": "",
    "about": "",
    "twitter": "",
    "homepage": "",
    "telegram": "",
    "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "theme": 1,
    "expert": false,
    "first_seen": "2022-04-08 18:45:35.178152",
    "last_seen": "2022-04-08 18:45:35.178152",
    "role": 1,
    "followers": 315
  }
]