import React, { lazy, Suspense, useState } from 'react'
import { Box, Typography } from '@mui/material';
import { Context } from 'hooks/WalletContext';
import { getProjectByAddress } from 'services/Project/getProjectByAddress';
import LoaderCard from 'components/LoaderCard';
import { useTranslation } from 'react-i18next';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import ButtonStyled from 'components/ButtonStyled';
import { useNavigate } from 'react-router-dom'

const CollectionCards = lazy(() => import('./components/CollectionCards/CollectionCards'));

const SelectCollection = () => {
    const {t} = useTranslation("translate");
    const history = useNavigate();
    const {data} = React.useContext(Context)
    const [projects,setProjects] = useState([]);
    const [loadNfts,setLoadNfts] = useState(true);
    if(data && data.userAccount && projects.length == 0){
        let interval = setInterval(async() =>{
            const projects = await getProjectByAddress(data.userAccount);
            console.log('projects aa', projects)
            //const projects = []
            clearInterval(interval)
            setProjects(projects)
            setLoadNfts(false)
        },1000)
    }


    return (
        <>
            <Box 
                component='h1' 
                sx={{
                    fontSize:'30px',
                    textAlign:'center',
                }}
            >
                {t("create_page.selected_collection")}
            </Box>
            {
                loadNfts ? <LoaderCard />
                :
                projects.length > 0  ?
                    <Suspense fallback={<LoaderCard/>}>
                        <CollectionCards content={projects} showBtnAll={false} limit={50}/>
                    </Suspense>
                :
                <Box
                    sx={{width:'100%', height:'100%'}}
                >
                    
                    <Box
                        sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center'}}
                    >
                        <Typography variant='h4' sx={{color:'red'}}>
                            {t("explore.not_found")}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            width:'100%'
                        }}
                    >
                        <center>
                            <SentimentDissatisfiedIcon fontSize='large' htmlColor='red'/>
                        </center>
                    </Box>

                   
                    <Box
                        sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center',marginTop:'8%'}}
                    >
                        <ButtonStyled 
                            width={'240px'}
                            text={t("create_page.create_collection")}
                            onClick={(e) => {
                                e.preventDefault();
                                history.push('/create');
                            }}
                        />
                    </Box>
                    <Box
                        sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center'}}
                    >
                        <Typography variant='h6' sx={{color:'red',marginTop:'15px'}}>
                            {t("create_page.try_create_collection")}
                        </Typography>
                    </Box>
                    
                </Box>
            }
            
        </>
    )
}

export default SelectCollection