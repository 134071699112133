import React from 'react'
import { Card, CardMedia, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
//import { getThumbnail } from 'services/getThumbnail';

const CardHero = ({content}) => {
    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            sx={{
                width: '100%'
            }}
            
        >
            <Grid container  columns={{xs:12,sm:12, md:12, lg:12, xl:12}}               
                
                sx={{
                    width: '100%',
                    "@media screen and (max-width: 899px)": {
                        display: 'flex',
                        justifyContent: 'center'
                    }
                }}
                justifyContent="flex-end"
            >   
                <Grid item xs={11} sm={7} md={10} lg={7} xl={7}
                    sx={{
                        width: '100%',	
                        marginRight: '20px',
                        "@media screen and (max-width: 899px)": {
                            marginRight: '0px'
                        }
                    }}
                >
                    <Link to='/explore'>
                        <Card
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '400px',
                                margin: 'auto',
                                boxShadow: 'none',
                                "@media (max-width: 320px)": {
                                    height: '200px'
                                }
                            }}
                        >
                            <CardMedia
                                component={content.metadata.is_video ? "video" :"img"}
                                src={content.metadata.image_url}
                                alt="Chivas"
                                autoPlay
                                loop
                                muted
                                sx={{
                                    borderRadius: '8px',
                                }}
                            />
                        </Card>
                    </Link>
                </Grid>

            </Grid>
        </Grid>
  )
}

CardHero.propTypes = {
    content: PropTypes.object,
}

export default CardHero
