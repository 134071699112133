import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';


const UserRegisteredTab = () => {
    const [users, setUsers] = useState([])
    const columns = [
        { field: 'id', headerName: 'ID' ,width: 250 },
        { field: 'wallet', headerName: 'Wallet', width: 250 },
        { field: 'username', headerName: 'Username', width: 250 },
        { field: 'email', headerName: 'Email', width: 250 }
    ]
    useEffect(()=>{
        if(users.length == 0) {
            axios.get(process.env.REACT_APP_URL_API+`/user?domain=${process.env.REACT_APP_DOMAIN}&limit=${1000}&page=${0}&search=ALL`)
            .then(res=>{
                console.log('users ::',res.data)
                let users = []
                res.data.forEach(user => {
                    users.push({id : user.id, wallet : user.wallet,email : user.email, username : user.username})
                })
                console.log('uusers 2 ',users)
                setUsers(users)
            })
        }
    },[])
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
            rows={users}
            columns={columns}
            pageSize={ 12}
          />
        </div>
    )
}


export default UserRegisteredTab