import React from 'react'
import { Container, Grid } from '@mui/material';
import { CardHero, Information } from './components';
import { nft } from './utils/nft'
import PropTypes from 'prop-types';

const HomeHero = ({content}) => {
    
    return (
        <>
        <Container 
            maxWidth='xl' 
            sx={{
                marginTop:'4rem',
            }}
        >
            <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
            <Grid 
                container 
                columns={{xs:12,sm:12, md:12, lg:12, xl:12}}
                sx={{
                    width: '100%'
                }}
            >
                <CardHero content={content} />
                <Information content={content} />
            </Grid>
            </Container>
        </Container>

        </>
    );
}

HomeHero.defaultProps = {
    content: nft,
}

HomeHero.propTypes = {
    content: PropTypes.object,
}

export default HomeHero;
