import React from 'react'
import { Box, CardMedia, Container } from '@mui/material'
import LogoChivas from 'assets/logos/logo-chivas.svg'
import { useTranslation } from 'react-i18next'

const HomeBlockContent = () => {
    const { t }  = useTranslation("translate");
    return (
        <Box 
            component='section'
            display='flex'
            justifyContent='space-around'
            alignItems='center'
            sx={{
                marginTop: '50px',
                backgroundColor: '#1B2635',
                boxSizing: 'border-box',
                padding: '15px 0px',
                '@media screen and (max-width: 768px)': {
                    flexDirection: 'column',
                }
            }}
        >
            <Box>
                <CardMedia 
                    component='img' 
                    src={LogoChivas}
                    sx={{
                        height: '80px',
                      
                    }}
                />
            </Box>
            <Box sx={{fontSize:'25px', textAlign:'right'}}>
                <Container maxWidth='xs'>
                    <span style={{color:'#fff'}}>{t('home.oficial_site')}</span><span style={{color:'#FF0000', margin:'0 5px'}}>{t('home.oficial_site_red')}</span> <br />
                </Container>
            </Box>
        </Box>
    )
}

export default HomeBlockContent