import React,{useContext,useState,useEffect} from 'react';
import { Context } from 'hooks/WalletContext';
import { Avatar, ListItemButton, ListItemIcon, ListItemText, Modal } from '@mui/material';
import { Box } from '@mui/system';
import logoAvalanche from 'assets/logos/avalanche_logo.svg'
//import logoEthereum from 'assets/logos/eth.svg'
import { useTranslation } from 'react-i18next';
import { switchEthereumChain } from 'services/metamask';
import ModalPowerOff from 'views/cryptoloterita/StatusValidator/components/alertModalPowerOff.js';
import { validateServerStatus } from 'views/cryptoloterita/StatusValidator/validateServerStatus';
import {useLocation} from 'react-router-dom'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  };

const ModalChangeNetWork  = () =>{

    const {t} = useTranslation("translate");
    const location = useLocation();
    const {data} = useContext(Context)
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isPowerOffModal, setIsPowerOffModal] = useState(false);
    useEffect(() => {
        if(data && data.chainID !== 'NaN') {
            if(data.chainID != process.env.REACT_APP_POLYGON_NETWORK){
                setIsOpenModal(true)
            }else{
                setIsOpenModal(false)
            }
        }
        validateServerStatus().then((serverStatusPowerOff) => {
            if(!serverStatusPowerOff) {
                setIsPowerOffModal(false)
            } else {
                setIsPowerOffModal(true)
            }
        })
    },[data,location.pathname])
    useEffect(() => {
        window.scrollTo(0, 0);
    },[])
    const handleChangePolygon = () => {
        switchEthereumChain(process.env.REACT_APP_POLYGON_NETWORK)
    }

    return (
        <>
            <ModalPowerOff open={isPowerOffModal} />
            <Modal
                open={isOpenModal}
                onClose={() => setIsOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h3 style={{textAlign:'center'}}>{t("lottery_container_modal.title")}</h3>
                    <ListItemButton
                        onClick={handleChangePolygon} 
                    >
                        <Box  sx={{width: '100%',display:'flex',justifyContent:'center',textAlign:'center'}}>
                            <ListItemIcon >
                                <Avatar alt="metamask" sx={{width:'35px',height:'35px'}} src={logoAvalanche} />
                            </ListItemIcon>
                            <Box 
                                sx={{marginLeft:'-10px',
                                    display:'flex',
                                    alignItems:'center'
                                }}
                            >
                                <ListItemText primary={t("lottery_container_modal.switch_to_polygon_text")} />
                            </Box>
                        </Box>
                    </ListItemButton>
                </Box>
            </Modal>
        </>
    );
    
}

export default ModalChangeNetWork;


