import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Alert from '@mui/material/Alert';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { BsFillGrid1X2Fill } from 'react-icons/bs';
import {useTranslation} from 'react-i18next';
//import Status from 'components/Status/Status';
//import { Link } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CircularProgress } from '@mui/material';
import ModalCancelOffersMade from './ModalCancelOffersMade';
//import wmaticIcon from 'assets/logos/wmatic.png'
import { cancelAllByIds } from 'views/cryptoloterita/NFT/components/ModalOffers/components/cancelAllRequests/cancelAllByIds';
import {sign} from "views/cryptoloterita/Blockchain/signMessage";
import {cancelAllCryptoRequestsByIds} from '../../../../../../services/ExchangeCrypto/cancelAllCryptoRequestsByIds';
import RowCollapse from 'components/Tables/RowCollapse' 
import {Paper} from '@mui/material';


function EnhancedTableHead(props) {
  const {t} = useTranslation("translate");
  const { order, orderBy, onRequestSort,show } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
          <TableCell   align="center">
          </TableCell>
          <TableCell  padding='none' align="center">
              <b>{t("nft-screen.offers_modal_table.status_title_column")}</b>
          </TableCell>
          <TableCell  padding='none' align="center">
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <BsFillGrid1X2Fill style={{fontSize:'12px',marginRight:'3px'}}/>
                <b>NFTs</b>
              </Box>
          </TableCell>
          {/* <TableCell  align="center">
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <BsFillGrid1X2Fill style={{fontSize:'12px',marginRight:'3px'}}/>
              <b>{t("nft-screen.offers_modal_table.offered_title_column")}</b>
              </Box>
          </TableCell>
          <TableCell  align="center">
              <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <BsFillGrid1X2Fill style={{fontSize:'12px',marginRight:'3px'}}/>
                <b>{t("nft-screen.offers_modal_table.for_title_column")}</b>
              </Box>
          </TableCell> */}
          <TableCell align="center">
            <TableSortLabel
              active={orderBy === "amount"}
              direction={orderBy === "amount" ? order : 'asc'}
              onClick={createSortHandler("amount")}
            >
              <b>{t("nft-screen.offers_modal_table.crypto_title_column")}</b>
              {orderBy === "amount" ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ):null}
            </TableSortLabel>
          </TableCell>
          <TableCell align="center"
              sx={{
                  '@media screen and (max-width: 600px)':{
                      display:'none'
                  }
              }}
          >
              <b>{t("nft-screen.offers_modal_table.usd_title_column")}</b>
          </TableCell>
          <TableCell align="center"
            sx={{
              '@media screen and (max-width: 600px)':{
                display:'none'
              }
            }}
          >
            {
              show == "to" ?
              <b>{t("nft-screen.offers_modal_table.to_title_column")}</b>
              :
              <b>{t("nft-screen.offers_modal_table.from_title_column")}</b>
            }

          </TableCell>
          <TableCell align="center"
            sx={{
              '@media screen and (max-width: 600px)':{
                display:'none'
              }
            }}
          >
            <b>{t("nft-screen.offers_modal_table.date_title_column")}</b>
          </TableCell>
        </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  show: PropTypes.string
};



const EnhancedTable = ({setArrOffers,movements,setMovements,data,address,show}) => {
  const {t} = useTranslation("translate");
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('amount');
  const [arrChecked, setArrChecked] = React.useState([]);
  const [initCancel,setInitCancel] = React.useState(false);
  const [deleteLoading,setDeleteLoading] = React.useState(false);
  const [openModalOffersMade,setOpenModalOffersMade] = React.useState(false);
  const [msg,setMsg] = React.useState('');
  const [error, setError] = React.useState(false);
  const [closeRowCollapse,setCloseRowCollapse] = React.useState([]);
  

  const handleCloseRowCollapse = (pos,open) => {
    let closeRowCollapseByIndex = new Array();
    if(closeRowCollapse.length == 0){
        movements.map((val,index)=>{
          console.log(val)
          if(index === pos){
            closeRowCollapseByIndex.push(open)
          
          }else
          closeRowCollapseByIndex.push(false)
        })
        setCloseRowCollapse(closeRowCollapseByIndex)
    }else{
      closeRowCollapse.map((val,index)=>{
         console.log(val)
          if(index === pos){
            closeRowCollapseByIndex.push(open)
          }else
          closeRowCollapseByIndex.push(false)
      })
      setCloseRowCollapse(closeRowCollapseByIndex)
    }
    console.log("RowCollapse ::",closeRowCollapseByIndex);
    console.log(pos,open);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleCloseModalOffersMade = () => {
    setOpenModalOffersMade(false);
    setDeleteLoading(false);
    setOpenModalOffersMade(false);
    setInitCancel(false);
  }

  const handleResetAllOffers = () => {
    let arrTemp = new Array();
    movements.map((val)=>{
        arrChecked.map((arr)=>{
            if(arr.id == val.id && !arr.check){
                arrTemp.push(val)
            }
      })
    })
    setMovements(arrTemp)
    setArrOffers(arrTemp)
  }

  const handleCancelOfferCrypto = (crypto) =>{
    cancelAllCryptoRequestsByIds(crypto).then((success) => {
      console.log('crypto ::', crypto)
      console.log(success)
      setMsg(t('all_offers.cancel_success_text'))
      setInitCancel(false);
      handleResetAllOffers()
      setDeleteLoading(false);
      setArrChecked([]);
    },(error) => {
      console.log(error)
      setMsg(error.message)
      setError(true);
      setInitCancel(false);
      setDeleteLoading(false);
    })
  }

  const handleCancelOfferNft = (requestIDS) =>{
    cancelAllByIds(requestIDS).then((success) => {
      console.log(success)
      setMsg(t('all_offers.cancel_success_text'))
      setInitCancel(false);
      handleResetAllOffers()
      setDeleteLoading(false);
      setArrChecked([]);
    },(error) => {
        console.log(error)
        setMsg(error.message)
        setError(true);
        setInitCancel(false);
        setDeleteLoading(false);
    })
  }

  const handleCancelOfferBidAndNft = (requestIDS,crypto)=>{
    cancelAllByIds(requestIDS).then((success) => {
      console.log(success)
      cancelAllCryptoRequestsByIds(crypto).then((success) => {
        console.log(success)
        setMsg(t('all_offers.cancel_success_text'))
        setInitCancel(false);
        handleResetAllOffers()
        setDeleteLoading(false);
        setArrChecked([]);
      },(error) => {
        console.log(error)
        setMsg(error.message)
        setError(true);
        setInitCancel(false);
        setDeleteLoading(false);
      })
    },(error) => {
      console.log(error)
      setMsg(error.message)
      setError(true);
      setInitCancel(false);
      setDeleteLoading(false);
    })
  }

  const handleTrashFrom = () => {

    if(data && data.provider){
        setMsg('')
        setError(false);
        setDeleteLoading(true);
        setInitCancel(true);
        sign(data.userAccount,data.provider,t("all_offers.cancel_offers_text"),"cancel").then(res => {
            console.log(res)
            setError(false);

            let requestIDS = []
            let crypto = []

            arrChecked.forEach((check) => {
                if(check.amount){
                    if(check.check) {
                        crypto.push(check.id)
                    }
                }else
                    if(check.check) {
                        requestIDS.push(check.id)
                }
            })

            if(requestIDS.length > 0 && crypto.length > 0) {
              handleCancelOfferBidAndNft(requestIDS,crypto)
            }else
            if(requestIDS.length > 0) {
              handleCancelOfferNft(requestIDS)  
            }else
              if(crypto.length > 0){
              handleCancelOfferCrypto(crypto)
            }

        }).catch(error => {
            console.log(error.message)
            if(error.code == '4001'){
                setMsg(t("custom_error_metamask.cancel_transaction"));
            }else{
                setMsg("Error: "+ error.message.substring(0,100));
            }
            setError(true);
            setInitCancel(false);
            setDeleteLoading(false);
            setArrChecked([]);
        })
    }

  }

  

  const handleTrash = () => {
    console.log("arr offers checked::",arrChecked)

    if(show == "to"){
      setDeleteLoading(true);
      setOpenModalOffersMade(true);
      setInitCancel(true);
    }else{
      handleTrashFrom();
    }

  }

  const validIfExpiration = (timelive,created) => {
    const dateFormated = new Date(created);
    const today = new Date().getTime();
    if(Number(today) > (Number(dateFormated.getTime()) + Number(timelive))){
      return false
    }else{
      return true
    }
  }

  const handleChange = (e) =>{
    const {name,checked} = e.target;
        let arrTemp = new Array();
        if(arrChecked.length == 0){
            movements.map((val,index)=>{
                if(validIfExpiration(val.timelive,val.created_at)){
                    if(name != "allSelected"){
                        if(name == index){
                            arrTemp.push({name:index,check:checked, id : val.id,amount:val.amount ? val.amount: null})
                        }else{
                            arrTemp.push({name:index,check:!checked,  id : val.id,amount:val.amount ? val.amount: null})
                        }
                    }else{
                        arrTemp.push({name:index,check:checked,  id : val.id,amount:val.amount ? val.amount: null})
                    }
                }
            })
            setArrChecked(arrTemp)
        }else{
            arrChecked.map((val,index)=> {
                if(validIfExpiration(val.timelive,val.created_at)){
                    if(name != "allSelected"){
                        if(name == index){
                            arrTemp.push({name:index,check:checked,  id : val.id,amount:val.amount ? val.amount: null})
                        }else{
                            arrTemp.push(val)
                        }
                    }else{
                        arrTemp.push({name:index,check:checked,  id : val.id,amount:val.amount ? val.amount: null})
                    }
                }
            })
            setArrChecked(arrTemp);
        }

  }

  const descendingComparator = (a, b, orderBy) =>{
    if(b[orderBy]){
      if (parseFloat(b[orderBy]) < parseFloat(a[orderBy])) {
        return -1;
      }
      if (parseFloat(b[orderBy]) > parseFloat(a[orderBy])) {
        return 1;
      }
    }else{
      return -1;
    }
    
    return 0;
  }

  const getComparator = (order, orderBy)=> {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator)=>{
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }




  // const formatDate = (date) => {
  //   const lang = localStorage.getItem('i18nextLng');
  //   const setLang = lang ? lang : 'en'
  //   const dateFormated = new Date(date);
  //   const dateFormatedString = new Intl.DateTimeFormat(`${setLang}`, { month: 'short', day:'2-digit', year:'2-digit' }).format(dateFormated);
  //   const hourFormat = new Intl.DateTimeFormat(`${setLang}`, { hour: '2-digit', minute:'2-digit'}).format(dateFormated);
  //   let formatText = '';
  //     if(setLang == 'en'){
  //         formatText = dateFormatedString.replace(' ','/').replace(',','/')
  //     }else{
  //         formatText = dateFormatedString.replace(' ','/').replace(' ','/')
  //     }
  //     return formatText   +' - ' + hourFormat + ' H';
  // }




  return (
    <>

      {
        msg !== '' &&
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',padding:'10px 0px'}}>
          <Alert severity={error ? "error":"success"} >
            {msg}
          </Alert>
        </Box>
      }

      <Box sx={
            data  && (data.userAccount+'').toUpperCase() == (address+'').toUpperCase() ?
            {width:'100%',height:'40px',display:'flex',justifyContent:'flex-start',marginBottom:'10px'}
            :
            {display:'none'}
      }
      >
        <input style={{marginLeft:'15px',marginTop:'10px'}}
          type="checkbox"
          name='allSelected'
          checked={arrChecked.length == 0 ? false :  arrChecked.filter((val)=> val?.check !== true).length < 1}
          onChange={(e)=>{handleChange(e)}}
        />

          <span>
            {

              arrChecked.filter((val)=> val?.check === true).length > 0 && !initCancel &&
              <Tooltip title={t("all_offers.require_sign_text")} placement="right">
                    <IconButton
                        onClick={ () => {handleTrash()}}
                        component='span'
                        sx={{color:'gray'}}
                    >
                        <HighlightOffIcon/>
                    </IconButton>
              </Tooltip>
            }

            {
                deleteLoading &&
                <CircularProgress size={24}
                    sx={{
                        color: '#ED2891',
                        marginLeft: '5px',
                    }}
                />
            }
          </span>
      </Box>

      <Paper elevation={2}  >
      <Table  size="small" aria-labelledby="tableTitle" >

        <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              show={show}

        />

        <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:  rows.slice().sort(getComparator(order, orderBy)) */}
            {
              stableSort(movements, getComparator(order, orderBy)).map((movement, index) => {
              return (
                    <RowCollapse
                      key = {index}
                      row = {movement}
                      show = {show}
                      index = {index}
                      arrChecked = {arrChecked}
                      handleChange = {handleChange}
                      data = {data}
                      address = {address}
                      setCloseRowCollapse = {setCloseRowCollapse}
                      closeRowCollapse = {closeRowCollapse}
                      handleCloseRowCollapse = {handleCloseRowCollapse}
                    />
              );
            })}

        </TableBody>
      </Table>
      </Paper>
      <ModalCancelOffersMade
            openModalOffersMade={openModalOffersMade}
            arrChecked={arrChecked}
            setArrChecked={setArrChecked}
            handleCloseModalOffersMade={handleCloseModalOffersMade}
            data={data}
            movements={movements}
            setMovements={setMovements}
            setArrOffers={setArrOffers}
      />
    </>
  );
}

EnhancedTable.propTypes = {
  setArrOffers:PropTypes.func,
  movements: PropTypes.array,
  setMovements: PropTypes.func,
  data:PropTypes.object,
  address:PropTypes.string,
  show: PropTypes.string,
};

export default EnhancedTable;
