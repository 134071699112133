import React from 'react'
// import ReactAccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar, AvatarGroup, Box } from '@mui/material';
import { FiShare } from 'react-icons/fi';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import  { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'

const Share = ({func, setState, onChange, fileRef, content}) => {
    const { t } = useTranslation("translate");
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{
                paddingLeft:'10rem',
                paddingRight:'10rem',
                '@media screen and (max-width: 768px)':{
                    flexDirection:'column',
                    justifyContent:'center',
                    flexWrap:'wrap',
                    paddingLeft:'0px',
                    paddingRight:'0px',
                }
            }}
        >
            <Box
                sx={{
                    marginTop:'15px',
                    position:'relative',
                    boxSizing:'border-box',
                    padding:'2rem',
                    backgroundColor:'#fff',
                    display:'inline-flex',
                    gap:'2rem',
                    borderRadius:'8px',
                    boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.3)',
                    top: '-3.5rem',
                    '@media screen and (max-width: 768px)':{
                        flexWrap:'wrap',
                        justifyContent:'space-between',
                    }
                }}
            >
                <Box>
                    <Box sx={{color:'#7F7F7F', fontSize:'18px', fontWeight:600}}>{t('collection.box_item_one')}</Box>
                    <Box sx={{color:'#000', fontWeight:600, fontSize:'24px'}}>{content.collection_of}</Box>
                </Box>
                <Box>
                    <Box sx={{color:'#7F7F7F', fontSize:'18px', fontWeight:600}}>{t('collection.box_item_two')}</Box>
                    <Box display='flex' alignItems='center' sx={{gap:'5px'}}>
                        <span style={{color:'#000', fontWeight:600, fontSize:'24px'}}>{content.owned_by.length}</span>
                        <AvatarGroup total={content.owned_by.length}>
                        {content.owned_by.map((item, index)=>(  index < 3 &&
                            <Avatar 
                                key={index}
                                alt="Remy Sharp" 
                                src={item.profile_pic_url} 
                                sx={{ height: '25px', width: '25px'}}
                            />

                        ))}
                        </AvatarGroup>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{color:'#7F7F7F', fontSize:'18px', fontWeight:600}}>{t('collection.box_item_three')}</Box>
                    <Box sx={{color:'#000', fontWeight:600, fontSize:'24px'}}>--</Box>
                </Box>
                <Box>
                    <Box sx={{color:'#7F7F7F', fontSize:'18px', fontWeight:600}}>{t('collection.box_item_four')}</Box>
                    <Box sx={{color:'#000', fontWeight:600, fontSize:'24px'}}>--</Box>
                </Box>
            </Box>
            <Box
                sx={{
                    position:'relative',
                    boxSizing:'border-box',
                    display:'flex',
                    gap:'2rem',
                    top: '-4rem',
                    '@media screen and (max-width: 768px)':{
                        marginTop:'2rem',
                    }
                }}
            >
   {/*              <Box 
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    sx={{
                        color:'#000', 
                        fontWeight:600, 
                        fontSize:'50px',
                        boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.3)',
                        borderRadius:'999px',
                        width:'50px',
                        height:'50px',
                        backgroundColor:'#fff',
                        cursor:'pointer',
                    }}
                >
                    . ..
                    {/* <Tooltip title="Open settings">
                        ...
                </Box> 
                    </Tooltip> */}
                <Box 
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    onClick={()=>setState(true)}
                    sx={{
                        color:'#000', 
                        fontWeight:600, 
                        fontSize:'24px',
                        boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.3)',
                        borderRadius:'999px',
                        backgroundColor:'#fff',
                        paddingLeft:'1rem',
                        paddingRight:'1rem',
                        cursor:'pointer',
                    }}
                >
                    <span style={{marginRight:'5px'}}>{t('collection.share_btn')}</span>
                    <FiShare size={25} />
                </Box>
                { content.is_owner &&
                    <Box 
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        onClick={func}
                        sx={{
                            color:'#000', 
                            fontWeight:600, 
                            fontSize:'24px',
                            boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.3)',
                            borderRadius:'999px',
                            backgroundColor:'#fff',
                            paddingLeft:'1rem',
                            paddingRight:'1rem',
                            cursor:'pointer',
                        }}
                    >
                        <span style={{marginRight:'5px'}}>{t('collection.upload_btn')}</span>
                        <input onChange={onChange} ref={fileRef} type='file' style={{display:'none'}} />
                        <AiOutlineCloudUpload size={30} />
                    </Box>

                }
            </Box>
        </Box>
    )
}

Share.propTypes = {
    content: PropTypes.object.isRequired,
    func: PropTypes.func,
    setState: PropTypes.func,
    onChange: PropTypes.func,
    fileRef: PropTypes.object,
}

export default Share