//import { exist } from 'layouts/LoteryMain/components/TopBar/components/WalletSection/User/valieteExist';
import Web3 from 'web3'
import { getBalance } from 'services/MATIC/getBalance';
import { getUser } from './User/getUser';
import { balanceOf } from './WAVAX/balanceOf';

const web3Connection =async(provider) => {
    try{
        const web3 = new Web3(provider)
        const chainID = await web3.eth.getChainId()
        const network = await web3.eth.net.getNetworkType()
        const accounts = await web3.eth.getAccounts()
        const userAccount = accounts[0]
        const formatMaticBalance = await getBalance(userAccount)
        const formatWMaticBalance = await balanceOf(process.env.REACT_APP_WRAPPED,userAccount)
        const user = await getUser(userAccount);
        let viewEmailInput = true;
        if (user.registered) {
            viewEmailInput = false;
        }
        // console.log('ENtra a web3 ')
        localStorage.setItem('viewEmailInput',""+viewEmailInput)
        localStorage.setItem('current_wallet',""+userAccount)
        let sign_exchange = false;
        const userData = {
            userAccount,
            network,
            chainID,
            provider,
            formatMaticBalance,
            viewEmailInput,
            user,
            sign_exchange,
            formatWMaticBalance
        }
        return userData
    }catch(error){
        console.log('error metamask ::', error)
    }
}

export default web3Connection
