import React, { useEffect, useState } from 'react';
//import {NFTSectionProfile} from '../../components';
import { getNFTSByAddress } from 'services/NFTS/getNFTSByAddress';
import NFTsCard from 'components/NFTsCard';
import { useFetch } from 'hooks/useFetch'
import ErrorMessage from 'components/ErrorMessage';
import LoaderNFT from 'components/LoaderNFT';
import PropTypes from 'prop-types';

const MyNFTs = ({address}) => {
    const [nfts, setNFTS] = useState([]);
    const url = `${process.env.REACT_APP_URL_API}/nft?wallet=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=10&page=0&order=created`;
    const {data, loading, error} = useFetch(url);

    const init = async () =>{
        let nfts = await getNFTSByAddress(address)
        setNFTS(nfts)
    }

    useEffect(()=>{
        init();
    },[address])
    
    console.log("nfts ::",nfts);
    if(loading) return <LoaderNFT />
    if(error) return <ErrorMessage error={error.message} />
    return (
        <> {data ?
            <NFTsCard content={data} limit={10} /> :<NFTsCard content={[]} limit={10} />  }
            {/* <NFTSectionProfile resource={nfts && nfts.length>0 ? nfts :[]} user={user && user} address={address} data={data}/> */}
        </>
    )
}

MyNFTs.propTypes = {
    user: PropTypes.object.isRequired,
    address: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
}

export default MyNFTs;