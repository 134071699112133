import React from 'react'
import { Box } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import SortNFTs from './components/SortNFTs/SortNFTs';
import SortProfile from './components/SortProfile';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SideBar = ({filters,setFilters,isNFT,isProfile,handleRequestWithFilters}) => {
    const { t } = useTranslation("translate");
    return (
        <Box 
            component='aside'
            sx={{
                boxSizing:'border-box',
                marginLeft:'25px',
                width:'320px',
                position:'sticky',
                top: 0,
                '@media screen and (max-width: 600px)': {
                    position:'static',
                    width:'85%'
                }
                //height:'100vh',
                //overflow:'auto',
            }}
        >
            <Box
                component='article'
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                    display:'none',
                    borderBottom:'1px solid #e0e0e0',
                    '@media screen and (max-width: 768px)':{
                        display:'flex',
                    }
                }}   
            >
                <h1>{t('sidebar_component.filters')}</h1>
                <span><ClearIcon /></span>
            </Box>
            {isNFT && <SortNFTs filters={filters} setFilters={setFilters} handleRequestWithFilters={handleRequestWithFilters} />}
            {isProfile && <SortProfile />}
        </Box>
    )
}

SideBar.defaultProps = {
    filters: [{'':''}],
    setFilters:    () => {},
    isNFT: true,
    isProfile: false,
    handleRequestWithFilters: () => {}
}

SideBar.propTypes = {
    filters: PropTypes.array,
    setFilters: PropTypes.func,
    isNFT: PropTypes.bool,
    isProfile: PropTypes.bool,
    handleRequestWithFilters: PropTypes.func
}

export default SideBar