import metamask from "./metamask";
import WalletConnect from "./WalletConnect";

const getConnection =(provider)=>{
    if(provider==='metamask'){
        return metamask()
    }
    if(provider==='walletConnect'){
        return WalletConnect()
    }
}

export default getConnection