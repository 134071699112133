import React, {  useState } from 'react'
import { Avatar,CardMedia, Box, Button, CardContent,  Container, Grid, Typography } from '@mui/material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {BigTitle, DisplayOver,BackgroundNewCard} from './styles/styles'
import { Link } from 'react-router-dom';
import { nfts } from './utils/nfts';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import {useLocation} from 'react-router-dom'
//import { getThumbnail } from 'services/getThumbnail';

const NFTsCard = ({showBtnAll, content, limit}) => {
    const [counters, setCounters] = useState([])
    let isFirst = true;
    const location = useLocation();


    const countDown = () => {
        if (content) {
            setInterval(function () {
                   let counters = []
                   if (content && content.length > 0) {
                       return;
                   }
                   content.forEach((item,index) => {
                       if(item.auction && item.auction.finish_date > 0) {
                           // console.log('coun', item)
                           let countDownDate = item.auction.finish_date  * 1000;
                           let now = new Date().getTime();
                           let distance = countDownDate - now;
                           let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                           let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                           let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                           let seconds = Math.floor((distance % (1000 * 60)) / 1000)
                           if (distance >= 0) { 
                               counters[index] = [days, hours, minutes, seconds];
                           } 
                       }
                   });
                   // console.log('counters ::', counters)
                   setCounters(counters)
               }, 1000);

        }
    }

    if(isFirst) {
        isFirst = false;
        countDown();
    }
    const {t} = useTranslation("translate");
    

    if(content.length === 0 || !content) {
        return (
            <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}
            >
                    <Typography variant='h4' sx={{color:'red'}}>
                        {t("explore.not_found")}
                    </Typography>
            </Box>
     
        )
    }
    
    return (
        <>

        <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
            <Grid 
                container 
                columns={{sm:12, md:12, lg:12, xl:12}}
                rowSpacing={4} 
                spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
               
            >
                {content.slice(0, limit).map((item, index)=>(
                <Grid 
                    key={index} 
                    item 
                    sm={12} 
                    md={6} 
                    lg={location && location.pathname.includes('/explore') ? 4:3} 
                    xl={3}
                    sx={{
                        width:'100%'
                    }}
                >
                    {console.log('item.metadata.is_video',item.metadata.json_data)}
                    <Link 
                        to={`/nft?address=${item.collection_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                    <BackgroundNewCard>
                            <CardMedia
                                className='card-collection'
                                component={ (item.metadata.json_data.isVideo) ? 'video' : 'img'}
                                src={item.thumb_url}
                                autoPlay
                                loop
                                muted
                                sx={{
                                    position:'relative',
                                    borderRadius: '8px 8px 0 0',
                                    height:'100%',
                                    width:'100%',
                                    margin: '0 auto',
                                }}
                            />
                            <DisplayOver>
                                <BigTitle>
                                    {item.metadata.json_data.name}
                                </BigTitle>
                            </DisplayOver>

                                <Box
                                    sx={{
                                        height: "auto",
                                        bottom: "0",
                                        left: "0",
                                        position: "absolute",
                                        width: "100%",
                                        zIndex: 2,
                                        backgroundColor: "transparent",
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <CardContent
                                        sx={{
                                            backgroundColor:'rgba(2, 40, 87, 0.68)',
                                            borderRadius:'0 0 8px 8px',
                                            height:'auto',
                                            width:'100%',
                                            display:'flex',
                                            flexDirection:'column',
                                            paddingBottom:'0px'
                                        }}
                                    >
                                        <Box
                                            display='flex'
                                            sx={{
                                             
                                                "@media (min-width: 320px)": {
                                                    gap:'5px',
                                                }
                                            }}
                                        >
                                            <Box component='span'>
                                                <Avatar variant="circular" src={item.user && item.user.profile_pic_url} />
                                            </Box>
                                            <Box component='span'>
                                                <Typography 
                                                    variant="overline" 
                                                    display="block" 
                                                    gutterBottom 
                                                    sx={{
                                                        marginTop:'3px',
                                                        fontSize:'25px',
                                                        color:'#b3b3b3',
                                                        fontWeight: 600,
                                                        cursor:'pointer',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        width: '13rem',
                                                        display:'inline-block',
                                                        "@media (min-width: 320px)": {
                                                            fontSize:'13px',
                                                            fontWeight: 200,
                                                        }
                                                    }}
                                                >
                                                                                      {item.user && item.owner && (item.collection.user.main_key.toUpperCase() == item.owner.toUpperCase())? item.user.username : (item.owner).substring(0,5)+ '...' +(item.owner).substring(38,54) }
                                                </Typography>
                                            </Box>
                                        </Box>

                                        { ((item.on_auction == true) || (item.on_sale == true )) &&
                                            <Box
                                                display='flex'
                                                justifyContent='space-between'
                                            >
                                                {item &&
                                                    <Box>
                                                        <Box>
                                                            <Typography 
                                                                variant="overline" 
                                                                display="block" 
                                                                gutterBottom 
                                                                sx={{
                                                                    fontSize:'20px',
                                                                    color:'#b3b3b3',
                                                                    fontWeight: 600,
                                                                    "@media (min-width: 320px)": {
                                                                        fontSize:'13px',
                                                                        fontWeight: 200,
                                                                    }
                                                                }}
                                                            >
                                                                { item.on_auction == true ?
                                                                    t("home.current_bid") : t("home.buy_now")
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            display='flex'
                                                            sx={{
                                                                fontSize:'20px',
                                                                color:'#fff',
                                                                fontWeight: 600,
                                                                gap:'10px',
                                                                "@media (min-width: 320px)": {
                                                                    fontSize:'13px',
                                                                    fontWeight: 200,
                                                                    gap:'5px',
                                                                }
                                                            }}
                                                        >
                                                            <Box component='span'>
                                                                <RadioButtonCheckedIcon fontSize='5px' />
                                                            </Box>
                                                            <Box component='span'>
                                                                { item.on_auction && item.last_bid ?
                                                                item.last_bid.amount+' '+'WAVAX':  item.sale.price + ' ' + item.sale.coin 
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                }
                                                {item && item.on_auction && item.auction && item.auction.finish_date > 0 && item.auction.finish_date >= Math.floor(Date.now() / 1000) &&
                                                (<Box>
                                                 
                                                    <Box>
                                                        <Typography 
                                                            variant="overline" 
                                                            display="block" 
                                                            gutterBottom 
                                                            sx={{
                                                                fontSize:'15px',
                                                                color:'#b3b3b3',
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                        { t("home.ends_in")}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        display='flex'
                                                        sx={{
                                                            fontSize:'18px',
                                                            color:'#fff',
                                                            fontWeight: 600,
                                                            gap:'10px',
                                                            "@media (min-width: 320px)": {
                                                                fontSize:'13px',
                                                                fontWeight: 200,
                                                                gap:'5px',
                                                            }
                                                        }}
                                                    >
                                                        { counters.length > 0  && counters[index] && item.auction.finish_date > 0 && item.auction.finish_date >= Math.floor(Date.now() / 1000) &&
                                                            <>
                                                                <Box compoennt='span'>
                                                                    {counters[index][0]}d
                                                                </Box>
                                                                <Box compoennt='span'>
                                                                {counters[index][1]}h
                                                                </Box>
                                                                <Box compoennt='span'>
                                                                {counters[index][2]}m
                                                                </Box>
                                                                <Box compoennt='span'>
                                                                {counters[index][3]}s
                                                                </Box>
                                                            </>
                                                        }
                                                    </Box>
                                                </Box>)}
                                                {item && item.on_auction && item.auction && item.auction.finish_date > 0 && item.auction.finish_date < Math.floor(Date.now() / 1000) &&
                                                    
                                                    <Box>
                                                        <Box>
                                                            <Typography 
                                                                variant="overline" 
                                                                display="block" 
                                                                gutterBottom 
                                                                sx={{
                                                                    fontSize:'18px',
                                                                    color:'#b3b3b3',
                                                                    fontWeight: 600,
                                                                    "@media (min-width: 320px)": {
                                                                        fontSize:'13px',
                                                                        fontWeight: 200,
                                                                    }
                                                                }}
                                                            >
                                                                { item.on_auction == true ?
                                                                    t("home.auction_close") : t("home.auction_close")
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>

                                        }
                                    </CardContent>
                                </Box>
                    
                    </BackgroundNewCard>
                    </Link>
                </Grid>))}
            </Grid>
        </Container>
        {showBtnAll &&
        (<Container 
            maxWidth='sm' 
            sx={{
                display: 'flex',
                marginTop:'2rem',
                justifyContent:'center',
            }}
        >
            <Button
                variant="outlined"
                type="button"
                sx={{
                    borderRadius:'9999px',
                    backgroundColor:'#fff',
                    color:'#000',
                    border:'1px solid #e3e3e3',
                    fontSize:'18px',
                    '&:hover':{
                        backgroundColor:'#000',
                        transition:'background-color 0.3s ease-in-out',
                        color:'#fff',
                        border:'1px solid #000',
                    }
                }}
            >
                View all NFTs
            </Button>
        </Container>)}
        </>
    )
}

NFTsCard.defaultProps = {
    limit: 8,
    showBtnAll: false,
    content: nfts,
}

NFTsCard.propTypes = {
    showBtnAll: PropTypes.bool,
    content: PropTypes.array, 
    limit: PropTypes.number,
}

export default NFTsCard
