import React from 'react';
import { Container, Grid, Skeleton} from '@mui/material';

const LoaderHero = () => {

    return (
        <Container maxWidth='xl' sx={{marginTop:'1.5rem'}}>
            <center>
                <Grid 
                    container 
                    columns={{sm:12, md:12, lg:12, xl:12}}
                    rowSpacing={4} 
                    spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
                    justifyContent='center'
                    sx={{
                        width:'100%'
                    }}
                >
                    <Grid 
                        item 
                        sm={12} 
                        md={5} 
                        lg={5} 
                        xl={5}
                    >
                        <Skeleton variant="rectangular" height='30vh' width="100%">
                            <div style={{ paddingTop: '57%' }} />
                        </Skeleton>
                    </Grid>
                    <Grid 
                        item 
                        sm={12} 
                        md={5} 
                        lg={5} 
                        xl={5}
                    >
                       
                        <Skeleton sx={{marginTop:'15px'}} variant="rectangular" height='5vh' width="100%">
                            <div style={{ paddingTop: '57%' }} />
                        </Skeleton>
                        <Skeleton sx={{marginTop:'15px'}} variant="rectangular" height='5vh' width="100%">
                            <div style={{ paddingTop: '57%' }} />
                        </Skeleton>
                        <Skeleton sx={{marginTop:'15px'}} variant="rectangular" height='5vh' width="100%">
                            <div style={{ paddingTop: '57%' }} />
                        </Skeleton>
                          
                    </Grid>
                </Grid>
            </center>
        </Container>
    )
    
}

export default LoaderHero;