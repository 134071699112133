import React from 'react'
import HomeHero from './components/HomeHero';
// import HomeBlockSanity from './components/HomeBlockSanity';
import HomeSectionContent from './components/HomeSectionContent';
import HomeBlockContent from './components/HomeBlockContent';
import { useFetch } from 'hooks/useFetch';
import ErrorMessage from 'components/ErrorMessage';
import { Box } from '@mui/material';
import LoaderCard from 'components/LoaderCard';
import Carousel from 'components/Carousel';
import LoaderHero from 'components/LoaderHero';
import { isEnglish } from 'utils/sanityLanguage';


const Home = () => {
    const url = process.env.REACT_APP_URL_API+'/nft?domain='+process.env.REACT_APP_DOMAIN+'&limit=30&page=0&order=created&cahe=true';
    const {data, loading, error} = useFetch(url);
    //const randomUrl = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=1&page=0&order=RANDOM&key_name=SLUG&key_val=MeXIcanos`
    ///const {data:random, loading:loadingRandom, error:errorRandom} = useFetch(randomUrl);
    console.log('home data', data, isEnglish);
    return (
        <Box>
            <Carousel />
            {loading && <LoaderHero/>}
            {error && <ErrorMessage error={error.message} />}
            {data  && <HomeHero content={data[0]} />}
            <HomeBlockContent />
            {loading && <LoaderCard />}
            {error && <ErrorMessage error={error.message} />}
            {data &&
                    <HomeSectionContent
                        title={isEnglish() ? '' :''}
                        searchBlockName={isEnglish() ? '' : ''}
                        content={data}
                        showBtnAll={''} 
                        limit={16}
                        query={''}
                        nft={data[0]}

                    />
              
            }
            {/* <HomeBlockSanity /> */}
            <div style={{marginBottom:'2rem'}}>
                {loading && <LoaderCard />}
            </div>
            {error && <ErrorMessage error={error.message} />}
            
        </Box>
    );
}

export default Home;
