export const nft = {
  "project_key": "0xFe79c6Fd1519825E93A0951f8158E994453909e5",
  "project": {
    "name": "GÉNESIS GÉNESIS"
  },
  "project_key_format": "0xFe...09e5",
  "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
  "thumb_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
  "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
  "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
  "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
  "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
  "transaction": "0xa5116de3c377c0ef7e5cb12c97e76a497c44ea28c97cf6fb5f49a0131cd1fd31",
  "license": 1,
  "status": 1,
  "token_id": 1,
  "views": "14",
  "stock": "1",
  "is_owner": false,
  "owner": "0x30e6fc29d2c1df526f12d2be50b828e2ac0641f3",
  "short_owner": "0x30...41f3",
  "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
  "short_creator": "0x01...9014",
  "creator_avatar": "",
  "on_auction": false,
  "tags": [],
  "categories": [],
  "highest_bid": {
    "price": 0,
    "blockchain": {
      "name": "AVALANCHE FUJI ARTCRYPTED",
      "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
      "chainId": "0xA869",
      "symbol": "AVAX",
      "decimals": 18,
      "explorer": "https://testnet.snowtrace.io/",
      "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "testnet": false
    }
  },
  "count_bid": 0,
  "txs": [],
  "init_price": "0",
  "finish_price": "0",
  "on_sale": false,
  "amount_top": "0",
  "blockchain": {
    "name": "AVALANCHE FUJI ARTCRYPTED",
    "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
    "chainId": "0xA869",
    "symbol": "AVAX",
    "decimals": 18,
    "explorer": "https://testnet.snowtrace.io/",
    "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "testnet": false
  },
  "metadata": {
    "nft_key": "1_0xFe79c6Fd1519825E93A0951f8158E994453909e5",
    "image_url": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
    "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTjxQXSkertECQCTWCEqVEqb4kceXaF9idhr5ov4vsvSW",
    "is_video": false,
    "short_metadata_url": "https://...vsvSW",
    "json_data": {
      "name": "Lago",
      "image": "https://persea.mypinata.cloud/ipfs/QmUUSDLZNFtmeGJRrXxesScwYvkjSPNf1vzVNBChXia5mt",
      "external_url": "",
      "description": "test!",
      "isVideo": false,
      "attributes": [
        {
          "trait_type": "1",
          "value": "cine"
        }
      ]
    }
  },
  "user": {
    "registered": true,
    "balances": [],
    "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "domain": "artcrypted.com",
    "username": "0x016b76...9014",
    "email": "",
    "about": "",
    "twitter": "",
    "homepage": "",
    "telegram": "",
    "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "theme": 1,
    "expert": false,
    "first_seen": "2022-04-08 18:45:35.178152",
    "last_seen": "2022-04-08 18:45:35.178152",
    "role": 1,
    "followers": 175
  }
}