import React,{useState} from 'react';
import { Box, Button,Container,Alert,CardMedia, Input} from '@mui/material';
import PropTypes from 'prop-types';
import LoaderCircle from 'components/LoaderCircle';
import { useTranslation } from 'react-i18next';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext'
import ButtonStyled from 'components/ButtonStyled';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import UserNameInputType1 from 'components/Form/UserNameInputType1';
import EmailInputType1 from 'components/Form/EmailInputType1';
import LogoProfileBlue from 'assets/logos/logo_profile_blue.svg'
import axios from 'axios';
import { sign } from 'services/Utils/signature';
import { updateUser } from 'services/User/updateUser';

const styles = {
    backgroundColor: '#000',
    width:'80px',
    color:'#fff',
    marginTop:'8px',
    marginLeft:'5px',
    ':hover':{
        backgroundColor: '#000',
    }
}


const ProfileData = ({
    formEditProfile,
    setFormEditProfile,
    initEditProfile,
    setInitEditProfile,
    isEditProfile,
    isSetEditProfile,
    data,
    user,
    dataTemp,
    setDataTemp,
    showMsgRegister,
    setShowMsgRegister,
    getMyDataProfile,
    showMyInfo
    }) => {
    const {t} = useTranslation("translate");
    const [editProfile,setEditProfile] = useState(false);
    const [load,setLoad] = useState(false);
    
    console.log(editProfile);
  
    const [error,setError] = useState(false);
    const [msg, setMsg] = useState('');
    const {openWallet,setOpenWallet} = React.useContext(DrawerMobileContext);

    React.useEffect(()=>{
      
        setShowMsgRegister(localStorage.getItem('viewEmailInput') == "true" ? true : false);
        if(!showMsgRegister){
            isSetEditProfile(false)
        }else{
            isSetEditProfile(false)
        }
      
    },[openWallet,showMsgRegister])
    



    const [validateForm,setValidateForm] = useState([false,false]);

    const validateUsername = (username) =>{
        var re = /^[a-zA-Z0-9]+$/;
        return re.test(username);
    }

    const validateEmail = (email) =>{
        return new RegExp(/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(email)
    }


    const validForm = ()=>{
      
        setMsg('');
        if(validateForm.length > 0){
            validateForm[0] = validateUsername(formEditProfile.username);
            validateForm[1] =  validateEmail(formEditProfile.email)
            setValidateForm(validateForm)
            let arrValidate = new Array();
            arrValidate = validateForm;
            if(arrValidate.includes(false)){
                setMsg(t("message_validate_form.invalid_data_text"));
                setError(true);
                return false
            }
            else{
                setError(false);
                return true
            }
        }else{
            setMsg(t("message_validate_form.invalid_data_text"))
            setError(true);
            return false;
            
        }
    }



    const uploadFile = (thumbnail, isVideo) => {
        return new Promise((resolve, reject) => {
            try {
                let type = isVideo ? "video" : "img";
                let url_thumbnail = process.env.REACT_APP_URL_API+"/thumbnail?time=-1&type="+type+"&domain="+process.env.REACT_APP_DOMAIN
                console.log('video url_thumbnail', url_thumbnail)
                let headers = {
                  'Content-Type': 'multipart/form-data'
                }
                axios.post(url_thumbnail, thumbnail, { headers :headers}).then((success) => {
                    console.log('success ::', success);
                    resolve(success.data.url)
                })
            } catch (error) {
                reject(error)
            }
        })
    }
    
    const validateModifyForm = () =>{
        let band = false;
        if(
            (formEditProfile.validateAttr.username == true && formEditProfile.username !== dataTemp.username) ||
            (formEditProfile.validateAttr.email == true && formEditProfile.email !== dataTemp.email) ||
            (formEditProfile.validateAttr.avatar == true && formEditProfile.avatar !== '') ||
            (formEditProfile.validateAttr.banner == true && formEditProfile.banner !== '')
        ){
            band = true;
        }else{
            band = false;
        }
        return band;
    }

    const handleSubmitEditProfile = async(e)=>{
        e.preventDefault();
        const {username,email} = formEditProfile;
        if(validateModifyForm()){
            setMsg("");
            setError(false);
            if(validForm()){
                setInitEditProfile(true); 
                setLoad(true);
                let profilePic = new FormData();
                let profilePicURL = '';
                console.log(formEditProfile)
                profilePic.append("thumbnail", formEditProfile.fileAvatar);
                if(formEditProfile.avatar != '' && formEditProfile.validateAttr.avatar == true){
                    profilePicURL = await uploadFile(profilePic, false);
                }else{
                    profilePicURL = String(user.profile_pic_url).replace("https://persea.mypinata.cloud/ipfs/", "");     
                }
              
                let banner = new FormData();
                banner.append("thumbnail", formEditProfile.fileBanner)
                let bannerURL = '';
                if(formEditProfile.banner != '' && formEditProfile.validateAttr.banner == true){
                    bannerURL = await uploadFile(banner, false);
                }else{
                    bannerURL = String(user.banner_url).replace("https://persea.mypinata.cloud/ipfs/", "");
                }
                let  { signature, message } = await sign("Approve my intention to receive notifications at",data.userAccount,data.provider).catch(err=>{
                    setInitEditProfile(false);
                    console.log(err)
                    return  {signature:null, message:err.message};
                })

                if(signature){
                    setDataTemp({...dataTemp,username:username,email:email});
                    await updateUser({
                        address : data.userAccount,
                        username : formEditProfile.username,
                        email : formEditProfile.email,
                        bannerURL : bannerURL,
                        profilePicURL: profilePicURL,
                        signature : signature,
                        message : message
                    })
                    setMsg("");
                    setError(false);
                }else{
                    setMsg(message);
                    setError(true);
                }
                setInitEditProfile(false); 
            }
        }else{
            setMsg(t("profile.edit_profile_from.without_changes_text"));
            setError(false);
        }

    }

    const handleClickCancelEdit = () =>{
        setMsg('');
        setFormEditProfile({...formEditProfile,username:dataTemp.username,email:dataTemp.email,banner:'',fileBanner:[],avatar:'',fileAvatar:[]})
        setEditProfile(false);
        isSetEditProfile(false);
    }

    const handleOpenWallet = () =>{
        setOpenWallet(true)
    }

    const handleChangeProfileData = (e) =>{
        const {name,files} = e.target;
      
        if(name && name == "avatar" || name && name == "banner"){
            if (files &&  files.length > 0){
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onloadend = function() {
                 
                    var base64data = reader.result;                
                    if(name == "avatar"){
                        setFormEditProfile({...formEditProfile,[name]:base64data,fileAvatar:files[0],validateAttr:{...formEditProfile.validateAttr,avatar:true}});
                    }
                    else{
                        setFormEditProfile({...formEditProfile,[name]:base64data,fileBanner:files[0],validateAttr:{...formEditProfile.validateAttr,banner:true}});
                    }
                }      
            }
        }   
    }


    if(!showMsgRegister){
        return (
            <Container 
            maxWidth="lg"
            >
            {
            initEditProfile ? <LoaderCircle text={t("message_loader.loading")}/>
            :<>
                {
                    showMyInfo ?
                    <form onSubmit={(e)=>handleSubmitEditProfile(e)}>
                    <Box   sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '30px',
                    }}>

                        <Box sx={{width:'100%'}}>
                            <center>
                                <Input sx={{display:'none'}}
                                    id="file-avatar"
                                    type='file'
                                    disableUnderline
                                    onChange={(e)=>handleChangeProfileData(e)}
                                    name={"avatar"} 
                                    accept="image/png, image/gif, image/jpeg"
                                />
                                <Input sx={{display:'none'}}
                                    id="file-banner"
                                    type='file'
                                    disableUnderline
                                    onChange={(e)=>handleChangeProfileData(e)}
                                    property={{
                                        name:'banner'
                                    }}
                                    name={"banner"}
                                    accept="image/png, image/gif, image/jpeg"
                                />
                            </center>
                            <center>
                                <Box
                                    sx={{
                                        width:'50%',
                                        display:'flex',
                                        marginTop:'15px',
                                        justifyContent:'space-around',
                                        "@media (max-width: 768px)": {
                                            display:'grid',
                                            width:'100%',
                                            gridTemplateColumns:'1fr',
                                        }
                                    }}
                                >   
                                    
                                    <Box
                                        sx={{
                                            color:'#1B2635'
                                        }}
                                    >
                                        <label htmlFor="file-avatar" 
                                            style={{ 
                                                width:'135px',
                                                height:'135px',
                                                borderRadius:'50%',
                                                color:'#1B2635',
                                                cursor:'pointer'
                                            }}
                                            name="avatar"
                                        >
                                        <Box 
                                            sx={{
                                                width:'135px',
                                                height:'135px',
                                                borderRadius:'50%',
                                                border:'1px solid #1B2635',
                                                padding:'25px',
                                                cursor:'pointer',
                                                "&:hover":{
                                                    backgroundColor:'#CD1731',
                                                }
                                            }}
                                        >
                                        
                                            <CardMedia 
                                                component="img"
                                                sx={{width:'100%'}}
                                                src={LogoProfileBlue}
                                            />
                                            
                                        </Box>
                                            
                                        </label>
                                        <center>
                                            Avatar
                                        </center>
                                    </Box>
                                    <Box
                                        sx={{
                                            color:'#1B2635'
                                        }}
                                    >
                                        <label  htmlFor="file-banner"
                                            style={{ 
                                                width:'135px',
                                                height:'135px',
                                                borderRadius:'50%',
                                                color:'#fff',
                                                cursor:'pointer'
                                            }}
                                            name="banner"
                                        >
                                        <Box 
                                        
                                            sx={{
                                                width:'135px',
                                                height:'135px',
                                                borderRadius:'8px',
                                                border:'1px solid #1B2635',
                                                padding:'20px',
                                                cursor:'pointer',
                                                "&:hover":{
                                                    backgroundColor:'#CD1731',
                                                }
                                            }}
                                        >
                                            <PhotoCameraBackIcon htmlColor='#1B2635' sx={{width:'100%',height:'100%'}}/>
                                            
                                        </Box>
                                        </label>
                                        <center>
                                            Portada
                                        </center>
                                    </Box>
                                
                                </Box>
                            
                            </center>
                            <Box 
                                sx={{
                                    width:'100%',
                                    display:'flex',
                                    marginTop:'15px',
                                    justifyContent:'space-around',
                                    "@media (max-width: 768px)": {
                                        display:'grid',
                                        width:'100%',
                                        gridTemplateColumns:'1fr',
                                    }
                                }}>
                        
                                <UserNameInputType1
                                    setMsg={setMsg}
                                    setError={setError}
                                    isEditProfile={isEditProfile} 
                                    form={formEditProfile} 
                                    setForm={setFormEditProfile} 
                                    name={"username"} 
                                    placeholder={"username"} 
                                />
                                <EmailInputType1
                                    setMsg={setMsg} 
                                    setError={setError}  
                                    isEditProfile={isEditProfile} 
                                    form={formEditProfile} 
                                    setForm={setFormEditProfile} 
                                    name={"email"} 
                                    placeholder={"email"}
                                    emailTemp={dataTemp.email}
                                />
                            </Box>
                            <Box
                                sx={{width:'100%',display:'flex',justifyContent:'center',marginTop:'15px',marginBottom:'15px'}}
                            >
                                <ButtonStyled
                                    text={t("profile.edit_profile_from.save_btn")}
                                />
                                
                            </Box>
                        </Box>

                    
                    </Box>
        
                    <Container  maxWidth="sm">
                        <center>
                        {
                            msg != '' &&
                            <Alert severity={error ? "error":"success"} sx={{display:'flex',justifyContent:'center'}}>{msg}</Alert>
                        }
                        </center>
                    </Container>
                
                    <Container 
                        maxWidth="sm"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap:'10px'
                        }}
                    >          
                        {
                            isEditProfile ? 
        
                            <>
                            <Button
                                type='submit'
                                disabled = {load}
                                onClick={handleSubmitEditProfile}
                                sx={styles}
                            >
                                {t("profile.edit_profile_from.save_btn")}
                            </Button>
                            <Button
                                disabled = {load}
                                onClick={handleClickCancelEdit}
                                sx={styles}
                            >
                                {t("profile.edit_profile_from.cancel_btn")}
                            </Button>
                            </>
                            :''
                        }       
                        
                    </Container>
                    </form>
                :
                    <Box
                        sx={{
                            width:'100%',
                            display:'flex',
                            justifyContent:'center',
                            marginTop:'50px',
                            marginBottom:'15px'
                        }}
                    >
                        <ButtonStyled 
                            onClick={getMyDataProfile}
                            width='300px'
                            text={t("profile.show_my_data")}
                        />
                    </Box>        
                }
            </>
            }
        </Container>
        )
    }
    else{
        return (
            <Container  maxWidth="lg">
                <Box   sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '30px',
                }}
                >
                   <b>{t("profile.register_text")}</b>
                </Box>
                <center>
                    <ButtonStyled 
                        onClick={handleOpenWallet}
                        width='25%'
                        text={t("profile.register_here_text")}
                    />
                   
                </center>
               
            </Container>
        )
      
    }
    
}

ProfileData.propTypes = {
    formEditProfile: PropTypes.object,
    setFormEditProfile: PropTypes.func,
    initEditProfile : PropTypes.bool,
    isEditProfile : PropTypes.bool,
    isSetEditProfile: PropTypes.func,
    setInitEditProfile : PropTypes.func,
    data : PropTypes.object,
    user: PropTypes.object,
    dataTemp: PropTypes.object,
    setDataTemp: PropTypes.func,
    showMsgRegister: PropTypes.bool,
    setShowMsgRegister: PropTypes.func,
    getMyDataProfile: PropTypes.func,
    showMyInfo: PropTypes.bool
}

export default ProfileData;