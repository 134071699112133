import Web3 from 'web3'
import Contract from './Collectibles.json'

export const deploy = async(name,symbol,resalePercentage,from,provider) => {
    let web3 = new Web3(provider);
    let contract = new web3.eth.Contract(Contract.abi)
    console.log(Contract)
    let transaction = await contract.deploy({
        data :Contract.bytecode,
        arguments : [
          name, symbol,resalePercentage
        ]
     }).send({
       from : from
     })

    return transaction
}