import React from 'react'
import {Box} from '@mui/material'
import TelegramIcon from '@mui/icons-material/Telegram';

// font-size: 16px;  Cambiar el tamaño de la tipografia 
// text-transform: uppercase;  Texto en mayusculas 
// font-weight: bold;  Fuente en negrita o bold 
// color: #ffffff; Color del texto 
// border-radius: 5px;  Borde del boton 
// letter-spacing: 2px; Espacio entre letras 
// background-color: #E91E63; Color de fondo 
// padding: 18px 30px; Relleno del boton 
// position: fixed;
// bottom: 40px;
// right: 40px;
// transition: all 300ms ease 0ms;
// box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
// z-index: 99;

//



const  FloatBtn = () =>{
    const handleOpenTelegram = () =>{
        window.open('https://t.me/ChivasNFT', '_blank');
    }

    return(
        <Box 
            sx={{
                width: '72px',
                height: '72px',
                backgroundColor: '#1976d2 !important',
                borderRadius: '50%',
                position: 'fixed',
                bottom: '15px',
                right: '15px',
                zIndex: '99 !important',
                cursor: 'pointer',
                "&:hover": {
                    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)'
                }
               

            }}
            onClick={handleOpenTelegram}
        >
            <Box
                sx={{
                    width: '72px',
                    height: '72px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center'
                }}
            >
                <TelegramIcon htmlColor='#fff' sx={{fontSize:'40px'}} />    
            </Box>
            
        </Box>
    )
}

export default FloatBtn;