import { FormControl } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';
//import artist from './services/artist'
//import Proptypes from 'prop-types';

const CustomSelect = styled('select')(() => ({
    border: '1px solid #ed2891',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '8px',
    width: '140px',
    '@media screen and (max-width: 500px)': {
        //width: '100%',
        width: '100%',
    }
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

  const CustomOption = styled('option')(() => ({
    //backgroundColor: '#FAB9DB',
    fontSize: '16px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

const DropDownPrice = ({handleFetchOrder}) => {
    const { t } = useTranslation("translate");
    const mintingRef = React.useRef(null);
    const handleChange = () => {
        handleFetchOrder(mintingRef.current.value)
    }
    return (
        <FormControl
            variant="outlined" 
            sx={{ 
                minWidth: 150,
                //border:'1px solid#ed2891',
                borderRadius: '8px',
                outline: 'none',
                '&:hover': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                },
                '@media screen and (max-width: 500px)': {
                    width: '100%',
                    marginBottom: '10px',
                }
            }}
        >
            <CustomSelect ref={mintingRef} onChange={handleChange}>
                <CustomOption value={1}>{t("search.order_by")}</CustomOption>
                <CustomOption value={2}>{t("search.filter_minting_asc")}</CustomOption>
                <CustomOption value={3}>{t("search.filter_minting_desc")}</CustomOption>
                <CustomOption value={4}>{t("search.filter_block")}</CustomOption>
                <CustomOption value={5}>{t("search.filter_unblock")}</CustomOption>
                {/*<CustomOption>{t("search.lower")}</CustomOption>*/}
            </CustomSelect>
        </FormControl>
    );
}

DropDownPrice.propTypes = {
    setOrder: Proptypes.func.isRequired,
    handleFetchOrder: Proptypes.func.isRequired,
}

//DropDownPrice.propTypes = {
//    setSearch: Proptypes.func,
//}

export default DropDownPrice;