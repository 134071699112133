import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SortByDropdown = () => {
    const { t } = useTranslation("translate")
    return (
        <Box 
            display='flex' 
            justifyContent='center' 
            alignItems='center' 
            sx={{
               
                gap:'10px', 
                paddingRight:'24px',
                '@media screen and (min-width: 768px)': {
                    display:'flex',
                }
            }}
        >
            <span style={{fontSize:'20px', marginLeft:'5px'}}>{t("profile.tab_sort_by")}</span>
            <select>
                <option>{t('tab_selector_component.newest')}</option>
                <option>{t('tab_selector_component.oldest')}</option>
                <option>{t('tab_selector_component.price_highest')}</option>
                <option>{t('tab_selector_component.price_lowest')}</option>
            </select>
        </Box>
    )
}

export default SortByDropdown