export const nfts = [
  {
    "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "project_key_format": "0x8c...5afD",
    "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "thumb_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
    "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmfWXrbQ2NMGkf7YcZWNgZjun9zgZiE8nwEghFrg8hQCrN",
    "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
    "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmPS8MWf29DB4pNehfHKd77hdVnLZxDCwG3seZD9wVLCQz",
    "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
    "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "license": 1,
    "status": 1,
    "token_id": 4,
    "views": "419",
    "stock": "1",
    "is_owner": false,
    "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "short_owner": "0x01...9014",
    "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "short_creator": "0x01...9014",
    "creator_avatar": "",
    "on_auction": false,
    "tags": [],
    "categories": [],
    "highest_bid": {},
    "count_bid": 0,
    "current_bid": 0,
    "price": 0,
    "coin_symbol": "AVAX",
    "txs": [],
    "init_price": "0",
    "finish_price": "0",
    "on_sale": false,
    "amount_top": "0",
    "blockchain": {
    "name": "AVALANCHE FUJI WEGOATYOU",
    "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
    "chainId": "0xA869",
    "symbol": "AVAX",
    "decimals": 18,
    "explorer": "https://testnet.snowtrace.io/",
    "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "testnet": false
    },
    "metadata": {
    "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "image_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
    "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTkHcwtSG5ZBxvr3xuBAzCdudqxVi8GWDrvQ6cXY7WMnL",
    "is_video": false,
    "resolution": "1919x1280",
    "format": "jpeg",
    "short_metadata_url": "https://...7WMnL",
    "json_data": {
    "name": "Lumn1",
    "image": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
    "external_url": "",
    "description": "dasfrerf",
    "isVideo": false,
    "attributes": [
    {
    "trait_type": "sef",
    "value": "love"
    }
    ]
    }
    },
    "user": {
    "registered": true,
    "balances": [],
    "games": [],
    "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "domain": "wegoatyou.art",
    "username": "Daniel",
    "email": "",
    "about": "",
    "twitter": "",
    "homepage": "",
    "telegram": "",
    "banner_url": "https://persea.mypinata.cloud/ipfs/QmUSaZZsj7AT25u5zGZQb71om3sRLF2nBVPZc3W9k7J424",
    "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmWVK3REf6Rrii2RdE9hRXyaj6pjVHasEtvhUgru5eCS2N",
    "theme": 1,
    "expert": false,
    "first_seen": "2022-05-09 19:47:03.082882",
    "last_seen": "2022-05-09 19:47:03.082882",
    "role": 1,
    "followers": 903
    },
    "project": {
    "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "project_key_format": "0x8c...5afD",
    "user_key": "0x01...9014",
    "thumb_url": "https://persea.mypinata.cloud/ipfs/QmcFKacJyssNHr18sDM6HHmABZTfbX3ehhPdXyoFn3WqB6",
    "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "domain": "wegoatyou.art",
    "only_domain": true,
    "imported": true,
    "name": "Train",
    "description": "asfdafsfdsf",
    "nfts": [],
    "categories": [],
    "tags": [],
    "banner_url": "",
    "offers": [],
    "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "comments": [],
    "is_video": false,
    "blockchain": {
    "name": "AVALANCHE FUJI WEGOATYOU",
    "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
    "chainId": "0xA869",
    "symbol": "AVAX",
    "decimals": 18,
    "explorer": "https://testnet.snowtrace.io/",
    "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "testnet": false
    },
    "created": 1652125623.097009
    },
    "auction": {
    "id": 7,
    "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "init_price": 2,
    "last_price": 0,
    "current_bid": 0,
    "time_live": 259200,
    "start_date": 0,
    "finish_date": 0,
    "domain": "wegoatyou.art",
    "status": 1
    },
    "sale": {
    "id": 7,
    "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
    "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "price": 0,
    "coin": "AVAX",
    "domain": "wegoatyou.art",
    "status": 1
    },
    "random": [],
    "last_bid": {
    "amount": 2,
    "show_time": false,
    "date_end": 0
    }
    },
    {
      "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "project_key_format": "0x8c...5afD",
      "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
      "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmfWXrbQ2NMGkf7YcZWNgZjun9zgZiE8nwEghFrg8hQCrN",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
      "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmPS8MWf29DB4pNehfHKd77hdVnLZxDCwG3seZD9wVLCQz",
      "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
      "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "license": 1,
      "status": 1,
      "token_id": 4,
      "views": "419",
      "stock": "1",
      "is_owner": false,
      "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_owner": "0x01...9014",
      "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "short_creator": "0x01...9014",
      "creator_avatar": "",
      "on_auction": false,
      "tags": [],
      "categories": [],
      "highest_bid": {},
      "count_bid": 0,
      "current_bid": 0,
      "price": 0,
      "coin_symbol": "AVAX",
      "txs": [],
      "init_price": "0",
      "finish_price": "0",
      "on_sale": true,
      "amount_top": "0",
      "blockchain": {
      "name": "AVALANCHE FUJI WEGOATYOU",
      "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
      "chainId": "0xA869",
      "symbol": "AVAX",
      "decimals": 18,
      "explorer": "https://testnet.snowtrace.io/",
      "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "testnet": false
      },
      "metadata": {
      "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "image_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
      "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTkHcwtSG5ZBxvr3xuBAzCdudqxVi8GWDrvQ6cXY7WMnL",
      "is_video": false,
      "resolution": "1919x1280",
      "format": "jpeg",
      "short_metadata_url": "https://...7WMnL",
      "json_data": {
      "name": "Lumn2",
      "image": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
      "external_url": "",
      "description": "dasfrerf",
      "isVideo": false,
      "attributes": [
      {
      "trait_type": "sef",
      "value": "love"
      }
      ]
      }
      },
      "user": {
      "registered": true,
      "balances": [],
      "games": [],
      "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "domain": "wegoatyou.art",
      "username": "Daniel",
      "email": "",
      "about": "",
      "twitter": "",
      "homepage": "",
      "telegram": "",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmUSaZZsj7AT25u5zGZQb71om3sRLF2nBVPZc3W9k7J424",
      "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmWVK3REf6Rrii2RdE9hRXyaj6pjVHasEtvhUgru5eCS2N",
      "theme": 1,
      "expert": false,
      "first_seen": "2022-05-09 19:47:03.082882",
      "last_seen": "2022-05-09 19:47:03.082882",
      "role": 1,
      "followers": 903
      },
      "project": {
      "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "project_key_format": "0x8c...5afD",
      "user_key": "0x01...9014",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/QmcFKacJyssNHr18sDM6HHmABZTfbX3ehhPdXyoFn3WqB6",
      "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "domain": "wegoatyou.art",
      "only_domain": true,
      "imported": true,
      "name": "Train",
      "description": "asfdafsfdsf",
      "nfts": [],
      "categories": [],
      "tags": [],
      "banner_url": "",
      "offers": [],
      "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "comments": [],
      "is_video": false,
      "blockchain": {
      "name": "AVALANCHE FUJI WEGOATYOU",
      "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
      "chainId": "0xA869",
      "symbol": "AVAX",
      "decimals": 18,
      "explorer": "https://testnet.snowtrace.io/",
      "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "testnet": false
      },
      "created": 1652125623.097009
      },
      "auction": {
      "id": 7,
      "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "init_price": 2,
      "last_price": 0,
      "current_bid": 0,
      "time_live": 259200,
      "start_date": 0,
      "finish_date": 0,
      "domain": "wegoatyou.art",
      "status": 1
      },
      "sale": {
      "id": 7,
      "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
      "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "price": 0,
      "coin": "AVAX",
      "domain": "wegoatyou.art",
      "status": 1
      },
      "random": [],
      "last_bid": {
      "amount": 2,
      "show_time": false,
      "date_end": 0
      }
      },
      {
        "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "project_key_format": "0x8c...5afD",
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "thumb_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
        "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmfWXrbQ2NMGkf7YcZWNgZjun9zgZiE8nwEghFrg8hQCrN",
        "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
        "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmPS8MWf29DB4pNehfHKd77hdVnLZxDCwG3seZD9wVLCQz",
        "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
        "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "license": 1,
        "status": 1,
        "token_id": 4,
        "views": "419",
        "stock": "1",
        "is_owner": false,
        "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "short_owner": "0x01...9014",
        "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "short_creator": "0x01...9014",
        "creator_avatar": "",
        "on_auction": true,
        "tags": [],
        "categories": [],
        "highest_bid": {},
        "count_bid": 0,
        "current_bid": 0,
        "price": 0,
        "coin_symbol": "AVAX",
        "txs": [],
        "init_price": "0",
        "finish_price": "0",
        "on_sale": true,
        "amount_top": "0",
        "blockchain": {
        "name": "AVALANCHE FUJI WEGOATYOU",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
        },
        "metadata": {
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTkHcwtSG5ZBxvr3xuBAzCdudqxVi8GWDrvQ6cXY7WMnL",
        "is_video": false,
        "resolution": "1919x1280",
        "format": "jpeg",
        "short_metadata_url": "https://...7WMnL",
        "json_data": {
        "name": "Lumn3",
        "image": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
        "external_url": "",
        "description": "dasfrerf",
        "isVideo": false,
        "attributes": [
        {
        "trait_type": "sef",
        "value": "love"
        }
        ]
        }
        },
        "user": {
        "registered": true,
        "balances": [],
        "games": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "wegoatyou.art",
        "username": "Daniel",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmUSaZZsj7AT25u5zGZQb71om3sRLF2nBVPZc3W9k7J424",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmWVK3REf6Rrii2RdE9hRXyaj6pjVHasEtvhUgru5eCS2N",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-05-09 19:47:03.082882",
        "last_seen": "2022-05-09 19:47:03.082882",
        "role": 1,
        "followers": 903
        },
        "project": {
        "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "project_key_format": "0x8c...5afD",
        "user_key": "0x01...9014",
        "thumb_url": "https://persea.mypinata.cloud/ipfs/QmcFKacJyssNHr18sDM6HHmABZTfbX3ehhPdXyoFn3WqB6",
        "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "domain": "wegoatyou.art",
        "only_domain": true,
        "imported": true,
        "name": "Train",
        "description": "asfdafsfdsf",
        "nfts": [],
        "categories": [],
        "tags": [],
        "banner_url": "",
        "offers": [],
        "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "comments": [],
        "is_video": false,
        "blockchain": {
        "name": "AVALANCHE FUJI WEGOATYOU",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
        },
        "created": 1652125623.097009
        },
        "auction": {
        "id": 7,
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "init_price": 2,
        "last_price": 0,
        "current_bid": 0,
        "time_live": 259200,
        "start_date": 0,
        "finish_date": 1653089368,
        "domain": "wegoatyou.art",
        "status": 1
        },
        "sale": {
        "id": 7,
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "price": 0,
        "coin": "AVAX",
        "domain": "wegoatyou.art",
        "status": 1
        },
        "random": [],
        "last_bid": {
        "amount": 2,
        "show_time": false,
        "date_end": 0
        }
      },
      {
        "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "project_key_format": "0x8c...5afD",
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "thumb_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
        "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmfWXrbQ2NMGkf7YcZWNgZjun9zgZiE8nwEghFrg8hQCrN",
        "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
        "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmPS8MWf29DB4pNehfHKd77hdVnLZxDCwG3seZD9wVLCQz",
        "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
        "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "license": 1,
        "status": 1,
        "token_id": 4,
        "views": "419",
        "stock": "1",
        "is_owner": false,
        "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "short_owner": "0x01...9014",
        "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "short_creator": "0x01...9014",
        "creator_avatar": "",
        "on_auction": true,
        "tags": [],
        "categories": [],
        "highest_bid": {},
        "count_bid": 0,
        "current_bid": 0,
        "price": 0,
        "coin_symbol": "AVAX",
        "txs": [],
        "init_price": "0",
        "finish_price": "0",
        "on_sale": true,
        "amount_top": "0",
        "blockchain": {
        "name": "AVALANCHE FUJI WEGOATYOU",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
        },
        "metadata": {
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "image_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
        "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTkHcwtSG5ZBxvr3xuBAzCdudqxVi8GWDrvQ6cXY7WMnL",
        "is_video": false,
        "resolution": "1919x1280",
        "format": "jpeg",
        "short_metadata_url": "https://...7WMnL",
        "json_data": {
        "name": "Lumn4",
        "image": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
        "external_url": "",
        "description": "dasfrerf",
        "isVideo": false,
        "attributes": [
        {
        "trait_type": "sef",
        "value": "love"
        }
        ]
        }
        },
        "user": {
        "registered": true,
        "balances": [],
        "games": [],
        "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "domain": "wegoatyou.art",
        "username": "Daniel",
        "email": "",
        "about": "",
        "twitter": "",
        "homepage": "",
        "telegram": "",
        "banner_url": "https://persea.mypinata.cloud/ipfs/QmUSaZZsj7AT25u5zGZQb71om3sRLF2nBVPZc3W9k7J424",
        "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmWVK3REf6Rrii2RdE9hRXyaj6pjVHasEtvhUgru5eCS2N",
        "theme": 1,
        "expert": false,
        "first_seen": "2022-05-09 19:47:03.082882",
        "last_seen": "2022-05-09 19:47:03.082882",
        "role": 1,
        "followers": 903
        },
        "project": {
        "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "project_key_format": "0x8c...5afD",
        "user_key": "0x01...9014",
        "thumb_url": "https://persea.mypinata.cloud/ipfs/QmcFKacJyssNHr18sDM6HHmABZTfbX3ehhPdXyoFn3WqB6",
        "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "domain": "wegoatyou.art",
        "only_domain": true,
        "imported": true,
        "name": "Train",
        "description": "asfdafsfdsf",
        "nfts": [],
        "categories": [],
        "tags": [],
        "banner_url": "",
        "offers": [],
        "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "comments": [],
        "is_video": false,
        "blockchain": {
        "name": "AVALANCHE FUJI WEGOATYOU",
        "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
        "chainId": "0xA869",
        "symbol": "AVAX",
        "decimals": 18,
        "explorer": "https://testnet.snowtrace.io/",
        "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
        "testnet": false
        },
        "created": 1652125623.097009
        },
        "auction": {
        "id": 7,
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "init_price": 2,
        "last_price": 0,
        "current_bid": 0,
        "time_live": 259200,
        "start_date": 0,
        "finish_date": 0,
        "domain": "wegoatyou.art",
        "status": 1
        },
        "sale": {
        "id": 7,
        "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
        "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
        "price": 0,
        "coin": "AVAX",
        "domain": "wegoatyou.art",
        "status": 1
        },
        "random": [],
        "last_bid": {
        "amount": 2,
        "show_time": false,
        "date_end": 0
        }
        },
        {
          "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "project_key_format": "0x8c...5afD",
          "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "thumb_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
          "thumb_url_mini": "https://persea.mypinata.cloud/ipfs/QmfWXrbQ2NMGkf7YcZWNgZjun9zgZiE8nwEghFrg8hQCrN",
          "thumb_url_big": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
          "thumb_url_large": "https://persea.mypinata.cloud/ipfs/QmPS8MWf29DB4pNehfHKd77hdVnLZxDCwG3seZD9wVLCQz",
          "thumb_resize": "https://persea.mypinata.cloud/ipfs/QmNuyDJAWjHXNiJcLC6i7AmPMRCps35zXfcuEN28nVbhh8",
          "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "license": 1,
          "status": 1,
          "token_id": 4,
          "views": "419",
          "stock": "1",
          "is_owner": false,
          "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
          "short_owner": "0x01...9014",
          "creator": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
          "short_creator": "0x01...9014",
          "creator_avatar": "",
          "on_auction": false,
          "tags": [],
          "categories": [],
          "highest_bid": {},
          "count_bid": 0,
          "current_bid": 0,
          "price": 0,
          "coin_symbol": "AVAX",
          "txs": [],
          "init_price": "0",
          "finish_price": "0",
          "on_sale": false,
          "amount_top": "0",
          "blockchain": {
          "name": "AVALANCHE FUJI WEGOATYOU",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
          },
          "metadata": {
          "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "image_url": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
          "metadata_url": "https://persea.mypinata.cloud/ipfs/QmTkHcwtSG5ZBxvr3xuBAzCdudqxVi8GWDrvQ6cXY7WMnL",
          "is_video": false,
          "resolution": "1919x1280",
          "format": "jpeg",
          "short_metadata_url": "https://...7WMnL",
          "json_data": {
          "name": "Lumn5",
          "image": "https://persea.mypinata.cloud/ipfs/QmaMVUPZEsFFj1xijM2mCTx9w1C7hJunJnyGuYqBk1WsmG",
          "external_url": "",
          "description": "dasfrerf",
          "isVideo": false,
          "attributes": [
          {
          "trait_type": "sef",
          "value": "love"
          }
          ]
          }
          },
          "user": {
          "registered": true,
          "balances": [],
          "games": [],
          "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
          "domain": "wegoatyou.art",
          "username": "Daniel",
          "email": "",
          "about": "",
          "twitter": "",
          "homepage": "",
          "telegram": "",
          "banner_url": "https://persea.mypinata.cloud/ipfs/QmUSaZZsj7AT25u5zGZQb71om3sRLF2nBVPZc3W9k7J424",
          "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmWVK3REf6Rrii2RdE9hRXyaj6pjVHasEtvhUgru5eCS2N",
          "theme": 1,
          "expert": false,
          "first_seen": "2022-05-09 19:47:03.082882",
          "last_seen": "2022-05-09 19:47:03.082882",
          "role": 1,
          "followers": 903
          },
          "project": {
          "project_key": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "project_key_format": "0x8c...5afD",
          "user_key": "0x01...9014",
          "thumb_url": "https://persea.mypinata.cloud/ipfs/QmcFKacJyssNHr18sDM6HHmABZTfbX3ehhPdXyoFn3WqB6",
          "transaction": "0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "domain": "wegoatyou.art",
          "only_domain": true,
          "imported": true,
          "name": "Train",
          "description": "asfdafsfdsf",
          "nfts": [],
          "categories": [],
          "tags": [],
          "banner_url": "",
          "offers": [],
          "owner": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
          "comments": [],
          "is_video": false,
          "blockchain": {
          "name": "AVALANCHE FUJI WEGOATYOU",
          "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
          "chainId": "0xA869",
          "symbol": "AVAX",
          "decimals": 18,
          "explorer": "https://testnet.snowtrace.io/",
          "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
          "testnet": false
          },
          "created": 1652125623.097009
          },
          "auction": {
          "id": 7,
          "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
          "init_price": 2,
          "last_price": 0,
          "current_bid": 0,
          "time_live": 259200,
          "start_date": 0,
          "finish_date": 1653089368,
          "domain": "wegoatyou.art",
          "status": 1
          },
          "sale": {
          "id": 7,
          "nft_key": "4_0x8c62B3d4c6F4e194843F248bF7D70aF2390A5afD",
          "main_key": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
          "price": 0,
          "coin": "AVAX",
          "domain": "wegoatyou.art",
          "status": 1
          },
          "random": [],
          "last_bid": {
          "amount": 2,
          "show_time": false,
          "date_end": 0
          }
          }
          
]