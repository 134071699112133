import React from 'react'
import { Box, Card, CardMedia } from '@mui/material'
import preview from 'assets/images/preview.jpg'
import PropTypes from 'prop-types'

const ImagePreview = ({image,isVideo, title}) => {
    return (
        <>
            <Box
                component='h2'
                sx={{
                    fontSize:'26px',
                    lineHeight:'26px',
                    marginBottom:'20px',
                    marginTop:'0px',
                }}
            >
                {title}
            </Box>
            <Card
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    border: '1px solid #E5E5E5',
                    padding: '10px',
                }}
            >
                <CardMedia
                    id="preview"
                    component={isVideo ? "video" : "img"}
                    src={image ? image : preview}
                    autoPlay
                    loop
                    muted
                    sx={{
                        borderRadius: '5px',
                        maxHeight: '400px',
                        maxWidth: '400px',
                        '@media (max-width: 600px)': {
                            height: '100%',
                            width: '100%',
                        }
                    }}
                />
            </Card>
        </>
    )
}

ImagePreview.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    isVideo: PropTypes.bool,
}
 
export default ImagePreview