import React, { useState, useContext, useEffect } from 'react'
import { Button, CardMedia, Input} from '@mui/material'
import styled from '@emotion/styled'
import { Box } from '@mui/system'
import { Link, NavLink, useLocation ,useNavigate} from 'react-router-dom'
//import criptoloteryLogo from 'assets/logos/new_logo.png'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { NavItem, WalletSection } from './components'
//import GamesIcon from '@mui/icons-material/Games';
import ImageIcon from '@mui/icons-material/Image';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useTranslation } from 'react-i18next'
//import borderImage from 'assets/images/border.png'
import { DrawerMobileContext } from 'hooks/DrawerMobileContext'
import OutsideClickHandler from 'react-outside-click-handler'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Context } from 'hooks/WalletContext'
//import { isPendingAll } from 'views/cryptoloterita/Blockchain/isRequestPending'
//import { fetchUserNFT } from 'views/cryptoloterita/Profile/helpers/fetchUser'
import LogoChivas from 'assets/logos/logo-chivas.svg'
import LogoProfile from 'assets/logos/logo_profile.svg'
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import StarsIcon from '@mui/icons-material/Stars';


//import { Context } from 'hooks/WalletContext';
//import LoaderCircle from 'components/LoaderCircle'
//import axios from "axios";

const CustomNavLink = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  border: none;
  background: '#fff';
  &:hover {
    color: #FAB9DB;
  }
`;



const Navbar = styled.nav`
  background-color: #CD1731;
  height: 82px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //position: fixed;
  //z-index: 10;
`;

//const activeLinkStyle = {
//  color: '#FAB9DB',
//}
let first = false;
let isFirstShowEmail = false;
let dataContext = null;
const TopBar = () => {
  const { t } = useTranslation("translate")
  const history = useNavigate();
  const {openNav, setOpenNav, openWallet, setOpenWallet} = useContext(DrawerMobileContext);
  const {data} = useContext(Context)
  const location = useLocation();

  //const [pending, setPending]=useState(false)
  const {connection}= useContext(Context)
  const [isRegisterEmail, setIsRegisterEmail] = useState(false);
  //const [notifications, setNotifications]=useState(( isNaN(parseInt(localStorage.getItem('notifications'))) == false) ? parseInt(localStorage.getItem('notifications')) : 0)
  const [checkLater, setCheckLater]=useState(false);
  const [search,setSearch] = useState('');
  const [largeSearch,setLargeSearch] = useState(false);

  

  console.log('location ::',checkLater,isRegisterEmail)
  if (!isFirstShowEmail) {
    if(localStorage.getItem('viewEmailInput')) {
      isFirstShowEmail = true
      const isDate = localStorage.getItem('checkLater')
      if(isDate){
        const date = new Date(isDate)
        const now = new Date()
        if(date.getTime() > now.getTime()){
          setCheckLater(true)
        }
      }else{
        setIsRegisterEmail((localStorage.getItem('viewEmailInput').includes('true')) ? true : false)
      }
    }
  }

  const determinateNotification = (data) => {
    if (data && data.userAccount) {
      console.log('Testing')
/*       let resource = fetchUserNFT(data.userAccount)
      const interval = setInterval( () => {
        try {
            let userNfts = resource.nfts.read()
            let notificationsNew = 0;
            if(userNfts != null) {
              console.log('userNfts ::', userNfts)
              userNfts[0].forEach(nft => {
              //console.log('notificationsNew ::', nft.requests_count )

                if(nft.requests_count > 0) {
                  notificationsNew = notificationsNew + 1
                }
              });
              //console.log('notificationsNew ::', notificationsNew)
              localStorage.setItem('notifications', JSON.stringify(notificationsNew))
              setNotifications(notificationsNew)
              clearInterval(interval)
            }
        } catch (error) {
           // console.log('Al obtener notifications', error)
        }
        //console.log("notifications::",notifications);
      }, 200) */
    }
    
  }

  const dashboardAddress = [
    '0x048D6eE510F2c52F71dfc0AD1BBD6363457C49DB',
    '0xc8b84a86cF9af59eBceEFC8B0ccaCf529E241585',
    '0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014',
    '0x88782d7d91a8b0bc9dc78f6f89d434f99ec45104',
    '0xa4B40F851D60C57c3733Caf7063E7F7820a7a79d',
    '0xc3eEdebCAb5B511262180e6B1468F47FE372f60e',
    '0xa5461cbCf9c767264CC619bCF1AF3AaD083A5b22'
  ];





  if(!first) {
    setInterval(() => {
      if (data && data.provider) {
        first = true;
        /* isPendingAll(data.provider).then((success) => {
          if(success.isPending) {
            setPending(true)
          } else {
            setPending(false)
          }
        }).catch((error) => {
          console.log('error ::', error)
        }) */
      }
    }, 2000)

  }

  if(!dataContext) {
    if (data && data.userAccount) { 
      dataContext = data
      console.log('dataContext ::', dataContext)
    }
  }

  useEffect(() => {
    let notificationInterval = setInterval(() => {
      determinateNotification(dataContext)
      const metamask = localStorage.getItem('isMetamask')
      const walletConnect = localStorage.getItem('walletConnect')
      console.log('Actualizando metamask')
      try {
        if (metamask) {
          connection('metamask')
        }
        if(walletConnect) {
          console.log('Actualizando wallet connect')
          connection('walletConnect')
        }
      } catch (error) {
        console.log(error)
      }
      // axios.get("https://api.dev.persea.app/nft?mode=UPDATE_ALL").then(()=>{})
      // axios.get("https://api.dev.persea.app/exchange/nft?filter=UPDATE_ALL_TO").then(()=>{})
    },30000)
    window.onscroll = () => {
      if (openWallet) {
        window.scrollTo(0, 0);
      }
    };
    //clearInterval(notificationInterval)
    //throw new Error('Example of error')
    return () => clearInterval(notificationInterval)
  },[openWallet])
  
  const handleOpenWallet = () => {
    setOpenWallet(!openWallet);
  }
  const handleCloseDrawer = () => {
    if(openNav || openWallet) {
      setOpenNav(false);
      setOpenWallet(false);
    }
  }



  const items = [
    {
      name: 'Mint',
      icon: ImageIcon,
      path: '/dashboard-mint/card',
      visible : (location.pathname.indexOf('dashboard') == -1) ? false : true
    },
    {
      name: t('topbar.history'),
      icon: MenuBookIcon,
      path: '/home',
      visible : (location.pathname.indexOf('dashboard') != -1) ? false : true
    },
    {
      name: t('topbar.explore'),
      icon: ImageIcon,
      path: '/explore',
      visible : (location.pathname.indexOf('dashboard') != -1) ? false : true
    },
    {
      name: t('topbar.benefits'),
      icon: StarsIcon,
      path: '/benefits',
      visible : (location.pathname.indexOf('dashboard') != -1) ? false : true
    },
    {
      name: 'Settings',
      icon: ImageIcon,
      path: '/home',
      visible : (location.pathname.indexOf('dashboard') == -1) ? false : true
    },
    {
      name: t('topbar.about'),
      icon: InfoIcon,
      path: '/about',
      visible : (location.pathname.indexOf('dashboard') != -1) ? false : true
  },
  {
      name: 'FAQS',
      icon: HelpCenterIcon,
      path: '/FAQS',
      visible : (location.pathname.indexOf('dashboard') != -1) ? false : true
  }
  ]


    const handleSearch = (route) =>{
      history.push(`/explore?SLUG=${route}`)
    }

    const handleChangeSearch = (e) =>{
      const { value } = e.target
      setSearch(value)
    }
   
    return (
        <>
        <Navbar>
            <Box
                sx={{
                    zIndex: '10',
                    marginTop: 'auto',
                    margin: 0
                }}
            >
            
              <Box 
                  sx={{
                    width:'350px',
                    height:'82px',
                    background: 'linear-gradient(70deg, #1B2635 91%, #CD1731 9%)',
                    "@media (max-width: 1280px)": {
                      width:'220px',
                      background: 'linear-gradient(70deg, #1B2635 87%, #CD1731 13%)'
                    },
                    "@media (max-width: 768px)": {
                      background: 'linear-gradient(70deg, #1B2635 85%, #CD1731 15%)'
                    },
                    "@media (max-width: 420px)": {
                      width: largeSearch ? '100px':'180px',
                      background: 'linear-gradient(70deg, #1B2635 78%, #CD1731 22%)'
                    },
                    "@media (max-width: 250px)": {
                      width: largeSearch ? '0px':'80px',
                      background: largeSearch ? 'transparent':'linear-gradient(70deg, #1B2635 75%, #CD1731 25%)'
                    }
                   
                    //backgroundImage: 'linear-gradient(to right, rgba(27, 38, 53), rgba(10.6, 14.9, 20.8))',
                  
                    //background:'linear-gradient(to top right,#1B2635 90% , #CD1731 10%)'
                  }}
                >
                  
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'space-between',
                      marginLeft:'18%',
                      "@media (max-width: 1280px)": {
                        marginLeft:'5%'
                      },
                      "@media (max-width: 768px)": {
                        marginLeft:'2%'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        height: '100%',
                        alignItems: 'center'
                      }}
                    >
                      <Link
                        style={{ 
                          textDecoration:'none',
                          color:'#fff',
                          display:'flex',
                          alignItems:'center',
                      
                          
                        }}
                        to="/home"
                      >
                        <Box
                          sx={{
                            width: '60px',
                            "@media (max-width: 768px)": {
                              width: '50px',
                            }
                          }}
                        >
                          <CardMedia 
                            component='img'
                            src={LogoChivas} 
                            alt="logo"
                            sx={{
                              width:'100%',
                              margin: 'auto',
                              cursor: 'pointer',
                              "@media (max-width: 768px)": {
                                width:'100%',
                                margin: 'none'
                              }
                            }}
                      
                          />
                        </Box>
                      </Link>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        color:'#fff',
                        marginLeft:'10px',
                        fontWeight:'bold',
                        fontSize:'17px',
                        "@media (max-width: 420px)": {
                          fontSize:'14px',
                          marginLeft:'4px',
                          display:largeSearch ? 'none' : 'flex'
                        }
                      }}
                    >
                      <Link
                        style={{ 
                          textDecoration:'none',
                          color:'#fff'
                        }}
                        to="/home"
                      >
                        CHIVAS NFTs
                      </Link>
                    </Box>

                  </Box>

                 
                  
                  
               
              </Box>

  
                 
            </Box>
            
            <Box
                alignItems='center'
                sx={{
                  display:'flex',
                  backgroundColor: '#fafafa',
                  borderRadius: '10px',
                  width: '25%',
                  marginRight: '10px',
                  transition: 'width 2s',
                  border: '1px solid #e6e6e6',
                  '@media (max-width: 768px)': {
                    width: '100%',
                  },
                  '@media (max-width: 430px)': {
                    width: '100%',
                    display: location.pathname && location.pathname.includes('/explore')  ? 'flex' : 'none',
                  }
                }}
                onFocus={() => {
                  setLargeSearch(true);
                }}
                onBlur={() => {
                  setLargeSearch(false);
                }}
            >
                <SearchIcon
                  htmlColor='#000'
                  onFocus={() => {
                    setLargeSearch(true);
                  }}
                />

                <Input 
                    inputProps={{list: 'data-search'}}
                    type='search'
                    placeholder={t('search.placeholder')}
                    autoComplete='on'
                    disableUnderline
                    sx={{
                        width:'100%',
                        'input::-webkit-calendar-picker-indicator':{
                          opacity: '0',
                        }
                    }}
                    value={search}
                    onChange={(e) => handleChangeSearch(e)}
                    onKeyUp={(e)=>{
                      const {key} = e;
                      if(key == "Enter" || key == "enter"){
                        handleSearch(search)
                      }
                     
                    }}
                    onFocus={() => {
                      setLargeSearch(true);
                    }}
                />
            </Box>
            <datalist id='data-search'>
                <option value='NFT 1' />
                <option value='NFT 2' />
            </datalist>
            <Box
                sx={{ 
                    display:'flex',
                    //marginRight:'250px',
                    '@media (max-width: 1048px)': {
                        display: 'none',
                    }
                }}
            >
                {items.map((item, index) => (item.visible &&
                (<Box 
                    key={index}
                    sx={{
                        display:'flex',
                        //display:'none',
                        alignItems:'center'
                    }}
                >
                    <CustomNavLink to={item.path}
                        style={({ isActive }) => ({
                            color: isActive ? "#fff" : "#fff",
                            background:isActive ? "#1B2635" : "transparent"
                        })}
                    >
                        <Box component='h2' sx={{boxSizing:'border-box', padding:'5px 15px'}}>
                            {item.name}
                        </Box>
                    </CustomNavLink>
                </Box>)))}
               
                {data && data.userAccount && dashboardAddress.indexOf(data.userAccount) > -1 &&
                (<Box 
                    sx={{
                      display:'flex',
                      //display:'none',
                      alignItems:'center'
                    }}
                >
                    <CustomNavLink to='/create'
                        style={isActive => ({
                            color: isActive ? "#fff" : "#fff" ,
                            background: isActive ? "#1B2635" : "transparent" ,
                        })}
                    >
                        <Box component='h2' sx={{boxSizing:'border-box', padding:'5px 15px'}}>
                            {t('topbar.create')}
                        </Box>
                    </CustomNavLink>
                    <CustomNavLink to='/admin'
                        style={isActive => ({
                            color: isActive ? "#fff" : "#fff" ,
                            background: isActive ? "#1B2635" : "transparent" ,
                        })}
                    >
                        <Box component='h2' sx={{boxSizing:'border-box', padding:'5px 15px'}}>
                            Dashboard
                        </Box>
                    </CustomNavLink>
                </Box>)}
                {data && data.userAccount && 
                <Box marginRight='20px' sx={{position:'relative'}}>
                    <Button
                        sx={{
                            marginTop: '20px',
                        }}
                    >
                        <Link to={`/profile?address=${data.userAccount}`}>
                            <NotificationsNoneIcon
                                //htmlColor="#ed2891"
                                htmlColor="#fff"
                                sx={{
                                  fontSize: '30px',
                                  //color: '#EBECF0',
                                }}
                            />
                        </Link>
                    </Button>
                    {localStorage.getItem('notifications') && parseFloat(localStorage.getItem('notifications')) > 0 &&
                    <Box sx={{position:'absolute',top:3,right:10,color:'#ed2891'}} >
                        <span>{localStorage.getItem('notifications')}</span>
                    </Box>}
                </Box>}
                {(data && data.userAccount != undefined) && 
                (<Box marginRight='20px' >
                    
                        <Link to={`/profile?address=${data.userAccount}`}>
                          
                            
                              <Box sx={{display:'flex',width:'40px',height:'100%',padding:'-10',alignItems:'center',justifyContent:'center'}}>
                                <CardMedia
                                  component='img'
                                  sx={{padding:'0px'}}
                                  src={LogoProfile}
                                />
                              </Box>
                                                     
                          
                        </Link>
                   
                </Box>)}
                <Box marginRight='20px'>
                    <Button onClick={handleOpenWallet}
                        sx={{
                            marginTop: '20px',
                        }}
                    >
                        <AccountBalanceWalletIcon
                            //htmlColor="#ed2891"
                            htmlColor="#fff"
                            sx={{
                                fontSize: '30px',
                                //color: '#EBECF0',
                            }} 
                        />
                    </Button>
                </Box>
                { //pending &&
/*                 <Box marginRight='20px'>
                    <Button onClick={handleOpenWallet}
                        sx={{
                            marginTop: '25px',
                        }}
                    >
                        <AccountBalanceWalletIcon
                            //htmlColor="#ed2891"
                            htmlColor="#fff"
                            sx={{
                                fontSize: '30px',
                                //color: '#EBECF0',
                            }} 
                        />
                    </Button>
                </Box> */
                } 
                { //pending &&
/*                 <Box sx={{display:'flex',alignItems:'center'}} marginRight='20px'>
                    <span>
                        <CircularProgress 
                            size={'1rem'}
                            sx={{
                                color: '#ED2891',
                            }}
                        />
                    </span>
                    <span>
                        <b 
                            style={{
                               fontSize: '16px',
                               marginLeft: '10px',
                               color: '#ED2891'
                            }}
                        >
                            {(t("topbar.pending"))}
                        </b>
                    </span>
                </Box> */
                }
            </Box>
            <NavItem items={items} dashboardAddress={dashboardAddress} />
        </Navbar>
      {
        window.screen.width > 1048 ? (
          <OutsideClickHandler onOutsideClick={handleCloseDrawer} >
            <WalletSection openWallet={openWallet} setOpenWallet={setOpenWallet} />
          </OutsideClickHandler>
        ) : null
      }
      </>
    )
}

export default TopBar;
