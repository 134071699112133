import { FormControl } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react'
import artist from 'utils/artist'
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';


const CustomSelect = styled('select')(() => ({
    border: '1px solid #ed2891',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '8px',
    width: '140px',
    '@media screen and (max-width: 500px)': {
        //width: '100%',
        width: '100%',
    }
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

  const CustomOption = styled('option')(() => ({
    //backgroundColor: '#FAB9DB',
    fontSize: '16px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

const DropDownArtist = ({ handleFetchAuthor }) => {
    const { t } = useTranslation("translate");
    const authorRef = React.useRef(null);
    const handleChangeAuthor = () =>{
        let value = ""+authorRef.current.value;
        if (value.includes('Sofía')) {
            value = 'Castellanos'
        }
        if (value.includes('Peña')) {
            value = 'Jorge'
        }
        if (value.includes('Kone')) {
            value = 'Kone'
        }
        if (value.includes('Cocolvu')) {
            value = 'Cocolv'
        }
        handleFetchAuthor(value);
    }
    //Testing  
    return (
        <FormControl
            variant="outlined" 
            sx={{ 
                minWidth: 150,
                //border:'1px solid#ed2891',
                borderRadius: '8px',
                outline: 'none',
                '&:hover': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                  },
                '@media screen and (max-width: 500px)': {
                    width: '100%',
                    marginBottom: '10px',
                }
            }}
        >
            <CustomSelect ref={authorRef} onChange={handleChangeAuthor}>
                <CustomOption value=''>{t("search.filter_artist")}</CustomOption>
                <CustomOption value=''>{t("search.all_artists")}</CustomOption>
                {artist.map((item) => (
                    <CustomOption key={item.id} value={item.name}>{item.name}</CustomOption>
                ))}
            </CustomSelect>
        </FormControl>
    );
}

DropDownArtist.propTypes = {
    fetchFilter: Proptypes.func.isRequired,
    setFilteredCards: Proptypes.func.isRequired,
    //setAuthor: Proptypes.func.isRequired,
    handleFetchAuthor: Proptypes.func.isRequired,
}

export default DropDownArtist;