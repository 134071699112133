import React, { lazy, Suspense,useState } from 'react'
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next'; 
import ErrorBoundary from './components/ErrorBoundary'
import ErrorMessage from 'components/ErrorMessage';
import { useFetch } from 'hooks/useFetch';
//import LoaderPage from 'components/LoaderPage';
import Details from './components/Details';
import OfferHistory from 'components/OfferHistory';
import { Alert, Box, Container, Divider, Grid } from '@mui/material';
import NavInfoLoader from 'components/SkeletonLoaders/NFT/NavInfoLoader';
import HistoryLoader from 'components/SkeletonLoaders/NFT/HistoryLoader';
import DetailsLoader from 'components/SkeletonLoaders/NFT/DetailsLoader';
//import NFTSection from './components/NFTSection';
//import TableSection from './components/TableSection';
const NFTData = lazy(() => import('./components/NFTData'));
const NavInfo = lazy(() => import('./components/NavInfo'));
const NFT = () => {
    const { t } = useTranslation("translate"); 
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const address = query.get("address")
    const tokenId = query.get("token_id")
    const [bidTx ,setBidTx] =  useState(null)
    //const blockchain = query.get("blockchain")
    const NFTurl = location.search
    const url = process.env.REACT_APP_URL_API+`/nft?address=${address}&token_id=${tokenId}&domain=${process.env.REACT_APP_DOMAIN}&limit=20&page=0&order=created&cache=true`
    const projectUrl = process.env.REACT_APP_URL_API+`/collection?address=${address}&domain=${process.env.REACT_APP_DOMAIN}&limit=20&page=0&order=created&cache=true`
    const historyUrl = process.env.REACT_APP_URL_API+`/history?domain=${process.env.REACT_APP_DOMAIN}&token_id=${tokenId}&contract_address=${address}&limit=20&page=0&order=created&cache=true`
    let {data, error, loading} = useFetch(url)
    let {data:dataProject, error:errorProject, loading:loadingProject} = useFetch(projectUrl)
    let {data:dataHistory, error:errorHistory, loading:loadingHistory} = useFetch(historyUrl)
    console.log('from nft history', url, data, projectUrl, dataHistory)

    const reloadPage = () => {
        dataProject, errorProject, dataProject = useFetch(historyUrl)
        dataHistory, errorHistory, loadingHistory = useFetch(url)
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

 
    return (
        <>
            <ErrorBoundary fallback={<ErrorMessage error={t("loading_error_component.could_not_connect_to_the_api")} />}>
                <Suspense fallback={<div></div>}>
                    {(loadingProject || loading) && <NavInfoLoader />}
                    {!!error && <ErrorMessage error={error.message} />}
                    {!!errorProject && <ErrorMessage error={errorProject.message} />}
                    {!!dataProject && !!data && 
                    <NavInfo 
                        resouceProject={dataProject[0]}
                        nft={data[0]}
                    />}
                    {bidTx &&
                    <Container maxWidth="xs" sx={{margin:'0 auto', marginBottom:'5px'}}>
                        <Alert severity="success" sx={{color:'#198754'}}>
                            
                            {t("nft-screen.bid_modal.successful_offer_text1")} {bidTx.bid} WAVAX! {t("nft-screen.bid_modal.successful_offer_text2") +'2 s '}
                            <a style={{textDecoration:'none',color:'green'}} href={`${process.env.REACT_APP_SCAN}tx/${bidTx.tx}`} target="_blank" rel="noreferrer">
                                {(bidTx.tx).substring(0,8)+ '...' +(bidTx.tx).substring(58,66)}
                            </a>
                        </Alert>
                    </Container>}
                    {data && 
                    <NFTData
                        reloadPage = {reloadPage}
                        bids = {dataHistory && dataHistory}
                        setBidTx = {setBidTx}
                        address={address}
                        tokenId={tokenId}
                        resource={data}
                        NFTurl={NFTurl}
                    />}
                    <Container maxWidth="lg" >
                   
                        <Grid container columns={{lg:12, md:12,sm:12,xs:12}} 
                            spacing={{lg:3, md:2,sm:1,xs:0}}
                            sx={{
                                width:'100%'
                            }}
                        >

                            <Grid  item lg={6} md={6} sm={12} xs={12} >
                                
                                {loading && <DetailsLoader />}
                                {error && <ErrorMessage error={error.message} />}
                                {data && 
                                <Box
                                    sx={{
                                        width:'100%'
                                    }}
                                >
                                    <h1>{t('nft_details.title')}</h1>
                                    <Divider />
                                    <Box sx={{marginTop:'2rem'}}>
                                        <Details content={data[0]} />
                                    </Box>
                                </Box>}
                            </Grid>

                            <Grid  item lg={6} md={6} sm={12} xs={12} >
                                {loadingHistory &&  <HistoryLoader />}
                                {errorHistory && <ErrorMessage error={errorHistory.message} />}
                                {dataHistory && 
                                <Box
                                    sx={{
                                        width:'100%'
                                    }}
                                >
                                    <h1>{t('trading_history.title')}</h1>
                                    <Divider />
                                    <Box sx={{marginTop:'2rem'}}>
                                        <OfferHistory content={dataHistory} />
                                    </Box>
                                </Box>}
                            </Grid>
                        </Grid>
                    </Container>
                </Suspense>
            </ErrorBoundary>
        </>
    );
}

export default NFT;
