import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { TopBar, Footer } from './components';
//import { getMaticInUSD } from 'services/getMaticInUSD'
import FloatBtn from 'components/FloatBtn';
// import ModalChangeNetwork from 'components/ModalChangeNetwork';

const LoteryMain = ({ children }) => {
  
  return (
    <>
      <TopBar />
      <Box 
        component='section' 
        sx={{
          minHeight: '100vh'
          // minHeight: window.screen.height,
          // "@media (min-width: 3840px)": {
          //   minHeight: '100vh',
          // },
          // "@media (min-width: 1920px)": {
          //   minHeight: window.screen.height,
          // }

         
        }}
      >
        {children}
      </Box>
      <Footer />
      <FloatBtn />
    </>
  );
};

LoteryMain.propTypes = {
  children: PropTypes.node,
};

export default LoteryMain;
