import React from 'react'
import { Box, CardMedia } from '@mui/material';
import animated from 'assets/logos/logo-chivas.svg'

const LoaderPage = () => {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' >
            <Box
                sx={{
                    width: '250px',
                    marginTop:'25px',
                }}
            >
                <CardMedia 
                    component='img' 
                    src={animated} 
                    alt='loading...'
                    sx={{
                     
                        dropShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                        animation: 'rotate 2s infinite linear',
                        animationDelay: '0.5s',
                        animationDirection: 'alternate',
                        animationFillMode: 'forwards',
                        animationTimingFunction: 'ease-in-out',
                        '@keyframes rotate': {
                            '50%': {
                                transform: 'rotate(-5deg)',
                            },
                            '100%': {
                                transform: 'rotate(5deg)',
                            },
                        },
                        width:'100%',
                       
                    }}
                />
            </Box>
           
        </Box>
    )
}

export default LoaderPage