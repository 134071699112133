import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import LogOut from './LogOut';
import { Context } from 'hooks/WalletContext';

import LoaderCircle from 'components/LoaderCircle';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import LogIn from './LogIn';

let drawerWidth = 400;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

export default function WalletSection({setOpenWallet, openWallet}) {
  const {t} = useTranslation("translate");
  const {data, isLoading, setIsLoading} = React.useContext(Context)
  const theme = useTheme();
  //const [open, setOpen] = React.useState(true);
  /* const handleDrawerOpen = () => {
    drawerWidth = 240
    setOpen(true);
  }; */

  const handleDrawerClose = () => {
    setOpenWallet(false);
  };

  if(data && data.userAccount){
    setIsLoading(false)
  }
  return (
    <Box 
      sx={{
        display: 'flex'
      }}
    >
      <CssBaseline />
        <Drawer
          sx={{
            width: drawerWidth,
            height: '100%',
            boxSizing: 'border-box',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              marginTop:'82px'
            },
          }}
          variant="persistent"
          anchor="right"
          open={openWallet}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <CloseIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {/* <LogOut /> */}
          {isLoading ? <LoaderCircle text={t("message_loader.loading_account")} /> :(
          <>
            {data && data.userAccount ?
              <LogIn data={data} /> :
              <LogOut />
            }
          </>
          )}

      </Drawer>
    </Box>
  );
}

WalletSection.propTypes = {
  setOpenWallet: PropTypes.func,
  openWallet: PropTypes.bool
};

