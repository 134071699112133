import React, { useState } from 'react'
import { Box, Container } from '@mui/material'
import TabSelector from 'components/TabSelector';
import UserRegisteredTab from './components/UserRegisteredTab';
import AuctionSaleTab from './components/AuctionSaleTab';


const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState(1)
    const initialState = [
        {
            name: 'Usuarios registrados',
            active: true,
            number: 1,
        },
        {
            name: 'Ventas/Subastas',
            active: false,
            number: 2,
        }
    ]
    return (
        <Box
        sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }}>
            <Box component='h1'>Dashboard</Box>
            <Container>
                <TabSelector items={initialState} setUpdate={setActiveTab} showSelector={false} />
                <Box 
            sx={{
                marginBottom:'2rem'
            }}>
                { activeTab == 1 &&  <UserRegisteredTab />}
                { activeTab == 2 &&  <AuctionSaleTab />}

            </Box>
            </Container>
        </Box>
    )
}

export default AdminDashboard