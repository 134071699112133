export const collections = [
  {
    "project_key": "0x69F3138e5B3849954BC4AdA87bfD88E5b67DD564",
    "project_key_format": "0x69...D564",
    "user_key": "0x01...9014",
    "thumb_url": "https://persea.mypinata.cloud/ipfs/Qmef2PwqzPrBnT75KVnEpG6ZZKjQVpfJDXo9CefmBkoR8b",
    "thumb_url_big": "https://persea.mypinata.cloud/ipfs/Qmb7G3wGA4Gonntfw1vgbW2VEKPsJjezyvJxLwVRduuJqp",
    "transaction": "0x69F3138e5B3849954BC4AdA87bfD88E5b67DD564",
    "domain": "artcrypted.com",
    "only_domain": true,
    "imported": true,
    "name": "Train in Railway",
    "description": "test!",
    "nfts": [],
    "categories": [],
    "tags": [],
    "banner_url": "",
    "offers": [],
    "owners": [],
    "comments": [],
    "is_video": false,
    "blockchain": {
    "name": "AVALANCHE FUJI ARTCRYPTED",
    "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
    "chainId": "0xA869",
    "symbol": "AVAX",
    "decimals": 18,
    "explorer": "https://testnet.snowtrace.io/",
    "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
    "testnet": false
    },
    "user": {
    "registered": true,
    "balances": [],
    "games": [],
    "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
    "domain": "artcrypted.com",
    "username": "0x016b76...9014",
    "email": "",
    "about": "",
    "twitter": "",
    "homepage": "",
    "telegram": "",
    "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
    "theme": 1,
    "expert": false,
    "first_seen": "2022-04-08 18:45:35.178152",
    "last_seen": "2022-04-08 18:45:35.178152",
    "role": 1,
    "followers": 778
    },
    "created": 1649352970.81766
    },
    {
      "project_key": "0x69F3138e5B3849954BC4AdA87bfD88E5b67DD564",
      "project_key_format": "0x69...D564",
      "user_key": "0x01...9014",
      "thumb_url": "https://persea.mypinata.cloud/ipfs/Qmef2PwqzPrBnT75KVnEpG6ZZKjQVpfJDXo9CefmBkoR8b",
      "thumb_url_big": "https://persea.mypinata.cloud/ipfs/Qmb7G3wGA4Gonntfw1vgbW2VEKPsJjezyvJxLwVRduuJqp",
      "transaction": "0x69F3138e5B3849954BC4AdA87bfD88E5b67DD564",
      "domain": "artcrypted.com",
      "only_domain": true,
      "imported": true,
      "name": "Train in Railway",
      "description": "test!",
      "nfts": [],
      "categories": [],
      "tags": [],
      "banner_url": "",
      "offers": [],
      "owners": [],
      "comments": [],
      "is_video": false,
      "blockchain": {
      "name": "AVALANCHE FUJI ARTCRYPTED",
      "rpc": "https://api.avax-test.network/ext/bc/C/rpc",
      "chainId": "0xA869",
      "symbol": "AVAX",
      "decimals": 18,
      "explorer": "https://testnet.snowtrace.io/",
      "icon": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_xs": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_sm": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "icon_l": "https://persea.mypinata.cloud/ipfs/https://api.avax-test.network/ext/bc/C/rpc",
      "testnet": false
      },
      "user": {
      "registered": true,
      "balances": [],
      "games": [],
      "wallet": "0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014",
      "domain": "artcrypted.com",
      "username": "0x016b76...9014",
      "email": "",
      "about": "",
      "twitter": "",
      "homepage": "",
      "telegram": "",
      "banner_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
      "profile_pic_url": "https://persea.mypinata.cloud/ipfs/QmTNWVRWDSf73TNJbge6aLaPiCgMFXaW8rwz2Qu2dSjDuj",
      "theme": 1,
      "expert": false,
      "first_seen": "2022-04-08 18:45:35.178152",
      "last_seen": "2022-04-08 18:45:35.178152",
      "role": 1,
      "followers": 778
      },
      "created": 1649352970.81766
      }
]