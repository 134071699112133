import axios from "axios";

export const getUser = async(address, message = null,signature = null) => {
    const blockchain = process.env.REACT_APP_POLYGON_NETWORK_NAME;
    let url = process.env.REACT_APP_URL_API+`/user?domain=${process.env.REACT_APP_DOMAIN}&wallet=${address}&blockchain=${blockchain}&limit=15&page=0`
    //if (message && signature) {
    //    url+=`&message=${message}&signature=${signature}`
    console.log(message,signature)
    //}
    return axios.get(url)
    .then(res=>res.data[0])
}