import React from 'react';
import { Box } from '@mui/material';
import { BiError } from 'react-icons/bi';
import PropTypes from 'prop-types';

const ShowError =({error}) => {
    return(
        <>
            <Box
                sx={{
                    textAlign: 'center',
                    marginTop: '2rem',
                    color: '#FF0000',
                    fontWeight:600,
                    fontSize: '1.2rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <span>
                    <BiError size={50} />
                </span>
                <h2>{error}</h2>
            </Box>
        </>
    )
}

ShowError.propTypes = {
    error: PropTypes.string.isRequired
}

export default ShowError;