import React from 'react'
import styled from '@emotion/styled'
import { Divider, Box, CardMedia ,Link} from '@mui/material'

import './styles.css'
//logos chivas
import LogoChivas from 'assets/logos/logo-chivas.svg'
import PowerByPersea from 'assets/logos/persea-powered-white.png'
import PowerByAvalanch from 'assets/logos/avalanche_new_logo.png'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import  { useTranslation } from 'react-i18next';

const FooterSectionContent = styled.footer`
  background-color:#1B2635;
  box-sizing: border-box;
`;




const Footer = () => {
    const { t } = useTranslation("translate");
    return (
        <FooterSectionContent>
                <React.Fragment>
                    <Box 
                        sx={{width:'100%',height:'64px',background:'#CD1731',marginTop:'30px'}}
                    >
                    </Box>
                    <Box sx={{padding:'10px'}}>

         
                        <Box
                            sx={{
                                width:'100%',
                                backgroundColor: '#1B2635',
                            }}
                        >   
                            <Box
                                sx={{
                                    display: 'flex',
                                    width:'100%',
                                    height:'auto',
                                    padding:'10px',
                                    justifyContent: 'center'
                                }}
                            >   
                                <Link 
                                    sx={{
                                        textDecoration:'none',
                                        color:'#fff',
                                        marginTop:'35px',
                                        width:'6%',
                                        '@media (max-width: 768px)': {
                                            width:'23%',
                                        }
                                        
                                    }}
                                    href="https://www.chivasdecorazon.com.mx/es"
                                >
                                    <CardMedia
                                        component='img'
                                        src={LogoChivas}
                                        
                                    />
                                </Link>
                            </Box>
                            <Box
                            >
                                <center>
                                    <h3 
                                    >
                                        <Link 
                                            sx={{
                                                textDecoration:'none',
                                                color:'#fff',
                                                fontFamily:`'Titillium Web', sans-serif`,
                                                fontWeight:'300',
                                                fontSize:'30px',
                                                '@media (max-width: 768px)': {
                                                    fontSize:'20px',
                                                }
                                            }}
                                            href="https://www.chivasdecorazon.com.mx/es"
                                        >
                                            Chivasdecorazon.com.mx
                                        </Link>
                                    </h3>
                                </center>
                            </Box>
                            
                            <Divider color='#fff'/>

                            <Box
                                sx={{width:'100%', marginTop:'15px',marginBottom:'15px',height:150}}
                            >

                                <Box sx={{width:'62%',margin:'0 auto'}}>
                                    <Box sx={{display:'flex',width:'100%',marginTop:'35px',marginBottom:'35px',justifyContent:'space-around'}}>

                                        <Box sx={{display:'flex',height:'70px',alignItems:'center'}}>
                                            <center>
                                                <Link 
                                                    sx={{textDecoration:'none',color:'#fff'}}
                                                    href="https://www.facebook.com/Chivas/"
                                                >
                                                    <FacebookIcon 
                                                        sx={{
                                                            fontSize:'70px',
                                                            '@media (max-width: 768px)': {
                                                                fontSize:'30px',
                                                            }
                                                        }}
                                                        htmlColor='#fff'
                                                    />
                                                </Link>
                                            </center>
                                        </Box>

                                        <Box sx={{display:'flex',height:'70px',alignItems:'center',alignContent:'space-around'}}>
                                            <center>
                                                <Link 
                                                    sx={{textDecoration:'none',color:'#fff'}}
                                                    href="https://www.instagram.com/chivas/"
                                                >
                                                    <InstagramIcon 
                                                        sx={{
                                                            fontSize:'70px',
                                                            '@media (max-width: 768px)': {
                                                                fontSize:'30px',
                                                            }
                                                        }}
                                                        htmlColor='#fff'/>
                                                </Link>
                                            </center>
                                        </Box>

                                        <Box sx={{display:'flex',height:'70px',alignItems:'center'}}>
                                            <center>
                                                <Link 
                                                    sx={{textDecoration:'none',color:'#fff'}}
                                                    href="https://www.youtube.com/user/chivas"
                                                >
                                                    <YouTubeIcon 
                                                        sx={{
                                                            fontSize:'80px',
                                                            '@media (max-width: 768px)': {
                                                                fontSize:'38px',
                                                            }
                                                        }}
                                                        htmlColor='#fff'
                                                    />
                                                </Link>
                                            </center>
                                        </Box>

                                        <Box sx={{display:'flex',height:'70px',alignItems:'center'}}>
                                            <center>
                                                <Link 
                                                    sx={{textDecoration:'none',color:'#fff'}}
                                                    href="https://www.tiktok.com/@chivas"
                                                >
                                                     <CardMedia
                                                        component='img'
                                                        sx={{
                                                            width:'160px',
                                                            '@media (max-width: 768px)': {
                                                                width:'80px',
                                                            }
                                                        }}
                                                        src={"https://www.chivasdecorazon.com.mx/assets/images/icons/tikTok.svg"}
                                                    />
                                                </Link>
                                            </center>
                                        </Box>

                                        <Box sx={{display:'flex',height:'70px',alignItems:'center'}}>
                                            <center>
                                                <Link 
                                                    sx={{textDecoration:'none',color:'#fff'}}
                                                    href="https://www.linkedin.com/company/chivas/"
                                                >
                                                     <LinkedInIcon 
                                                        sx={{
                                                            fontSize:'80px',
                                                            '@media (max-width: 768px)': {
                                                                fontSize:'38px',
                                                            }
                                                        }}
                                                        htmlColor='#fff'
                                                    />
                                                </Link>
                                            </center>
                                        </Box>
                                    
                                    </Box>

                                    <Box sx={{marginTop:'15px',marginBottom:'15px',display:'flex',width:'100%',justifyContent:'space-around',color:'#fff',
                                        '@media (max-width: 768px)': {
                                            marginTop:'-15px'
                                        }
                                        }}
                                    >

                                        <Box 
                                            sx={{
                                                '@media (max-width: 768px)': {
                                                    padding:'10px'
                                                }
                                            }}
                                        >
                                            <b>
                                                <Link underline="hover" 
                                                    color='#fff'
                                                    sx={{
                                                        fontSize:'24px',
                                                        "&:hover":{color:'#CD1731'},
                                                        '@media (max-width: 768px)': {
                                                            fontSize:'15px',
                                                        }
                                                    }} 
                                                    href="https://estadioakron.mx/"
                                                >
                                                    {t('chivas_footer.stadium')}
                                                </Link>
                                            </b>
                                        </Box>

                                        <Box sx={{width:'4px',height:'30px',background:'#CD1731'}}>                                       
                                        </Box>

                                        <Box
                                            sx={{
                                                '@media (max-width: 768px)': {
                                                    padding:'10px'
                                                }
                                            }}
                                        >
                                            <b>
                                                <Link underline="hover" 
                                                    color='#fff'
                                                    sx={{
                                                        fontSize:'24px',
                                                        "&:hover":{color:'#CD1731'},
                                                        '@media (max-width: 768px)': {
                                                            fontSize:'15px',
                                                        }
                                                    }} 
                                                    href="https://www.chivasdecorazon.com.mx/es"
                                                >
                                                    CHIVASTV
                                                </Link>
                                            </b>
                                        </Box>

                                        <Box sx={{width:'4px',height:'30px',background:'#CD1731'}}>                                     
                                        </Box>

                                        <Box
                                            sx={{
                                                '@media (max-width: 768px)': {
                                                    padding:'10px'
                                                }
                                            }}
                                        >
                                            <b>
                                                <Link underline="hover" 
                                                    color='#fff'
                                                    sx={{
                                                        fontSize:'24px',
                                                        "&:hover":{color:'#CD1731'},
                                                        '@media (max-width: 768px)': {
                                                            fontSize:'15px',
                                                        }
                                                    }} 
                                                    href="https://www.chivasdecorazon.com.mx/es/contenidos/privacidad"
                                                >
                                                    {t('chivas_footer.advice')}
                                                </Link>
                                            </b>
                                        
                                        </Box>

                                        <Box 
                                            sx={{
                                                width:'4px',
                                                height:'30px',
                                                background:'#CD1731',
                                            }}
                                        >                                     
                                        </Box>

                                        <Box
                                            sx={{
                                                '@media (max-width: 768px)': {
                                                    padding:'10px'
                                                }
                                            }}
                                        >
                                            <b>
                                                <Link underline="hover" 
                                                    color='#fff'
                                                    sx={{
                                                        fontSize:'24px',
                                                        "&:hover":{color:'#CD1731'},
                                                        '@media (max-width: 768px)': {
                                                            fontSize:'15px',
                                                        }
                                                    }} 
                                                    href="https://www.chivasdecorazon.com.mx/es/contenidos/aviso-legal"
                                                >
                                                    {t('chivas_footer.terms')}
                                                </Link>
                                            </b> 
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                            
                            <Divider color='#fff'/>

                            <Box
                                sx={{marginTop:'15px',marginBottom:'15px',width:'100%',display:'flex',justifyContent:'center'}}
                            >   
                                <Box 
                                    sx={{
                                        width:'180px',
                                        marginRight:'5px',
                                        '@media (max-width: 768px)': {
                                            width:'90px'  
                                        }
                                    }}
                                >
                                    <Link  href="https://persea.avocadoblock.com/">
                                        <CardMedia
                                            component='img'
                                            sx={{
                                                width:'100%',
                                            }}
                                            src={PowerByPersea}
                                        />
                                    </Link>
                                </Box>

                                <Box
                                    sx={{
                                        width:'180px',
                                        marginLeft:'18px',
                                        marginTop:'5px',
                                        '@media (max-width: 768px)': {
                                            width:'100px'  
                                        }
                                    }}
                                >
                                    <Link  href="https://www.avax.network/">
                                        <CardMedia
                                            component='img'
                                            sx={{width:'100%'}}
                                            src={PowerByAvalanch}
                                        />
                                    </Link>
                                </Box>
                            </Box>


                        </Box>
                        
                        
                    </Box>
                </React.Fragment>

        </FooterSectionContent>
    );
};

export default Footer;
