import WalletConnectProvider from "@walletconnect/web3-provider";
import web3Provider from 'services/web3'

const WalletConnect = async() => {
    try{
        const provider = new WalletConnectProvider({
            infuraId: "4f3f9725c268439db4aee88df5fe4caf",
            //rpc: {137: "https://matic-mainnet.chainstacklabs.com"}
            //chainId: 137
            //chainId: 56,
            //chainId: 137,
            //chainId: 80001
        });
        await provider.enable();
        return new Promise((resolve, reject) => {
            try{
                localStorage.setItem('walletConnect', true)
                localStorage.setItem('wallet', true)
                web3Provider(provider).then(res => {
                    resolve(res)
                })
                provider.on('chainChanged', () => {
                    window.location.reload()
                });
                provider.removeListener('chainChanged', () => {
                    (web3Provider(provider).then(x=>{
                        if(x === undefined){
                            localStorage.removeItem('walletConnect')
                            localStorage.removeItem('walletconnect')
                            localStorage.removeItem('network')
                            localStorage.removeItem('wallet')
                        }
                        window.location.reload()
                    }))
                });
                provider.on('accountsChanged', () => {
                    (web3Provider(provider).then(x=>{
                        if(x === undefined){
                            localStorage.removeItem('walletConnect')
                            localStorage.removeItem('walletconnect')
                            localStorage.removeItem('network')
                            localStorage.removeItem('wallet')
                        }
                        window.location.reload()
                    }))
                });
                provider.removeListener('accountsChanged', () => {
                    window.location.reload()
                });
                provider.on('disconnect', () => {
                    alert ('deisconnected!')
                    localStorage.removeItem('walletConnect')
                    localStorage.removeItem('walletconnect')
                    localStorage.removeItem('network')
                    localStorage.removeItem('wallet')
                    window.location.reload()
                });
                provider.removeListener('disconnect', () => {
                    alert ('deisconnected!')
                    localStorage.removeItem('walletConnect')
                    localStorage.removeItem('walletconnect')
                    localStorage.removeItem('network')
                    localStorage.removeItem('wallet')
                    window.location.reload()
                });
            }catch (error){
                localStorage.removeItem('walletConnect')
                localStorage.removeItem('walletconnect')
                localStorage.removeItem('wallet')
                localStorage.removeItem('network')
                console.log(error)
                console.error(error)
                //window.location.reload()
                reject(error)
            }
        })
    }catch (error) {
        console.error(error)
        localStorage.removeItem('walletConnect')
        localStorage.removeItem('walletconnect')
        localStorage.removeItem('wallet')
        localStorage.removeItem('network')
        console.log(error)
        console.error(error)
        window.location.reload()
        //reject(error)
    }
}

export default WalletConnect