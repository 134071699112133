import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Grid,Box,TableContainer} from '@mui/material';
import TableFilter from './components/TableFilter';
//import { exist } from 'layouts/LoteryMain/components/Topbar/components/WalletSection/User/valieteExist';

const Offers  = ({user,data,address}) =>{
    const {t} = useTranslation("translate");

    const [movement1,setMovement1] = React.useState([]);
    const [movement2,setMovement2] = React.useState([]);

    const validIfExpiration = (timelive,created) => {

        const dateFormated = new Date(created);
        const today = new Date().getTime();
        if(Number(today) > (Number(dateFormated.getTime()) + Number(timelive))){
          return false
        }else{
          return true
        }
    }

   
    React.useEffect(()=>{
        if(user && user.offers){
            setMovement1(user.offers.filter(m=> validIfExpiration(m.timelive,m.created_at) && (m.from+'').toUpperCase() == (address).toUpperCase()) )
            setMovement2(user.offers.filter(m=> validIfExpiration(m.timelive,m.created_at) && (m.to+'').toUpperCase() == (address).toUpperCase()) )
        }
    },[])


    return (
        <Box sx={{display:'none'}}>
          
        <Grid container rowSpacing={1}  spacing={{ xs: 2, md: 2, lg: 2 }} columns={{xs: 12, sm: 12,md:12, lg:12 }} >
            
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <center><h3>{t("profile.offers_table.offers_made_title_table")} </h3></center>
                <center>
                <TableContainer sx={{marginBottom:'25px',maxWidth:750}}>
                    <TableFilter setArrOffers={setMovement1} movements={movement1} setMovements={setMovement1} data={data} address={address} show="to" />   
                </TableContainer>              
                </center>
            </Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <center><h3>{t("profile.offers_table.offers_received_title_table")}</h3></center>
                <center>
                <TableContainer sx={{marginBottom:'25px',maxWidth:750}}>
                    <TableFilter setArrOffers={setMovement2} movements={movement2} setMovements={setMovement2} data={data} address={address} show="from" />   
                </TableContainer>  
                </center>
            </Grid>
            
       
        </Grid>
        
        </Box>
    )
};


Offers.propTypes = {
    user:PropTypes.array,
    data:PropTypes.object,
    address:PropTypes.string
};

export default Offers ;
