import detectEthereumProvider from '@metamask/detect-provider';
import web3Provider from 'services/web3'
import Web3 from 'web3';

const metamask = async() => {
    try {
        const provider = await detectEthereumProvider()
        await provider.request({ method: 'eth_requestAccounts' });
        if(provider){
            return new Promise((resolve, reject) => {
                try{
                    localStorage.setItem('isMetamask', true)
                    localStorage.setItem('wallet', true)
                    web3Provider(provider).then(res => {
                        resolve(res)
                    })
                    provider.on('accountsChanged', () => {
                        (web3Provider(provider).then(x=>{
                            if(x === undefined){
                                localStorage.removeItem('isMetamask')
                                localStorage.removeItem('network')
                                localStorage.removeItem('wallet')
                            }
                            window.location.reload()
                        }))
                    });
                    provider.removeListener('accountsChanged', () => {
                        (web3Provider(provider).then(x=>{
                            if(x === undefined){
                                localStorage.removeItem('isMetamask')
                                localStorage.removeItem('network')
                                localStorage.removeItem('wallet')
                            }
                            window.location.reload()
                        }))
                    });
                    provider.on('chainChanged', () => {
                        window.location.reload()
                    });
                    provider.removeListener('chainChanged', () => {
                        (web3Provider(provider).then(x=>{
                            if(x === undefined){
                                localStorage.removeItem('isMetamask')
                                localStorage.removeItem('network')
                                localStorage.removeItem('wallet')
                            }
                            window.location.reload()
                        }))
                    });
                }catch(err){
                    localStorage.removeItem('isMetamask')
                    localStorage.removeItem('wallet')
                    localStorage.removeItem('network')
                    console.log(err)
                    console.error(err)
                    //window.location.reload()
                    reject(err)
                }
            })
        }else{
            alert('Metamask is not installed')
        }
        
    } catch (error) {
        console.log('Error metmaskconnection', error)
    }

}

const switchEthereumChain = async(network) => {
    if (window?.ethereum) {
        try {
          if (network === 0) return;
            console.log('network ::',network)
            if (process.env.REACT_APP_POLYGON_NETWORK == network) {
                console.log('hex ::', Web3.utils.toHex(process.env.REACT_APP_POLYGON_NETWORK))
                let provider = {
                        chainId: Web3.utils.toHex(process.env.REACT_APP_POLYGON_NETWORK),
                        chainName: process.env.REACT_APP_POLYGON_NETWORK_NAME_2,
                        nativeCurrency: {
                        name: 'AVAX',
                        symbol: 'AVAX',
                        decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_RPC],
                    blockExplorerUrls:[process.env.REACT_APP_SCAN]
                }
                await window?.ethereum.request({method: "wallet_addEthereumChain", params: [provider]})
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            } else {
                await window?.ethereum?.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: Web3.utils.toHex(network) }],
                });
            }
        } catch (error) {
            console.log('Error ::', error);
            //alert('Error to change network')
        }
    }
}

export {switchEthereumChain}
export default metamask