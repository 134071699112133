import React from 'react';
import { Avatar, Box} from '@mui/material';
//import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';



const Header = ({formEditProfile,user,address,data,handleActiveEditProfile,editProfile,initEditProfile}) => {

    console.log(data,handleActiveEditProfile,initEditProfile);
   

  
    console.log('user data ::', user,editProfile)
    return (
        <>
            <Box
                sx={
                    {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#e3e3e3',
                        height: '300px',
                        position:'relative',
                    }
                }
            />
            {formEditProfile.banner != '' &&
                <img src={formEditProfile.banner} height='300px' width='100%' style={{position:'absolute',top:0}} />
            }
            {user  && user.banner_url != '' && formEditProfile.banner == '' &&
                <img src={user.banner_url} height='300px' width='100%' style={{position:'absolute',top:0}} />
            }
           
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}
            >
                <Box>
                    <Box sx={{position:'relative'}}> 
                    { user ?
                        (
                        user &&  user.profile_pic_url ?
                        <Avatar
                            alt={user.profile_pic_url}
                            src={formEditProfile.avatar == '' ? user.profile_pic_url : formEditProfile.avatar}
                            sx={{ width: 120, height: 120, marginTop: '-60px',border:'5px solid #fff'}}/>
                        :
                        <Avatar
                            alt="Avatar"
                            src={formEditProfile.avatar != '' && formEditProfile.avatar}
                            sx={{ width: 120, height: 120, marginTop: '-60px',border:'5px solid #fff' }}
                        />
                        )
                        :
                        <Avatar
                            alt="Avatar"
                            src={'/static/images/avatar/1.jpg'}
                            sx={{ width: 120, height: 120, marginTop: '-60px',border:'5px solid #fff' }}
                        />
                    }
                    
                    </Box>        
                </Box>
            </Box>
            <Box 
                sx={{
                    textAlign:'center', 
                    marginTop:'-15px'
                }}
            >   
                {
                    formEditProfile && formEditProfile.username && formEditProfile.name != '' &&
                    <h2>{formEditProfile.username}</h2>
                }
               
             </Box>
            <Box sx={{textAlign:'center',marginTop:'30px'}}>
                <h3 style={{marginTop:'-20px', color:'#707A83'}}>
                    <a href={`${process.env.REACT_APP_SCAN}address/${address}`} style={{textDecoration:'none',color:'#000'}} target="_blank" rel="noreferrer">{(address).substring(0,5)+ '...' +(address).substring(38,54)}</a>
                </h3>
            </Box>
        </>
    )
}

Header.propTypes = {
    formEditProfile: PropTypes.object,
    user: PropTypes.object,
    address: PropTypes.string,
    data: PropTypes.object,
    handleActiveEditProfile: PropTypes.func,
    editProfile: PropTypes.bool,
    initEditProfile: PropTypes.bool
}


export default Header;
