import React, { useState } from 'react'
import { Box, Checkbox, Collapse, Input } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import ButtonStyled from 'components/ButtonStyled'
import CheckBoxFilters from 'components/Form/CheckBoxFilters';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';


const styleBox = {
    gap:'1rem',
    marginBottom:'0.5rem',
    border: '1px solid #1B2635',
    borderRadius:'8px',
    boxSizing:'border-box',
    cursor:'pointer',
    padding:'0px 1rem',
    '&:hover':{
        border: '1px solid #1B2635',
    }
 }

const SortNFTs = ({filters,setFilters,handleRequestWithFilters}) => {
    const { t } = useTranslation("translate");
    const [rangeBottom, setRangeBottom] = useState('')
    const [rangeTop, setRangeTop] = useState('')
    const [range, setRange] = useState(true)
    const [aviable, setAviable] = useState(false)
    const [type, setType] = useState(false)




    const handleClickSetPriceRange = () => {
        handleRequestWithFilters([
            { key_name : 'PRICE_RANGE_BOTTOM' , key_val : rangeBottom},
            { key_name : 'PRICE_RANGE_TOP' , key_val : rangeTop}
        ], true);
    }

    const handleClickBuyNow = (value) => {
        console.log('fil value ::',value)
        handleRequestWithFilters([
            { key_name : 'BUY_NOW' , key_val : 1},
        ], value);
    }

    const handleClickLiveAuction= (value) => {
        console.log('fil value ::',value)
        handleRequestWithFilters([
            { key_name : 'LIVE_AUCTION' , key_val : 1},
        ], value);
    }

    const handleClickOnlyVideo= (value) => {
        console.log('fil value ::',value)
        handleRequestWithFilters([
            { key_name : 'ONLY_VIDEO' , key_val : 1},
        ], value);
    }

    const handleClickOnlyImage= (value) => {
        console.log('fil value ::',value)
        handleRequestWithFilters([
            { key_name : 'ONLY_IMAGE' , key_val : 1},
        ], value);
    }

    return (
        <Box 
            sx={{
                position:'sticky',
                top: 0,
                '@media screen and (max-width: 768px)':{
                    position:'block',
                }
            }}
        >
            <Box
                component='article'
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                sx={{
                    borderBottom:'1px solid #e0e0e0',
                }}   
            >
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        width:'100%',
                    }}
                >
                    <h1>{t("sidebar_component.price_range")}</h1>
                    <span onClick={()=>setRange(!range)}><AddIcon sx={{cursor:'pointer'}} /></span>
                </Box>
                <Collapse in={range} timeout="auto" unmountOnExit>
                    <Box display='flex' justifyContent='space-between' sx={{gap:'40px', marginBottom:'25px'}}>
                        <Input 
                            type='number' 
                            placeholder='0.00' 
                            disableUnderline
                           // value={filters && filters.length > 1 && filters[1].priceRange && filters[1].priceRange.min.keyValue && filters[1].priceRange.min.keyValue >= 0 ? filters[1].priceRange.min.keyValue : '' }
                            name="rangeMin"
                            value = {rangeBottom}
                            onChange={(e)=>{setRangeBottom(e.target.value)}}
                            sx={{
                                border: '1px solid #e0e0e0',
                            }}
                        />
                        <Input 
                            type='number' 
                            placeholder='0.00' 
                            //value={filters && filters.length > 1 && filters[1].priceRange && filters[1].priceRange.max.keyValue && filters[1].priceRange.max.keyValue >= 0 ? filters[1].priceRange.max.keyValue : '' }
                            disableUnderline
                            name="rangeMax"
                            value = {rangeTop}
                            onChange={(e)=>{setRangeTop(e.target.value)}}
                            sx={{
                                border: '1px solid #e0e0e0',
                            }}
                        />
                    </Box>
                    <Box
                        sx={{marginBottom:'15px'}}
                    >
                        <ButtonStyled
                            onClick={handleClickSetPriceRange}
                            text={t("sidebar_component.set_price")} 
                            width='90%'
                        />
                    </Box>
                </Collapse>
            </Box>
            <Box
                component='article'
                display='flex'
                flexDirection='column'
                sx={{
                    borderBottom:'1px solid #e0e0e0',
                }}   
            >
                <Box
                    component='article'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'  
                >
                    <h1>{t("sidebar_component.availability")}</h1>
                    <span onClick={()=>setAviable(!aviable)}><AddIcon sx={{cursor:'pointer'}} /></span>
                </Box>
                <Collapse in={aviable} timeout="auto" unmountOnExit>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                                <CheckBoxFilters
                                    name='buyNow'
                                    value={filters}
                                    setValue={setFilters}

                                    handleRequestWithFilters={handleClickBuyNow}
                                />
                                <h1>{t("sidebar_component.buy_now")}</h1>
                        </Box>
{/*                         <Box>
                            19,624
                        </Box> */}
                    </Box>
                    
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                                <CheckBoxFilters
                                    name='liveAuction'
                                    value={filters}
                                    setValue={setFilters}

                                    handleRequestWithFilters={handleClickLiveAuction}
                                />
                            <h1>{t("sidebar_component.live_auction")}</h1>
                        </Box>
{/*                         <Box>
                            165
                        </Box> */}
                    </Box>
                </Collapse>
            </Box>
            
            <Box
                component='article'
                display='flex'
                flexDirection='column'
                sx={{
                    borderBottom:'1px solid #e0e0e0',
                }}   
            >
                <Box
                    component='article'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'  
                >
                    <h1>{t("sidebar_component.type")}</h1>
                    <span onClick={()=>setType(!type)}><AddIcon sx={{cursor:'pointer'}} /></span>
                </Box>
                <Collapse in={type} timeout="auto" unmountOnExit>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                onChange={(e)=>{ handleClickOnlyImage(e.target.checked)}}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1>{t("sidebar_component.image")}</h1>
                        </Box>
{/*                         <Box>
                            156,563
                        </Box> */}
                    </Box>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                onChange={(e)=>{ handleClickOnlyVideo(e.target.checked)}}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1>{t("sidebar_component.video")}</h1>
                        </Box>
{/*                         <Box>
                            69,783
                        </Box> */}
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}


SortNFTs.defaultProps = {
    filters: [{'':''}],
    setFilters:    () => {},
    handleRequestWithFilters: () => {}
}

SortNFTs.propTypes = {
    filters: PropTypes.array,
    setFilters: PropTypes.func,
    handleRequestWithFilters: PropTypes.func
}

export default SortNFTs