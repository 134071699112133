import React from 'react'
import { createContext, useEffect, useState } from 'react'
import getConnection from 'services/connection'
import PropTypes from 'prop-types'

const defaultState = {
    userAccount: undefined,
    balance: '0.000',
    network: 'disconnected',
    chainID: 'NaN',
    provider: null,
    maticBalance: '0.00',
    viewEmailInput : false,
    user : null,
    sign_exchange : false,
    formatWMaticBalance: '0.00',
}
export const Context = createContext(defaultState)

const WalletProvider = ({children})=>{
    const [data, setData]=useState(defaultState)
    const [isLoading, setIsLoading] = useState(false);
    const connection =(wallet)=>{
        getConnection(wallet).then(res=>setData(res))
    }
    useEffect(()=>{
        const net = localStorage.getItem('network')
        const metamask = localStorage.getItem('isMetamask')
        const walletConnect = localStorage.getItem('walletConnect')
        if(net && metamask){
            setIsLoading(true)
            connection('metamask')
        }
        if(net && walletConnect){
            setIsLoading(true)
            connection('walletConnect')
        }
    },[])
    return(
        <Context.Provider value={{data, connection, isLoading, setIsLoading, setData}}>
            {children}
        </Context.Provider>
    )
}

WalletProvider.propTypes = {
    children: PropTypes.node
}

export default WalletProvider