import React, { useRef, useState } from 'react'
import { Box, Button, Container, Grid } from '@mui/material'
import ImagePreview from 'components/ImagePreview';
import ButtonStyled from 'components/ButtonStyled';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import styled from '@emotion/styled'
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Context } from 'hooks/WalletContext';
//import axios from 'axios';
import { useNavigate } from "react-router";
import { isVideo } from 'services/Utils/validateFileFormat';
import axios from 'axios';
import { sign } from 'services/Utils/signature';
import { mint } from 'services/ERC721/mint';
import LoaderModal from 'components/LoaderModal';
import { useTranslation } from 'react-i18next';
//import { Link } from 'react-router-dom'
const attributesValidationSchema = Yup.object().shape({
    name: Yup.string().required('name required'),
    type: Yup.string().required('value required'),
});

const mintSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required'),
    description: Yup.string()
        .required('Description is required'),
    attributes: Yup.array().of(attributesValidationSchema),
    image: Yup.mixed()
        .nullable()
        .notRequired()
        .test("FILE_SIZE", "Uploaded file is too big.",
            value => !value || (value && value.size <= 20900000000)),
    termsAndConditions: Yup.boolean()
        .required('The terms and conditions must be accept')
        .oneOf([true],'The terms and conditions must be accept')
});

const UploadFile = styled.input`
    display: none;
`;

const TextField = styled(Field)`
    width:100%;
    border-radius:8px;
    border:1px solid #E5E5E5;
    padding:10px;
    font-size: 16px;
`;

const TextArea = styled(Field)`
    width: 100%;
    height: 150px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
`;

const MintNFT = () => {
    const {t} = useTranslation("translate");
    const {data} = React.useContext(Context)
    const [preview, setPreview] = useState('');
    const hiddenFileInput = useRef(null);
    const query = new URLSearchParams(location.search)
    const history = useNavigate()
    const cancel = () => {
        history.push('/home')
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        let formData = new FormData();
        formData.append("files", file);
        reader.onload = () => {
            let preview = {
                src : reader.result,
                isVideo : isVideo(e.target.value),
                data : formData,
                format : file.type.split('/')[1],
                resolution: ''
            }
            setPreview(preview);
        };
    }

    const uploadNFTMedia = (thumbnail) => {
        return new Promise((resolve, reject) => {
            try {
                let url_thumbnail = process.env.REACT_APP_URL_API+"/extra-files"
                let headers = {
                  'Content-Type': 'multipart/form-data'
                }
                axios.post(url_thumbnail, thumbnail, { headers :headers}).then((success) => {
                    resolve(success.data)
                })
            } catch (error) {
                reject(error)
            }
        })
    }

    return (
        <Box>
            <Box 
                component='h1' 
                sx={{
                    fontSize:'30px',
                    textAlign:'center',
                }}
            >
                {t("create_page.form_nft.title")}
            </Box>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                sx={{
                    padding:'0 20vw',
                    '@media (max-width: 600px)': {
                        padding:'0 5vw',
                    }
                }}
            >
                <Grid container rowSpacing={5} columns={{sm:12, md:12, lg:12, xl:12}} sx={{marginTop:'1rem'}}>
                    <Grid item sm={12} md={6} lg={8} xl={8}>
                        <Formik
                            initialValues={{
                                image: '',
                                name: '',
                                description: '',
                                attributes: [],
                                termsAndConditions: false
                            }}
                            validationSchema={mintSchema}
                            onSubmit={async(values, { setSubmitting, resetForm }) => {
                                //console.log('submit', values);
                                window.scrollTo(0, 0);
                                setSubmitting(true);
                                let el = document.getElementById('preview')
                                let resolution = ''
                                if(el) {
                                    if (preview.isVideo) {
                                        resolution = ''+el.videoHeight+'x'+el.videoWidth
                                    } else {
                                        resolution = ''+el.naturalHeight+'x'+el.naturalWidth
                                    }
                                }
                                if(preview) {
                                    if(preview.data) {
                                        let thumb = await uploadNFTMedia(preview.data);
                                        let msg = "Approve my intention of create metadata of nft at";
                                        console.log(thumb);
                                        let ipfsGateway = thumb.ipfs_gateway;
                                        let image = ipfsGateway + thumb.urls[0]
                                        let meta = {
                                            image: image,
                                            is_video : preview.isVideo,
                                            _properties : values.attributes,
                                            levels: [],
                                            stats : [],
                                            name : values.name,
                                            description: values.description,
                                            external_url : '',
                                            blockchain_name : process.env.REACT_APP_POLYGON_NETWORK_NAME
                                        }
                                        let url_nft_metadata = process.env.REACT_APP_URL_API+"/nft/metadata"
                                        await axios.post(url_nft_metadata, meta).then(async(success) => {
                                            const address = query.get('address')
                                            msg = "Approve my intention of create metadata of nft at";
                                            let {signature , message} = await sign(msg,data.userAccount,data.provider);
                                            let ipfsHashMetadata = success.data[0]
                                            let uri = ipfsGateway+ipfsHashMetadata
                                            let { tokenId, transactionHash } = await mint(uri,address,data.userAccount,data.provider)
                                            console.log('receipt ::', tokenId, transactionHash)
                                            let nft = {
                                                address_collectible : address,
                                                token_id : tokenId,
                                                thumb_url :  thumb.urls[0],
                                                license : 1,
                                                transaction : transactionHash,
                                                signature : signature,
                                                message : message,
                                                action : 'CREATED_NFT',
                                                blockchain_name : process.env.REACT_APP_POLYGON_NETWORK_NAME,
                                                tags : [],
                                                uri : uri,
                                                is_video : (preview.isVideo) ? 1 : 0,
                                                format: preview.format,
                                                resolution:resolution
                                            }
                                            let isVideoURL = (preview.isVideo) ? "&is_video=1":"&is_video=0"
                                            let url_create_nft = process.env.REACT_APP_URL_API+"/nft?type=SINGLE"+isVideoURL
                                            await axios.post(url_create_nft,nft).then((success) => {
                                              if(success.data) {
                                                resetForm()
                                                history.push('/nft?address='+address+"&token_id="+tokenId+"&domain="+process.env.REACT_APP_DOMAIN)
                                              }
                                            }).catch((error) => {
                                              console.log('error ::', error)
                                            })
                                        })
                                        console.log('meta ::', meta)
                                        console.log(thumb)
                                    }
                                }
                                /// https://gitlab.com/jarturocastan/persea/-/blob/developer-config/projects/persea_web/src/screens/NewNFTDetails/index.js
                                setSubmitting(false);
                                //setTimeout(()=>resetForm(), 10000);
                            }}
                        >
                            {({errors, values, touched, isSubmitting}) =>(
                             <Form>
                             <Container maxWidth='sm'>
                                 <Box>
                                     <Box 
                                         component='h1'
                                         sx={{
                                             fontSize:'25px',
                                             marginBottom:'0px',
                                             marginTop:'0px',
                                         }}
                                     >
                                        {t("create_page.form_nft.upload_image")}
                                     </Box>
                                     <Box 
                                         sx={{
                                             fontSize:'14px',
                                             marginTop:'10px',
                                             marginBottom:'10px',
                                             color:'#9B9B9B',
                                         }}
                                     >
                                         Drag or choose a thumbnail
                                     </Box>
                                     <Box
                                         component='section'
                                         display='flex'
                                         flexDirection='column'
                                         alignItems='center'
                                         justifyContent='center'
                                         onClick={() => hiddenFileInput.current.click()}
                                         sx={{
                                             border: '1px solid #E5E5E5',
                                             height: '200px',
                                             width: '100%',
                                             borderRadius: '8px',
                                             padding: '10px',
                                             cursor: 'pointer',
                                         }}
                                     >
                                         <Box 
                                             component='article' 
                                             display='flex' 
                                             flexDirection='column'
                                             alignItems='center'
                                             justifyContent='center'
                                         >
                                             <UploadFileIcon htmlColor='#777E90' fontSize='large' />
                                             <Box 
                                                 sx={{
                                                     color:'#777E90',
                                                     fontSize:'14px',
                                                 }}
                                             >
                                                PNG, GIF, JPG, MP4 Max 10MB.
                                             </Box>
                                         </Box>
                                     </Box>
                                     <UploadFile 
                                         type='file' 
                                         ref={hiddenFileInput}
                                         accept="image/png, image/gif, image/jpeg, video/mp4"
                                         name='image'
                                         onChange={handleFileUpload}
                                     />
                                 </Box>
                                 <Box component='label' htmlFor='name' sx={{fontSize:'25px'}}>
                                    {t("create_page.form_nft.name")}<span style={{color:'#dc3545'}}>*</span>
                                 </Box>
                                 <TextField
                                     id='name'
                                     type='text'
                                     placeholder={t("create_page.form_nft.name")}
                                     name='name'
                                     value={values.name}
                                 />
                                 {errors.name && touched.name ? (
                                     <div style={{color:'#dc3545'}}>{errors.name}</div>
                                 ) : null}
                                 <Box component='label' htmlFor='description' sx={{fontSize:'25px'}}>
                                    {t("create_page.form_nft.description")}<span style={{color:'#dc3545'}}>*</span>
                                 </Box>
                                 <TextArea
                                     id='description'
                                     as='textarea'
                                     placeholder={t("create_page.form_nft.description")}
                                     name='description'
                                     value={values.description}
                                 />
                                 {errors.description && touched.description ? (
                                     <div style={{color:'#dc3545'}}>{errors.description}</div>
                                 ) : null}
                                 <Box component='label' htmlFor='attributes' sx={{fontSize:'25px'}}>
                                    {t("create_page.form_nft.attributes")}<span style={{color:'#dc3545'}}>*</span>
                                 </Box>
                                 <FieldArray
                                     name="attributes"
                                     render={arrayHelpers => (
                                         <div>
                                             {(values.attributes && values.attributes.length > 0) ? <>
                                                     <Button
                                                         type='button'
                                                         onClick={() => arrayHelpers.push('')}
                                                     >
                                                        {t("create_page.form_nft.add_btn")} 
                                                     </Button>
                                             {
                                                 values.attributes.map((attribute, index) => (
                                                     <div key={index}>
                                                         <TextField
                                                             type='text'
                                                             placeholder={t("create_page.form_nft.attribute_name") +' Rareness'} 
                                                             name={`attributes.${index}.name`}
                                                             style={{marginBottom:'0.5rem'}}
                                                         />
                                                         <TextField
                                                             type='text'
                                                             placeholder={t("create_page.form_nft.attribute_value") +' Common'} 
                                                             name={`attributes.${index}.type`}
                                                             style={{marginBottom:'0.5rem'}}
                                                         />
                                                         <Button 
                                                             type='button'
                                                             onClick={() => arrayHelpers.remove(index)}
                                                         >
                                                            {t("create_page.form_nft.remove_btn")} 
                                                         </Button>
                                                     </div>
                                                 ))}
                                                 
                                                 
                                                     </>: (
                                                 <Button
                                                     type='button'
                                                     onClick={() => arrayHelpers.push('')}
                                                 >
                                                      {t("create_page.form_nft.add_btn")} 
                                                 </Button>
                                             )}
                                         </div>
                                     )}
                                 />
                                 {

                                /*  <ErrorMessage name="attributes" component={() => (<div className="error">{errors.attributes}</div>)} />
                                 */ }
                                 <Box display='flex' alignItems='center' sx={{gap:'20px'}}>
                                     <Box display='flex' alignItems='center' sx={{gap:'5px'}}>
                                         <Box>
                                             <Field type='checkbox' name='termsAndConditions' />
                                         </Box>
                                         <Box component='h1' sx={{fontSize:'15px', color:'#777E90'}}>
                                         {t("create_page.form_nft.accept_termns_and_conditions")} <span style={{color:'#dc3545'}}>*</span>
                                         </Box>
                                     </Box>
                                 </Box>
                                 {errors.termsAndConditions && touched.termsAndConditions ? (
                                         <div style={{color:'#dc3545'}}>{errors.termsAndConditions}</div>
                                     ) : null}
                             </Container>
                             <Container maxWidth='sm' sx={{marginTop:'1rem'}}>
                                 <Box display='flex' justifyContent='center' alignItems='center' sx={{gap:'5px'}}>
                                     <ButtonStyled type='button' text={t("create_page.cancel_btn")} onClick={cancel} />
                                     <ButtonStyled text={t("create_page.create_btn")} />
                                 </Box>
                             </Container>
                             <LoaderModal
                                 text='Creating...'
                                 isOpen={isSubmitting}
                                 textColor='#fff'
                                 spinnerColor='#fff'
                             />
                         </Form>)}
                        </Formik>
                    </Grid>
                    <Grid item sm={12} md={6} lg={4} xl={4} sx={{margin:'0 auto'}}>
                        <ImagePreview title={t("create_page.form_nft.preview_item")} image={preview.src} isVideo={preview.isVideo} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default MintNFT