import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const ButtonStyled = ({text, width, component,to,isDisabled=false, onClick, icon, type = 'submit',fontSize,isHover})=>{

    return (
        <Button
            variant="contained"
            disabled={isDisabled}
            to={to}
            component={component}
            endIcon={icon}
            onClick={onClick}
            type={type}
            sx={{
                marginLeft:'5px',
                background: '#CD1731',
                color:'#fff', 
                fontSize: fontSize ? fontSize : '20px',
                MozTransform: 'scale(1.1)',
                WebkitTransform: 'scale(1.1)',
                OTransform: 'scale(1.1)',
                msTransform: 'scale(1.1)',
                transform: 'skew(-16deg)',
                borderRadius:'0px',
                width: width ? width : '120px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: isHover && isHover === 'no' ? '#CD1731':'#1B2635',
                },
                '@media screen and (max-width: 650px)': {
                    width:'100%',
                    marginTop:'5px',
                    marginBottom:'5px'
                },
                '@media screen and (max-width: 450px)': {
                    width:'95%',
                },
                '@media screen and (max-width: 420px)': {
                    width:'85%',
                },
                '@media screen and (max-width: 320px)': {
                    width:'85%',
                },
                '@media (max-width:250px)':{
                    fontSize: '12px',
                }
            }} 
        >
            <span>
                {text}
            </span>
        </Button>
    )
}

ButtonStyled.propTypes = {
    text: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    width: PropTypes.string,
    onClick: PropTypes.func,
    component :PropTypes.object,
    icon: PropTypes.element,
    type: PropTypes.string,
    to: PropTypes.string,
    fontSize: PropTypes.string,
    isHover: PropTypes.string,
}

export default ButtonStyled;