import React, { useRef, useState } from 'react'
import { Box, Container, Grid } from '@mui/material'
import ImagePreview from 'components/ImagePreview';
//import LotteryContainer from 'components/LotteryContainer';
import ButtonStyled from 'components/ButtonStyled';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import styled from '@emotion/styled'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from "react-router";
import { sign } from 'services/Utils/signature';
import { deploy } from 'services/ERC721/deploy';
//import { Link } from 'react-router-dom'
import { Context } from 'hooks/WalletContext'
//import { nameAndSymbol } from 'services/ERC721/nameAndSymbol';
import { isVideo } from 'services/Utils/validateFileFormat';
import LoaderModal from 'components/LoaderModal';
import { nameAndSymbol } from 'services/ERC721/nameAndSymbol';
import { useTranslation } from 'react-i18next';


const collectionSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Name is required'),
    description: Yup.string()
        .min(3, 'Too Short!')
        .max(500, 'Too Long!')
        .required('Description is required'),
    url: Yup.string()
        .url('Invalid url').notRequired(),
    image: Yup.mixed()
    .nullable()
    .notRequired()
    .test("FILE_SIZE", "Uploaded file is too big.",
        value => !value || (value && value.size <= 20900000000)),
    termsAndConditions: Yup.boolean()
    .required('The terms and conditions must be accept')
    .oneOf([true],'The terms and conditions must be accept')
});

const UploadFile = styled.input`
    display: none;
`;

/* const TextInput = styled(Input)`
    width:100%;
    border-radius:8px;
    border:1px solid #E5E5E5;
    padding:10px;
    font-size: 16px;
`; */

const TextField = styled(Field)`
    width:100%;
    border-radius:8px;
    border:1px solid #E5E5E5;
    padding:10px;
    font-size: 16px;
`;

const TextArea =styled(Field)`
    width: 100%;
    height: 150px;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
`;

const CreateCollection = () => {
    const {t} = useTranslation("translate");
    const {data} = React.useContext(Context)
    const [preview, setPreview] = useState('');
    const hiddenFileInput = useRef(null);
    const history = useNavigate()

    const cancel = () => {
        history.push('/home')
    }

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        console.log('file', file)
        let formData = new FormData();
        formData.append("thumbnail", file);
        reader.onload = () => {
            let preview = {
                src : reader.result,
                isVideo : isVideo(e.target.value),
                data : formData
            }
            console.log('preview', preview)
            setPreview(preview);
        };
    }

    const uploadThumbnail = (thumbnail, isVideo) => {
        return new Promise((resolve, reject) => {
            try {
                let type = isVideo ? "video" : "img";
                let url_thumbnail = process.env.REACT_APP_URL_API+"/thumbnail?type="+type
                console.log('video url_thumbnail', url_thumbnail)
                let headers = {
                  'Content-Type': 'multipart/form-data'
                }
                axios.post(url_thumbnail, thumbnail, { headers :headers}).then((success) => {
                    console.log('success ::', success);
                    resolve(success.data.url)
                })
            } catch (error) {
                reject(error)
            }
        })
    }
    return (
        <Box>
            <Box 
                component='h1' 
                sx={{
                    fontSize:'30px',
                    textAlign:'center',
                }}
            >
                 {t("create_page.form_collection.title")}
            </Box>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                sx={{
                    padding:'0 20vw',
                    '@media (max-width: 600px)': {
                        padding:'0 5vw',
                    }
                }}
            >
                <Grid container rowSpacing={5} columns={{sm:12, md:12, lg:12, xl:12}} sx={{marginTop:'1rem'}}>
                    <Grid item sm={12} md={6} lg={8} xl={8}>
                        <Formik
                            initialValues={{
                                image: '',
                                name: '',
                                description: '',
                                url: '',
                                termsAndConditions: false
                            }}
                            validationSchema={collectionSchema}
                            onSubmit={async(values, { setSubmitting, resetForm }) => {
                                window.scrollTo(0, 0);
                                setSubmitting(true);
                                console.log('Submit data ::', values)
                                if(preview) {
                                    if(preview.data) {
                                        console.log('Submit data ::', values)
                                        let thumb_url = await uploadThumbnail(preview.data, preview.isVideo);
                                        let msg = "Approve my intention of create project at";
                                        let {signature , message} = await sign(msg,data.userAccount,data.provider);
                                       // let { name, symbol} = nameAndSymbol(values.name);
                                        //console.log('resalePercentage', values.commission)
                                        let { name, symbol} = nameAndSymbol(values.name);
                                        let transaction = await deploy(name,symbol,1,data.userAccount, data.provider);
                                        let collection = {
                                            address_collectible : transaction._address,
                                            tags: [],
                                            categories : [],
                                            social_networks : [],
                                            files_url : [],
                                            project_url : values.url,
                                            description : values.description,
                                            name : values.name,
                                            thumb_url : thumb_url,
                                            signature : signature,
                                            message : message,
                                            is_video : (preview.isVideo)? 1 : 0,
                                            domain : process.env.REACT_APP_DOMAIN,
                                            action : 'CREATED_PROJECT',
                                            blockchain_name : process.env.REACT_APP_POLYGON_NETWORK_NAME
                                        }
                                        console.log('data to send ::', collection)
                                       
                                        await axios.post(process.env.REACT_APP_URL_API+"/project",collection)
                                            .then((success) => {
                                               console.log('success ::', success)
                                               resetForm()
                                               setSubmitting(false);
                                               history.push('/create/mint-nft?address='+transaction._address)
                                            }).catch((error) => {
                                                console.log('error ::', error)
                                            })
                                    }
                                }
                                console.log('Submit data',values, handleFileUpload);
                                //setTimeout(()=>resetForm(), 10000);
                            }}
                        >
                            {({ errors, touched, isSubmitting}) =>(
                             <Form name='collection'>
                             <Container maxWidth='sm'>
                                 <Box>
                                     <Box 
                                         component='h1'
                                         sx={{
                                             fontSize:'25px',
                                             marginBottom:'0px',
                                             marginTop:'0px',
                                         }}
                                     >
                                        {t("create_page.form_collection.upload_image")}
                                     </Box>
                                     <Box 
                                         sx={{
                                             fontSize:'14px',
                                             marginTop:'10px',
                                             marginBottom:'10px',
                                             color:'#9B9B9B',
                                         }}
                                     >
                                         Drag or choose a thumbnail
                                     </Box>
                                     <Box
                                         component='section'
                                         display='flex'
                                         flexDirection='column'
                                         alignItems='center'
                                         justifyContent='center'
                                         onClick={() => hiddenFileInput.current.click()}
                                         sx={{
                                             border: '1px solid #E5E5E5',
                                             height: '200px',
                                             width: '100%',
                                             borderRadius: '8px',
                                             padding: '10px',
                                             cursor: 'pointer',
                                         }}
                                     >
                                         <Box 
                                             component='article' 
                                             display='flex' 
                                             flexDirection='column'
                                             alignItems='center'
                                             justifyContent='center'
                                         >
                                             <UploadFileIcon htmlColor='#777E90' fontSize='large' />
                                             <Box 
                                                 sx={{
                                                     color:'#777E90',
                                                     fontSize:'14px',
                                                 }}
                                             >
                                                 PNG, GIF, JPG, MP4 Max 10MB.
                                             </Box>
                                         </Box>
                                     </Box>
                                     {/* <UploadFile 
                                         type='file' 
                                         ref={hiddenFileInput}
                                         onChange={handleFileUpload}
                                         value=''
                                         inputProps={{accept:"image/png, image/gif, image/jpeg"}}
                                     /> */}
                                     <UploadFile 
                                         data-testid='image-collection'
                                         type='file' 
                                         ref={hiddenFileInput}
                                         accept="image/png, image/gif, image/jpeg, video/mp4"
                                         name='image'
                                         onChange={handleFileUpload}
                                     />
                                 </Box>
                                 {errors.image && touched.image ? (
                                     <div style={{color:'#dc3545'}}>{errors.image}</div>
                                 ) : null}
                                 <Box 
                                     component='label' 
                                     htmlFor='name'
                                     sx={{
                                         fontSize:'25px'
                                     }}
                                 >
                                     {t("create_page.form_collection.name")} <span style={{color:'#dc3545'}}>*</span>
                                 </Box>
                                 <TextField
                                     id='name'
                                     type='text'
                                     name='name'
                                     placeholder={t("create_page.form_collection.name")}
                                 />
                                 {errors.name && touched.name ? (
                                     <div style={{color:'#dc3545'}}>{errors.name}</div>
                                 ) : null}
                                 <Box 
                                     component='label' 
                                     htmlFor='description'
                                     sx={{
                                         fontSize:'25px'
                                     }}
                                 >
                                    {t("create_page.form_collection.description")}<span style={{color:'#dc3545'}}>*</span>
                                 </Box>
                                 <TextArea
                                     id='description'
                                     component='textarea'
                                     placeholder={t("create_page.form_collection.description")}
                                     name='description'
                                 />
                                 {errors.description && touched.description ? (
                                     <div style={{color:'#dc3545'}}>{errors.description}</div>
                                 ) : null}
                                 <Box 
                                     component='label'
                                     htmlFor='url' 
                                     sx={{
                                         fontSize:'25px'
                                     }}
                                 >
                                     {t("create_page.form_collection.url")}
                                 </Box>
                                 <TextField
                                     id='url'
                                     type='pathname'
                                     name='url'
                                     placeholder='https://mycollection.com'
                                 />
                                 {errors.url && touched.url ? (
                                     <div style={{color:'#dc3545'}}>{errors.url}</div>
                                 ) : null}
                                 <Box 
                                     component='label' 
                                     htmlFor='resale'
                                     sx={{
                                         fontSize:'25px'
                                     }}
                                 >
                                    {t("create_page.form_collection.relase_fee")} <span style={{color:'#dc3545'}}>*</span>
                                 </Box>
                                 <TextField
                                     id='resale'
                                     type='number'
                                     placeholder='%'
                                     name='commission'
                                     sx={{
                                         width:'100%',
                                     }}
                                 />
                                 {errors.commission && touched.commission ? (
                                     <div style={{color:'#dc3545'}}>{errors.commission}</div>
                                 ) : null}
                                 <Box display='flex' alignItems='center' sx={{gap:'20px'}}>
                                     <Box display='flex' alignItems='center' sx={{gap:'5px'}}>
                                         <Box>
                                             <Field id='acceptTerms' type='checkbox' name='termsAndConditions' />
                                         </Box>
                                         <Box 
                                             component='label' 
                                             htmlFor='acceptTerms'
                                             sx={{
                                                 fontSize:'15px', color:'#777E90'
                                             }}
                                         >
                                             {t("create_page.form_collection.accept_termns_and_conditions")} <span style={{color:'#dc3545'}}>*</span>
                                         </Box>
                                     </Box>
                                 </Box>
                                 {errors.termsAndConditions && touched.termsAndConditions ? (
                                         <div style={{color:'#dc3545'}}>{errors.termsAndConditions}</div>
                                     ) : null}
                             </Container>
                             <Container maxWidth='sm' sx={{marginTop:'1rem'}}>
                                 <Box display='flex' justifyContent='center' alignItems='center' sx={{gap:'5px'}}>
                                     <ButtonStyled text={t("create_page.cancel_btn")} type='button' onClick={cancel} />
                                     <ButtonStyled  text={t("create_page.create_btn")} type='submit'/>
                                 </Box>
                                 {/* {submitForm && <Box component='h1' sx={{fontSize:'25px', color:'#777E90'}}>{submitForm}</Box>} */}
                             </Container>
                             <LoaderModal
                                setIsClosed = {() => {}}
                                 text='Creating...'
                                 isOpen={isSubmitting}
                                 textColor='#fff'
                                 spinnerColor='#fff'
                             />
                         </Form>)}
                        </Formik>
                    </Grid>
                    <Grid item sm={12} md={6} lg={4} xl={4} sx={{margin:'0 auto'}}>
                        <ImagePreview title='Preview item' image={preview.src} isVideo={preview.isVideo} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default CreateCollection