import { FormControl } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';

const limitInterval = [15, 20, 25, 30, 35,40,45,50, 54];


const CustomSelect = styled('select')(() => ({
    border: '1px solid #ed2891',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '8px',
    width: '140px',
    '@media screen and (max-width: 500px)': {
        //width: '100%',
        width: '100%',
    }
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

  const CustomOption = styled('option')(() => ({
    //backgroundColor: '#FAB9DB',
    fontSize: '16px',
    //padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  }));

const DropDownLimit = ({setLimit, handleFetchLimit}) => {
    const refLimit = React.useRef(null);
    const handleChangeLimit = (event) => {
        console.log(event.target.value)
        setLimit(parseInt(event.target.value));
        handleFetchLimit(parseInt(event.target.value));
        console.log(refLimit)
    }
    const {t} = useTranslation("translate");
    return (
        <FormControl
            variant="outlined" 
            sx={{ 
                minWidth: 150,
                //border:'1px solid#ed2891',
                borderRadius: '8px',
                outline: 'none',
                '&:hover': {
                    border: 'none',
                    outline: 'none',
                    boxShadow: 'none',
                  },
                '@media screen and (max-width: 500px)': {
                    width: '100%',
                    marginBottom: '10px',
                }
            }}
        >
            <CustomSelect onChange={handleChangeLimit }>
                <CustomOption ref={refLimit}  value={15}>{t("search.card_limit")}</CustomOption>
                {limitInterval.map((item,index) => (
                    <CustomOption key={index} value={item}>{item}</CustomOption>
                ))}
            </CustomSelect>
        </FormControl>
    );
}

DropDownLimit.propTypes = {
    setLimit: Proptypes.func.isRequired,
    handleFetchLimit: Proptypes.func.isRequired,
}

export default DropDownLimit;