import React from 'react'
import { Box, CardMedia } from '@mui/material'
import snowtrace from 'assets/logos/snowtrace.svg'
import {BiCube} from 'react-icons/bi'
import {BsEye} from 'react-icons/bs'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'

const Details = ({content}) => {
    const { t } = useTranslation("translate");
    const handleClickSocialMedia = (link) =>{
        window.open(link, '_blank');
    }
    return (
        <>
            <Box onClick={()=>handleClickSocialMedia(`${process.env.REACT_APP_SCAN}address/${content.project_key}`)} display='flex' alignItems='center' sx={{cursor:'pointer', gap:'0.5rem', marginBottom:'0.5rem'}}>
                <CardMedia component='img' src={snowtrace} alt='scan' sx={{cursor:'pointer', height:'25px', width:'25px'}} />
                <span>{t('nft_details.snowtrace')}</span>
            </Box>
            <Box onClick={()=>handleClickSocialMedia(content.metadata.metadata_url)} display='flex' alignItems='center' sx={{cursor:'pointer', gap:'0.5rem', marginBottom:'0.5rem'}}>
                <BiCube size={26} />
                <span>{t('nft_details.metadata')}</span>
            </Box>
            <Box onClick={()=>handleClickSocialMedia(content.metadata.image_url)} display='flex' alignItems='center' sx={{cursor:'pointer', gap:'0.5rem', marginBottom:'0.5rem'}}>
                <BsEye size={26} />
                <span>{t('nft_details.ipfs')}</span>
            </Box>
        </>
    )
}

Details.propTypes = {
    content: PropTypes.object,
}

export default Details