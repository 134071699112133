export const history = [
  {
    "contract_address": "0x856C7b227f8DDd4c44bEc9C3Abe12E94dC7cF187",
    "from": "0x0000000000000000000000000000000000000000",
    "to": "0xfc33B55E0457E9c3965666c459D7C7F042278d10",
    "amount": 1.0,
    "nft_id": 14670892,
    "type_movement": "MINT",
    "transaction_hash": "0x2bcfdcc3ea360b8aa32abc405fc71f57dcee790aa179c0d051580d36a2c4455b",
    "block_number": "329962",
    "domain": "artcrypted.com",
    "created": 1624054583,
    "status": 1
  }
]

