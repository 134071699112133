import React, { Suspense } from 'react';
import { Box, MobileStepper,Button,CardMedia } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { autoPlay } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
// import chivasSlide1 from 'assets/images/CXV-slider1.jpg';
import  { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import {fetchImageData} from './getImageData';
import ErrorBoundary from 'components/ErrorBoundary';
import ServerError from 'components/ServerError';
import LoaderCircle from 'components/LoaderCircle';


const images = fetchImageData();


const Slider = ({images}) => {
    const slides = images.images.read();
    
    const { t } = useTranslation("translate");
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [maxSteps,setMaxStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(Number(step));
    };

    const [array,setArray] = React.useState([]);
    const [showStep,setShowStep] = React.useState(false);
    

    React.useEffect(()=>{

       let arrayTemp = new Array();
        if(slides.length > 0){
            slides.map((slide)=>{
                if(slide.banner_1){
                    arrayTemp.push(slide.banner_1);
                }
                if(slide.banner_2){
                    arrayTemp.push(slide.banner_2);
                }
                if(slide.banner_3){
                    arrayTemp.push(slide.banner_3);
                }
    
            })
            setArray(arrayTemp);
            setMaxStep(arrayTemp.length);
        }

        
      
    },[])
    
    
    return (
        <React.Fragment>
            <Box>
            <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {array?.map((step, index) => (
                    <div key={index}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <CardMedia
                                component="img"
                                // alt={step.title}
                                sx={{
                                    height:"610px",
                                    "@media (max-width: 3840px)": {
                                        height:"850px"
                                    },
                                    "@media (max-width: 2840px)": {
                                        height:"610px"
                                    },
                                    "@media (max-width: 768px)": {
                                        height:"230px"
                                    }
                                }}
                                image={step.asset.url}
                                // title={step.title}
                                onLoad={()=>{setShowStep(true)}}
                            />
                            
                        ) : null}
                    </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper
                    
                    sx={{
                        display:array.length <= 1 || !showStep ? 'none':'auto',
                        position:'absolute',
                        bottom:0,
                        left:0,
                        right:0,
                        zIndex:1,
                        marginTop:'20px',
                        backgroundColor:'transparent',
                        color:'red',
                        ".css-114p2tk-MuiMobileStepper-dot":{
                            backgroundColor:'rgba(255,255,255,0.5)'
                        },".MuiMobileStepper-dotActive":{
                            backgroundColor:'red'
                        },

                    }}
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                    <Button
                        sx={{color:'#fff'}}
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        {t('home.carousel_button_next')}
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft sx={{color:'#fff'}} />
                        ) : (
                        <KeyboardArrowRight sx={{color:'#fff'}} />
                        )}
                    </Button>
                    }
                    backButton={
                    <Button
                        sx={{color:'#fff'}}
                        size="small" 
                        onClick={handleBack} 
                        disabled={activeStep === 0}
                    >
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight  sx={{color:'#fff'}}/>
                        ) : (
                        <KeyboardArrowLeft sx={{color:'#fff'}}/>
                        )}
                        {t('home.carousel_button_prev')}
                    </Button>
                    }
                />

            </Box>
        </React.Fragment>
    )
}

Slider.propTypes = {
    images: PropTypes.any
}


const Carousel = () =>{

    return (
        <Box>
            <Box 
                sx={{
                    minWidth:'100%',
                    maxWidth: "100%",
                    height:"610px",
                    flexGrow: 1,
                    position:'relative',
                    "@media (max-width: 3840px)": {
                        height:"auto",
                    },
                    "@media (max-width: 768px)": {
                        height:"230px"
                    }  
                }}
            >   
                {
                    images &&
                    <ErrorBoundary 
                        fallback={<ServerError />}
                    >
                        <Suspense fallback={<LoaderCircle text='loading' />}>
                            <Slider images={images}/>
                        </Suspense>
                    </ErrorBoundary>
                } 
            </Box>
        </Box>
    )
}


export default Carousel;
